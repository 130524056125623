













import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class QuestionPop extends Vue {
  @Prop({}) title: string
  @Prop({}) footer: string
  @Prop({}) content: string[]

  mounted() {}
  updated() {}
  destroyed() {}
}
