

























import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import ExchangeTabsComp from './views/tabs.vue'
import PackItemComp from './views/coupon.vue'
import EmptyComp from '@xt/www/components/system/empty.vue'
import Teleport from '@xt/client/mixins/components/teleport.vue'

@Observer
@Component({
  scrollToTop: true,
  components: { ExchangeTabsComp, PackItemComp, EmptyComp, Teleport }
})
export default class PageView extends Vue {
  get PageStore() {
    return this.$store.$coupon.myPacks
  }

  get body() {
    return {
      couponType: this.couponType
    }
  }

  loading = true
  tabActiveKey = +lodash.get(this.$route.query, 'active', 0)
  couponType = +lodash.get(this.$route.query, 'active', this.tabActiveKey)

  async onTabChange(active: number) {
    if (this.tabActiveKey === active) return

    this.PageStore.onResetMyPackList()
    this.tabActiveKey = this.couponType = active
    this.init()
  }

  async init() {
    this.loading = true
    try {
      await this.PageStore.onGetMyPacks(this.couponType === 0 ? 0 : `${this.couponType}00`)
    } catch (error) {
    } finally {
      this.loading = false
    }
  }

  created() {
    this.init()
  }
}
