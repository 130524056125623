/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-10-20 20:26:03
 * @modify date 2020-10-20 20:26:03
 * @desc 课程 课时详情
 */
import lodash from 'lodash'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { MixinsCourseHelpers } from './helpers'
import { MixinsCourseClasshourDownTime } from './downTime'
import { Bind, Debounce } from 'lodash-decorators'
import { EnumCouponType } from '@xt/client/enums'
import { BaseCouponItem } from '@xt/client/types/coupon'

@Component
export class MixinsCourseClasshour extends Mixins(MixinsCourseHelpers, MixinsCourseClasshourDownTime) {
  // 课时 地图页使用
  // @Prop({ default: () => [] }) dataSource: IClasshourEntities
  @Prop() getPopupContainer
  // 课时 地图页使用
  @Prop() index
  // 用户在当前课程下可用的回放延长卡
  userAvailableCompanionCardList: BaseCouponItem[] = []
  // 展示回放延长卡使用弹窗
  showCompanionCardPop = false
  jumpDownloadPageLock = false
  // 平台
  get platform() {
    return this.$store.$global.platform
  }
  // 课时 id
  get id() {
    return {
      Mobile: this.$route.params.id || this.$route.query.id,
      PC: this.$route.query.classhour
    }[this.platform]
  }
  get oss() {
    return this.$store.$OSS
  }
  // 课程详情
  get Details() {
    return this.$store.$storeCourse.Details
  }
  get UserStore() {
    return this.$store.$storeUser
  }
  // 地图
  get MapStore() {
    return this.Details.Map
  }
  // 课时状态
  get ClasshourStore() {
    return this.MapStore.Details
  }
  // pc 显示 详情
  get visible() {
    return lodash.has(this.$route.query, 'classhour')
  }
  // 课程 地图页 分类 3 5 10 20
  get templateType() {
    return this.Details.dataSource.templateType
  }
  get classHourStr() {
    const str = [this.classStr, 'index-' + this.index]
    if (!this.dataSource.isPublished) {
      str.push('xt-classhour-not')
    }
    if (this.dataSource.isActivity) {
      str.push('xt-classhour-activity')
    }
    return str
  }
  lineStart() {
    // 头部临时课程已经发布
    if (lodash.includes([10, 20], this.templateType) && this.index === 1) {
      return lodash.head(this.MapStore.dataSource.classhourList).isPublished
    }
    return true
  }
  lineEnd() {
    if (this.templateType === 10 && this.index === 11) {
      return lodash.head(this.MapStore.dataSource.classhourList).isPublished
    }
    return false
  }
  // 路径地址
  getLineSrc(end = false) {
    //  3 5 课程
    if (lodash.includes([3, 5], this.templateType)) {
      if (lodash.includes([this.dataSource.isLive, this.isDown], true)) {
        return `/template/${this.templateType}-sign.png`
      }
      return
    }
    // 10 20 课程
    const index = this.index
    if (index) {
      if (lodash.includes([this.dataSource.liveEnd, this.dataSource.isLive, this.isDown], true)) {
        const path = lodash.get({ 10: 'ten', 20: 'twenty' }, this.templateType)
        if (end) {
          return `/template/${path}/${this.index + 1}.png`
        }
        return path ? `/template/${path}/${index}.png` : ''
      }
    }
  }
  tabPane = [
    { key: 1, title: '资料区' },
    { key: 2, title: '交作业' }
  ]
  activeKey = 1

  tabsChange(activeKey) {
    this.activeKey = activeKey
  }
  async onLoading(id) {
    if (id) {
      this.activeKey = 1
      await this.ClasshourStore.onLoading({ id })
      // 已结束直播
      if (this.ClasshourStore.classhourInfo.liveEnd) {
        this.activeKey = 2
      }
    }
  }
  /**
   * 去直播
   * @param isPlayback 是否是回放  用于区分跳转至直播间还是回放间
   */
  @Debounce(500)
  async onGolive(isPlayback: boolean, id?: number) {
    //   用户需要完善个人信息
    if (this.UserStore.onneedCompleteInfo()) {
      this.UserStore.onToggleModal(this.$EnumLocaleLinks.links_complete)
      return
    }
    const targetRoom = isPlayback ? 'playback' : 'live'
    console.warn(isPlayback, 'targetRoom', targetRoom)

    if (!this.id && !id) return this.$msg('暂无直播时间')

    // 前置做一下拦截
    const { startTime, liveEnd, playbackStatus /** 回放 1， 不回放 2 */, playbackEndTime } = this.ClasshourStore.classhourInfo

    console.log(startTime, liveEnd, playbackEndTime, playbackStatus)

    // 不能进入直播间
    const preventGotoLive = this.moment(this.$ajax.serviceDate()).isBefore(this.moment(startTime).startOf('days'))
    console.warn(
      preventGotoLive,
      this.$ajax.serviceDate().format('YYYY-MM-DD HH:mm:ss'),
      this.moment(startTime).startOf('days').format('YYYY-MM-DD HH:mm:ss')
    )
    if (preventGotoLive) {
      return this.$msg('还没有到上课时间')
    }
    // 回放还没有生成
    if (liveEnd === true && playbackStatus === 0) {
      return this.$msg('回放正在生成中...')
    }
    // 回放已经结束
    const preventGotoPlayback = playbackEndTime && this.moment(this.$ajax.serviceDate()).isAfter(this.moment(playbackEndTime))
    if (preventGotoPlayback) {
      // 校验当前课时下是否有已经激活的回放延长卡
      const studyBuffEndTime = this.ClasshourStore.classhourInfo.original?.studyBuffEndTime
      const now = this.$ajax.serviceDate()
      if (studyBuffEndTime && now.isBefore(studyBuffEndTime)) {
        // 当前用户激活了回放延长卡且时间还没有过期

        this.gotoGenseeRoom(targetRoom, (this.id as string) || id)
        return
      }

      // 校验用户是否有可用的回放延长卡
      const cardList = await this.getUserCompanionCard()
      if (cardList.length > 0) {
        this.userAvailableCompanionCardList = cardList
        this.showCompanionCardPop = true
        return
      }
      return this.$msg('课程结束啦')
    }

    this.gotoGenseeRoom(targetRoom, (this.id as string) || id)
  }

  gotoGenseeRoom(targetRoom: 'playback' | 'live', id: string | number) {
    // 小程序独立打开一个页面
    if (this.$store.$global.isEmbeddedH5Page) {
      wx.miniProgram.navigateTo({
        url: `/pages/webview/index?p=${encodeURIComponent(`/${targetRoom}?classhour=${id}`)}`
      })
    } else {
      window.open(`/${targetRoom}?classhour=${id}`, '_blank')
    }
  }

  // 获取桐学回放延长卡数据
  async getUserCompanionCard() {
    const courseId = this.ClasshourStore.classhourInfo.original.courseId
    if (!courseId) return []

    const result = await this.Details.onOwnCompanionCard(EnumCouponType.Companion)

    return result.filter(item => this.isCompanionCardCanUse(courseId, item))
  }

  hideCompanionCardPop() {
    this.showCompanionCardPop = false
  }

  isCompanionCardCanUse(courseId: number | string, card: BaseCouponItem) {
    // 卡类型不适用
    if (card.couponType !== EnumCouponType.Companion) return false

    // 当前课程不适用
    if (card.exchangeProducts.every(prod => !lodash.isEqual(prod.bizId, courseId))) {
      return false
    }

    // 已经卡过期不适用？
    return true
  }

  getRevied(item) {
    return lodash.get(item, 'reviewed', 0) || 0
  }
  // 作业按钮
  getSubmitText<T extends 'text' | 'button' | 'isSubmited' | 'color' | 'class'>(item, type: T) {
    // 作业提交状态（空表示:未提交 false:已提交 true:已评阅）
    let reviewed = lodash.isBoolean(item.reviewed) ? (item.reviewed ? 2 : 1) : 0
    switch (type) {
      case 'text':
        return ['未提交', '已提交', '已评阅'][reviewed]
      case 'button':
        return ['交作业', '查看', '查看'][reviewed]
      case 'isSubmited':
        return [false, true, true][reviewed]
      case 'color':
        return ['#cccccc', '#c4a57f', '#077578'][reviewed]
      case 'class':
        return ['grey', 'yellow', 'green'][reviewed]
    }
  }
  async onDownload(item) {
    try {
      let filePath = lodash.get(item, 'filePath')
      if (!filePath) {
        this.$msg('下载失败')
        return false
      }
      this.ClasshourStore.onAddCourseMaterials(item.id)
      let url = await this.oss.onGetFilesUrl(filePath)
      // 内嵌打开去微信小程序内下载文件
      if (this.$store.$global.isEmbeddedH5Page) {
        if (this.jumpDownloadPageLock) {
          console.log('命中了重复点击防御')
          return
        }

        this.jumpDownloadPageLock = true
        wx.miniProgram.navigateTo({
          url: `/pages/download/index?url=${encodeURIComponent(url)}&filename=${item.name}`,
          success: () => {
            setTimeout(() => {
              this.jumpDownloadPageLock = false
            }, 500)
          }
        })
        return
      }

      // 移动端
      if (this.platform === 'Mobile' && this.$store.$global.WechatBowser) {
        return this.$router.push({ name: 'download', query: { filePath: url, name: item.name } })
      }
      this.onOpenBlock(url, encodeURIComponent(item.name))
    } catch (error) {
      console.log('Clin -> file: classhour.ts -> line 170 -> download -> error', error)
    }
  }

  /**
   * 课时详情
   * @param classhour
   */
  onToClasshour() {
    try {
      const classhour = this.dataSource
      //   用户需要完善个人信息
      if (this.UserStore.onneedCompleteInfo()) {
        this.UserStore.onToggleModal(this.$EnumLocaleLinks.links_complete)
        // throw this.$EnumLocaleLinks.links_complete
        return
      }
      if (classhour) {
        this.CourseHelpers.onCheck(this.Details.dataSource)
        this.CourseHelpers.onCheckClasshour(classhour)
        if (this.platform === 'PC') {
          this.$router.replace({
            query: lodash.assign({}, this.$route.query, { classhour: String(classhour.id) })
          })
        } else {
          this.$router.push({
            name: 'course-classhour-id',
            query: { id: String(classhour.id) },
            params: { id: String(classhour.id) }
          })
        }
      }
    } catch (error) {
      this.$msg(error)
    }
  }
  onToHomework(item) {
    // if (this.platform === 'PC' && !this.$store.$global.WechatBowser) {
    //   const url = this.$router.resolve({
    //     name: "homework",
    //     query: {
    //       id: item.id,
    //       // classhourId: this.id,
    //     },
    //   }).href
    //   return this.onOpenBlock(url)
    // }
    //   用户需要完善个人信息
    if (this.UserStore.onneedCompleteInfo()) {
      this.UserStore.onToggleModal(this.$EnumLocaleLinks.links_complete)
      // throw this.$EnumLocaleLinks.links_complete
      return
    }
    this.$router.push({
      name: 'homework',
      query: {
        id: item.id
        // classhourId: this.id,
      }
    })
  }
  onCancel() {
    let query = lodash.clone(this.$route.query)
    lodash.unset(query, 'classhour')
    this.$router.replace({
      query: query
    })
  }
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
