












































































































import { MixinsGoCourseItem } from '@xt/client/mixins'
import { Component, Mixins } from 'vue-property-decorator'
import FromImgModel from '../../../../components/fromImgModel/index.vue'
import ShowPopup from '../../../../components/showPopup/showPopup.vue'
import TypeIcon from '../../../../components/typeIcon/index.vue'
import WxImgPop from '../../../../components/wxImgPop/index.vue'
import LazyImg from '@/components/lazyImg/index.vue'
import { EnumProductTypeQuery } from '@xt/client/enums'

@Component({
  components: {
    TypeIcon,
    WxImgPop,
    ShowPopup,
    FromImgModel,
    LazyImg
  }
})
export default class PageView extends Mixins(MixinsGoCourseItem) {
  isHover = false
  //展览弹窗
  showPopVisible = false
  qrcodeUrl = ''

  //添加书童
  showAddChild = false
  toShowAddChild(isShow) {
    this.showAddChild = isShow
  }

  get EnumProductTypeQuery() {
    return EnumProductTypeQuery
  }

  //展览辅导课
  exhibitionIsVisibleGolearn = false
  //日课
  dayIsVisibleGolearn = false
  //去学习
  isVisibleGolearn = false
  //0是未确认未入群 1是已确认未入群 2是已入群
  async showGoLearnModel() {
    // if(this.type == 'exhibition'){
    //   this.isVisibleGolearn = true;
    //   return;
    // }
    // if(this.type == 'day'){
    //   this.isVisibleGolearn = true;
    //   return;
    // }
    //如果是直播课，未入群未确认，弹窗;否则跳转
    if (
      this.dataSource.groupChatStatus == 2 ||
      this.dataSource.groupChatStatus == 1 ||
      this.hasShowCallAlert ||
      !this.dataSource.showAssistantUrlFlag
    ) {
      await this.goCourse()
      return
    }
    //显示弹窗
    else {
      this.isVisibleGolearn = true
    }
  }

  //关闭弹窗
  closeGoLearnModel() {
    //如果是不在提醒
    if (this.callMe) {
      this.changeGroupStatus()
    }
    this.isVisibleGolearn = false
  }
  //关闭去学习展览
  closeExhibitionGoLearnModel() {
    this.exhibitionIsVisibleGolearn = false
  }
  //关闭去学习日课
  closeDayGoLearnModel() {
    this.dayIsVisibleGolearn = false
  }

  //点击去学习
  async goLearn() {
    //如果是选中了不在提醒
    if (this.callMe) {
      this.changeGroupStatus()
    }
    await this.goCourse()
  }
  async goCourse() {
    if (this.type == EnumProductTypeQuery.Exhibition) {
      if (this.btnText === '去备展') {
        this.qrcodeUrl = await this.$store.$storeShow.getQrcodeUrl({
          page: 'pages/levels/index',
          query: `id=${this.dataSource.bizId}`
        })
      } else {
        this.qrcodeUrl = await this.$store.$storeShow.getQrcodeUrl({
          page: 'pages/lookingEx/index',
          query: `id=${this.dataSource.bizId}`
        })
      }
      this.exhibitionIsVisibleGolearn = true
      this.isVisibleGolearn = false
      return
    }
    if (this.type == EnumProductTypeQuery.DayCourse) {
      this.dayIsVisibleGolearn = true
      this.isVisibleGolearn = false
      return
    }
    this.$router.push({
      path: `/course/${this.dataSource.bizId}`
    })
  }

  mounted() {}
  updated() {}
  destroyed() {}
}
