import lodash from 'lodash'
import { BindAll } from 'lodash-decorators'
import { action, computed, observable, runInAction, toJS } from 'mobx'
import { Subject } from 'rxjs'
import moment from 'moment'
import { getDeviceInfo } from '@xt/client/utils/device'
import { BanType, LastLoginType, StorageKeys, TargetType } from '@xt/client/enums'
import { EnumApiCourse, EnumApiOrder, EnumApiUser, EnumApiProduct, EnumApiCurrency, EnumBishan } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EnumMessage } from '../../languages'
import Entities from './entities'
import { Course } from '../basics/course'
import { BackupKeys } from './consts'
import $global from '../../store/global'
import { BindDeviceMeta } from '@xt/client/entities/device/controller'
import { getFp } from '@xt/client/utils/fingerprint/report'
import AppletAuthLogin from './auth'
import MessageNotice from './notice'
import { reportEntryLog, reportSumLog } from '@xt/client/utils/log'
import { hash } from 'ohash'
import { UserInfo, AccountAlertInfo } from './types'
import { parseAccountBanInfo } from '@xt/client/utils/user'
import { parseContent } from '@xt/client/utils/parser'
import { EnumApiCoupon } from '@xt/client/api/coupon'
import { BaseCouponItem } from '@xt/client/types/coupon'

/** 获取注销账号信息 */
export interface ICheckUserInfoResp {
  bishanPointCount?: number
  coin?: number
  purchaseProductCount?: number
  uncompleteProductCount?: number
}

/**
 * 微信code接口响应数据类型
 */

export interface XTUCCode4JOptions {
  // 邮箱是否存在
  emailExist: boolean
  // 手机号是否存在
  phoneExist: boolean
  // 临时 token
  tempToken: string
  // 微信是否存在
  wxExist: boolean
}

/**
 * 校验到用户有多个账号时的账号列表
 */
export interface XTUCPhone4JMemberOptions {
  accountType: number // 账号类型（1 微信账号，2 手机号账号）
  bindPhone: boolean // 是否绑定手机号
  bingEmail: boolean // 是否绑定邮箱
  bingWx: boolean // 是否绑定微信
  id: string
  phoneNum: string
  email: string
  nickname: string // 昵称
  registeredTime: number //注册时间
}

/**
 * phone4j的响应信息
 */
export interface XTUCPhone4JOptions {
  allUnExit: boolean
  result: boolean
  // 临时token
  tempToken: string
  memberLoginVOS: XTUCPhone4JMemberOptions[]
}

export interface BishanExpireRemindResp {
  bishanNum: number
  content: string
  expireTime: number
  id: number
  isShow: 0 | 1
  isValid: 0 | 1
}

interface ArmsApiOptions {
  /**
   * 接口名
   */
  api: string
  /**
   * 是否调用成功
   */
  success: boolean
  /**
   * 接口耗时
   */
  time: number
  /**
   * 返回码
   */
  code?: number | string
  /**
   * 返回信息
   */
  msg?: string
  /**
   * API请求开始时间戳
   */
  begin?: number
  /**
   * EagleEye-TraceID的值
   */
  traceId?: string
  /**
   * EagleEye-SessionID的值
   */
  sid?: string
}

export interface ApiSnapshot {
  method: string
  originApi: string
  params: any
  reqHeaders: Record<string, string>
  resHeaders: Record<string, string>
  response: string
}

declare global {
  interface Window {
    WeixinJSBridge: any
    __wxjs_environment: any
    __bl?: {
      // api(options: ArmsApiOptions): void
      api(
        api: string,
        success: boolean,
        time: number,
        code?: string | number,
        msg?: string,
        begin?: number,
        traceId?: string,
        sid?: string,
        apiSnapshot?: string
      ): void

      /**
       * @param {string} key 事件名
       * @param {?number} [value] 单次累加上报量
       */
      sum(key: string, value?: number): void
      [key: string]: any
    }
  }
}

declare const WeixinJSBridge

export const OAuthError = {
  /**
   * 静默绑定
   */
  AutoBind: 2031100005,

  /**
   * 已经绑定了别的设备，需要用户手动绑定当前设备
   */
  ManualBind: 2031100006,

  /**
   * 绑定设备达到上限
   */
  BindLimit: 2031100007,

  /**
   * 以下错误是授权登录失败相关
   */
  UnknownDevice: 2031100008,

  /**
   * 不清楚的授权设备  h5授权登录时会使用到
   */
  UnknowAuthDevice: 2031100010
} as const

export type OAuthErrorCode = (typeof OAuthError)[keyof typeof OAuthError]

type OAuthRequestError = {
  errCode: OAuthErrorCode
  errMsg: string
}

export enum EnumVerifyState {
  /** 未验证 */
  NoVerify = 0,
  /** 验证成功 */
  PassVerify = 1,
  /** 验证失败 */
  FailVerify = 2
}

export type BindWXMetaType = {
  title: string
  content: string
  scanTip?: string
}

export type VerfiyWXMetaType = {
  title: string
  content: string
  scanTip?: string
  operation?: boolean
  gift?: boolean
  /** 关闭 */
  closeHandler?: () => void
  /** 跳过 */
  skipHandler?: () => void
  /** 点击  去报名 | 继续报名 */
  signupHandler?: () => void
}

export type VerifyWechatMetaType = {
  title: string
  content: string
  /**
   * 操作类型
   * bind: 报名日课 未绑定微信
   * verify: 报名日课 校验绑定时间过期
   * unbind: 安全设置  点击微信绑定
   */
  type: 'bind' | 'verify' | 'unbind'
  /** 校验状态 */
  status?: 0 | 1 | 2
  tip?: string
  gift?: boolean
  /** 关闭 */
  closeHandler?: () => void
  /** 跳过 */
  skipHandler?: () => void
  /** 点击  去报名 | 继续报名 */
  signupHandler?: () => void
}

function isOAuthRequestError(data: unknown): data is OAuthRequestError {
  return typeof data === 'object' && data !== null && 'errCode' in data
}

// 内嵌在小程序中的webview出现在前台
const onWebviewVisible = (currentUserInfo, setUserInfo) => {
  const ready = () => {
    WeixinJSBridge.on('onPageStateChange', res => {
      const active = typeof res.active === 'string' ? res.active === 'true' : res.active

      if (active) {
        let backupInfo = null
        switch (sessionStorage.getItem('__xt__embedded__source')) {
          case 'uc':
            backupInfo = localStorage.getItem(BackupKeys.user_uc)
            localStorage.setItem(BackupKeys.userinfo, backupInfo)
            break
          case 'show':
            backupInfo = localStorage.getItem(BackupKeys.user_show)
            localStorage.setItem(BackupKeys.userinfo, backupInfo)
            break
        }
        // 判断当前UserInfo, 如果为空则刷新一下用户信息
        if (!currentUserInfo.id && backupInfo) {
          const data = JSON.parse(backupInfo)
          console.warn('检测到当前用户信息丢失，重新获取', data)
          if (data.UserInfo) {
            setUserInfo(data.UserInfo)
          }
        }
      }
    })
  }

  if (!window.WeixinJSBridge || !WeixinJSBridge.invoke) {
    document.addEventListener('WeixinJSBridgeReady', ready, false)
  } else {
    ready()
  }
}

@BindAll()
export class ControllerUser extends Entities {
  constructor(protected $ajax: AjaxBasics) {
    super()

    // 检测下有没有备份的用户信息
    if (this.isMobile() && !$global.isEmbeddedH5Page) {
      // 直接打开了H5页面
      const backupInfo = localStorage.getItem(BackupKeys.user_h5)
      if (backupInfo) {
        localStorage.setItem(BackupKeys.userinfo, backupInfo)
      } else {
        localStorage.removeItem(BackupKeys.userinfo)
      }
    }

    // 检测用户热启动重新回到小程序webview页面后，执行relaunch首页操作，防止数据错误
    if (this.isMobile() && $global.isEmbeddedH5Page) {
      onWebviewVisible(this.UserInfo, this.setUserInfo.bind(this))
    }
  }

  @observable isExistLive: boolean = false
  @observable visibleWXBind: boolean = false
  @observable bindWXMeta: BindWXMetaType | null = null
  @observable visibleWXVerify: boolean = false
  @observable verifyWXMeta: VerfiyWXMetaType | null = null
  /** 用于H5  是否展示微信检测弹窗 */
  @observable visibleVerifyWechat: boolean = false
  /** 用于H5  弹窗中展示的相关信息 */
  @observable verifyWechatMeta: VerifyWechatMetaType | null = null
  @observable visibleStudentWelfare = false
  @observable accountBanInfo: AccountAlertInfo | null = null
  @computed get accountBanTip() {
    // 10.28需求  不展示结束时间
    return ''

    if (!this.accountBanInfo || this.accountBanInfo.banType !== BanType.Ban) return ''
    const prefix = this.accountBanInfo.targetType === TargetType.Device ? '禁用' : '封禁'

    return `${prefix}结束时间：${moment(this.accountBanInfo.unbanTime).format('YYYY-MM-DD HH:mm:ss')}`
  }

  @computed get accountBanContent() {
    if (!this.accountBanInfo) return []

    return parseContent(this.accountBanInfo.banCause)
  }

  @observable checkState: boolean = false
  @observable isOnlyBindVisible: boolean = false // 用来关闭安全设置邮箱和手机绑定窗口
  @observable tempToken: string = '' // code4Judge的临时token
  giftsLoaded: boolean = false
  cancelAccountTempToken: string = '' // 注销账号的临时token

  /**
   * 平台
   * @memberof XTGlobal
   */
  platform: 'PC' | 'Mobile' = process.env.platform as any

  /** 小程序授权H5 */
  appletAuth = new AppletAuthLogin(this.$ajax)

  /** 消息通知 */
  messageNotice = new MessageNotice(this.$ajax)

  /**
   * 每次登录成功时记录一下设备信息
   */
  async onSaveDeviceInfo() {
    const deviceInfo = await getDeviceInfo()
    const {
      extra: { canvas, webgl, audio },
      current
    } = await getFp($global)
    // const xtDevId = getXTDevId($global.platform)

    try {
      await this.$ajax.post(EnumApiUser.SaveDeviceInfo, {
        authType: parseInt(this.JwtPayload.auth_type, 10),
        deviceInfo: {
          ...deviceInfo,
          canvas,
          webgl,
          audio,
          xtDevId: current
        }
      })
    } catch {}
  }

  async onAutoBindDevice(options: BindDeviceMeta) {
    const deviceInfo = await getDeviceInfo()
    const {
      extra: { canvas, webgl, audio },
      current
    } = await getFp($global)
    // const xtDevId = getXTDevId($global.platform)
    const result = await this.$ajax.post(EnumApiUser.SilenceBind, {
      trustedDeviceInfo: {
        ...deviceInfo,
        memberId: options.memberId,
        tempToken: options.tempToken,
        xtDevId: current,
        webgl,
        audio,
        canvas
      }
    })

    if (typeof result === 'object' && result !== null && 'access_token' in result) {
      // 登录成功
      await this.onSetToken(result)
      await this.onSaveDeviceInfo()
    }
  }

  /**
   * 登录
   * @param itcode
   * @param passworid
   */
  async onLogin(
    body: { itcode?; password?; code?; tempToken? },
    grant_type:
      | 'wxopen_code'
      | 'wxmp_code'
      | 'sms_code'
      | 'email_code'
      | 'email_pwd'
      | 'phone_pwd'
      | 'wx_applets2'
      | 'temp_token' = 'phone_pwd',
    headers?: object
  ) {
    switch (grant_type) {
      case 'wxmp_code':
      case 'wx_applets2':
        sessionStorage.setItem('last-login-type', `${LastLoginType.Wechat}`)
        break
      case 'sms_code':
      case 'email_code':
        sessionStorage.setItem('last-login-type', `${LastLoginType.SMSCode}`)
        break
      case 'email_pwd':
      case 'phone_pwd':
        sessionStorage.setItem('last-login-type', `${LastLoginType.Password}`)
        break
    }

    this.onToggleLoading(true)
    try {
      body = lodash.mapValues(body, encodeURIComponent)
      const res = await this.$ajax
        .request<OAuthRequestError | { access_token: string }>({
          url:
            EnumApiUser.Login +
            lodash.get(
              {
                phone_pwd: `?scope={scope}&grant_type={grant_type}&phone_num={phone_num}&password={password}`,
                wxmp_code: `?scope={scope}&grant_type={grant_type}&code={code}`,
                wxopen_code: `?scope={scope}&grant_type={grant_type}&code={code}`,
                sms_code: `?scope={scope}&grant_type={grant_type}&phone_num={phone_num}&code={code}`,
                email_code: `?scope={scope}&grant_type={grant_type}&email={email}&code={code}`,
                email_pwd: `?scope={scope}&grant_type={grant_type}&email={email}&password={password}`,
                // 微信授权登录
                wx_applets2: '?scope={scope}&tempToken={tempToken}&verifyCode={code}&grant_type={grant_type}',
                temp_token: '?scope={scope}&tempToken={tempToken}&grant_type={grant_type}'
              },
              grant_type
            ),
          method: 'post',
          headers: {
            Authorization: 'Basic dXNlci1jbGllbnQ6dXNlci1zZWNyZXQtODg4OA==',
            ...(headers ?? {})
          },
          body: {
            scope: 'all',
            grant_type: grant_type,
            phone_num: body.itcode,
            email: body.itcode,
            password: body.password,
            code: body.code,
            tempToken: body.tempToken,
            // 使用完参数清理 body
            delete: true
          }
        })
        .toPromise()

      if (!isOAuthRequestError(res)) {
        await this.onSetToken(res, grant_type)
        await this.onSaveDeviceInfo()
        // 登录成功
        return
      }

      // 检测是否是登录设备绑定
      if ([OAuthError.AutoBind, OAuthError.ManualBind, OAuthError.BindLimit].includes(res.errCode as any)) {
        switch (res.errCode) {
          case OAuthError.AutoBind:
            await this.onAutoBindDevice(JSON.parse(res.errMsg))
            if ($global.platform === 'PC') {
              // window.location.reload()
              window.$nuxt.$store.$storeUser.onToggleVisible(false)
            } else {
              window.$nuxt.$router.replace({ path: '/' })
            }
            return
          case OAuthError.ManualBind:
          case OAuthError.BindLimit:
            window.$nuxt.$store.$device.setBindDeviceMeta({
              errCode: res.errCode,
              ...JSON.parse(res.errMsg)
            })
            if ($global.platform === 'PC') {
              window.$nuxt.$store.$storeUser.onToggleVisible(false)
              window.$nuxt.$store.$device.setShowDeviceControlModal(true)
            } else {
              // 当前是否在my页面
              if (window.$nuxt.$route.name === 'my') {
                window.$nuxt.$store.$device.setShowDeviceControlModal(true)
              } else {
                // 重定向到登录弹窗页
                window.$nuxt.$router.replace({ name: 'my', params: { action: 'bindDevice' } })
              }
            }
            break
        }
      }
      throw new Error('device bind')
    } catch (error) {
      if (![2031100001, 2031100002].includes(error?.response?.errCode)) {
        // 认证相关的错误码，不做登出处理
        await this.onOutLogin()
      }

      this.onToggleLoading(false)
      // console.error("LENG: ControllerUser -> onLogin -> error", error)
      throw error
    }
  }

  /**
   * 登出
   */
  async onLogout() {
    if (this.UserInfo.access_token) {
      try {
        await this.$ajax.post(EnumApiUser.Logout)
      } catch {}
    }
  }

  async onSetToken(res, grant_type?: string) {
    this.onToggleLoading(false)
    this.setUserInfo(res)

    // 内嵌webview后面再请求myself
    if (navigator.userAgent.toLowerCase().includes('miniprogram')) {
      return
    }

    await this.onGetUserInfo()
  }

  /**
   * 通过code判断用户是否绑定了手机号或邮箱
   * @param code
   */
  @action
  async code4Judge(code: string) {
    try {
      this.onToggleLoading(true)

      const res = await this.$ajax
        .request<XTUCCode4JOptions | undefined>({
          url: `${EnumApiUser.Code4Judge}?code=${code}`,
          method: 'get',
          headers: {
            Authorization: 'Basic dXNlci1jbGllbnQ6dXNlci1zZWNyZXQtODg4OA=='
          }
        })
        .toPromise()

      // 保存下临时token
      if (res.tempToken) {
        this.tempToken = res.tempToken
      }
      return res
    } catch (error) {
      throw error
    } finally {
      this.onToggleLoading(false)
    }
  }

  @action
  async check4Bind({ tempToken, verifyCode }: { tempToken: string; verifyCode: string }) {
    try {
      const res = await this.$ajax
        .request<XTUCPhone4JOptions>({
          url: EnumApiUser.Check4Bind,
          method: 'put',
          body: {
            tempToken,
            verifyCode
          }
        })
        .toPromise()

      return res
    } catch (error) {
      throw error
    }
  }

  RefreshAwait: Promise<any>

  InvokeDeviceChangeAPI: boolean = false

  RefrshTokenList: string[] = []

  @action
  updateLiveStatus(live: boolean): void {
    window.dispatchEvent(new CustomEvent<boolean>('updateLiveIconStatus', { detail: live }))
    this.isExistLive = live
  }

  @action
  onSetRefreshToken(token: string) {
    this.UserInfo['refresh_token'] = token
  }
  // 用来改变安全设置中的邮箱以及手机绑定成功窗口关闭
  @action
  onChangeBindVisible(status: boolean) {
    this.isOnlyBindVisible = status
  }

  async onAuthPCBindDevice(headers?: Record<string, string>) {
    const params = window.$nuxt.$store.$device.authForPCParams

    if (!params) {
      throw new Error('params not found')
    }

    const deviceInfo = await getDeviceInfo()
    const result = await this.$ajax.post(
      EnumApiUser.ShortcutDeviceBind,
      {
        deviceInfo: {
          ...deviceInfo,
          memberId: params.memberId,
          ownerDevId: params.authDevId
        },
        refreshToken: params.refreshToken
      },
      headers
    )

    return result
  }

  async onAuthH5BindDevice(headers?: Record<string, string>) {
    const params = window.$nuxt.$store.$device.authForH5Params

    if (!params) {
      throw new Error('params not found')
    }

    const deviceInfo = await getDeviceInfo()
    const result = await this.$ajax.post(
      EnumApiUser.AppletBind,
      {
        deviceInfo: {
          ...deviceInfo,
          memberId: params.memberId,
          ownerDevId: params.authDevId
        },
        refreshToken: params.refreshToken
      },
      headers
    )

    return result
  }

  /**
   * token 快过期 续约
   */
  async onExpirationRefresh(request?, headers?: Record<string, string>) {
    if (
      lodash.includes(lodash.get(request, 'url'), EnumApiUser.Login) ||
      lodash.includes(lodash.get(request, 'url'), EnumApiUser.ShortcutDeviceBind) ||
      lodash.includes(lodash.get(request, 'url'), EnumApiUser.AppletBind) ||
      lodash.includes(lodash.get(request, 'url'), EnumApiUser.SaveDeviceInfo) ||
      lodash.endsWith(lodash.get(request, 'url'), '/date')
    ) {
      return console.warn('认证 不校验 token')
    }
    // console.warn('执行onExpirationRefresh', request?.url, JSON.stringify(request ?? {}), this.RefreshAwait === undefined)

    // 判断当前内存的UserInfo和localStorage是否一致，如果不一致可能当前localStorage是最新的，可以直接使用
    const local = localStorage.getItem(`${$global.localStorageStartsWith}User`)
    if (this.UserInfo?.access_token && local) {
      try {
        const {
          UserInfo: { access_token, ...rest }
        } = JSON.parse(local)

        if (access_token !== this.UserInfo.access_token) {
          this.setUserInfo({ access_token, ...rest })
          reportSumLog('app.store-storage.sync')
          return console.warn('同步store')
        }
      } catch (e) {
        reportEntryLog('app.store-storage.sync-error', { e })
      }
    }

    try {
      if (!this.RefreshAwait) {
        const refresh_token = this.UserInfo.refresh_token
        const minutes = this.expirationTime.diff(AjaxBasics.serviceDate.valueOf(), 'minutes')
        if (refresh_token && minutes <= 1) {
          headers = Object.assign(
            {},
            $global.isEmbeddedH5Page
              ? {
                  'x-appletEmbed': '1',
                  'x-authType': '2'
                }
              : {},
            headers ?? {}
          )

          this.RefreshAwait = (() => {
            // 防止一下并发刷新token
            if (this.RefrshTokenList.includes(refresh_token)) {
              reportSumLog('refresh-token.duplicate.error')
              return
            }

            this.RefrshTokenList.push(refresh_token)

            return this.$ajax
              .request({
                url: EnumApiUser.Login + `?grant_type={grant_type}&refresh_token={refresh_token}`,
                method: 'post',
                headers: {
                  Authorization: 'Basic dXNlci1jbGllbnQ6dXNlci1zZWNyZXQtODg4OA==',
                  ...headers
                },
                body: {
                  grant_type: 'refresh_token',
                  refresh_token,
                  // 使用完参数清理 body
                  delete: true
                }
              })
              .toPromise()
          })()
        }
      }

      // 统计日志开始
      if (this.RefreshAwait) {
        try {
          const refresh_token = this.UserInfo.refresh_token
          const access_token = this.UserInfo.access_token
          const minutes = this.expirationTime.diff(AjaxBasics.serviceDate.valueOf(), 'minutes')
          reportEntryLog('debug.refresh-token', {
            minutes,
            exp: lodash.get(this.JwtPayload, 'exp', 0),
            url: request?.url,
            clientTime: Date.now(),
            headers,
            serverTime: AjaxBasics.serviceDate.valueOf(),
            refresh_hash: hash(refresh_token),
            access_hash: hash(access_token)
          })
        } catch {}
      }
      // 统计日志结束

      let res: any = await this.RefreshAwait
      if (isOAuthRequestError(res)) {
        this.clearUserInfo()

        if (res.errCode === OAuthError.UnknownDevice) {
          res = await this.onAuthPCBindDevice(headers)
        } else if (res.errCode === OAuthError.UnknowAuthDevice) {
          res = await this.onAuthH5BindDevice(headers)
        }
      }

      if (res) {
        this.setUserInfo(lodash.merge(request ? toJS(this.UserInfo) : {}, res))
        // token续约完成后，调用device/change接口
        // console.warn('token续约完成后，调用device/change接口')
        // 防止并发多次调用
        if (!this.InvokeDeviceChangeAPI) {
          this.InvokeDeviceChangeAPI = true
          try {
            await this.onSaveDeviceInfo()
          } finally {
            this.InvokeDeviceChangeAPI = false
          }
        }
      }
    } catch (error) {
      console.error(error)
      // this.onOutLogin()
    }
    this.RefreshAwait = undefined
  }
  /**
   * 用户异步数据加载订阅
   * @type {Promise<any>}
   * @memberof ControllerUser
   */
  GetUserAsync = new Subject()

  isMobile() {
    const platform = process.env.platform
    return platform === 'Mobile'
  }

  getCalmnessPath() {
    return '/calmness'
  }

  isUserInCalmnessPage() {
    const path = this.getCalmnessPath()
    return window.location.pathname === path
  }

  shouldIgnoreRedirect() {
    const ignorePaths = ['/protocol/service', '/protocol/privacy', '/other/service', '/other/privacy']
    return !ignorePaths.includes(window.location.pathname)
  }

  async handleHashLogin() {
    if (window.location.hash === '') return
    const hash = window.location.hash.slice(1)
    // 删除hash
    history.replaceState(history.state, null, `${location.origin}${location.pathname}${location.search}`)

    // 解析hash中的参数
    try {
      const args = JSON.parse(decodeURIComponent(hash))
      // source参数，记录来源，被哪个小程序打开
      if (args.s) {
        sessionStorage.setItem('__xt__embedded__source', args.s)
      }

      if (!args.c) {
        return
      }

      // 小程序授权H5登录
      if (args.c === 'clear') {
        this.clearUserInfo()
        localStorage.removeItem(BackupKeys.user_uc)
      } else {
        // 把c参数保存下来
        const cList: string[] = JSON.parse(sessionStorage.getItem('hash_c_lists')) ?? []

        if (cList.some(cHistory => cHistory === args.c)) {
          // 检测到了重复的c参数，属于异常刷新，应该主动放弃本次的login
          // sessionStorage.setItem('debug_warn_dup_arg_c', args.c)
          return
        }

        cList.push(args.c)
        sessionStorage.setItem('hash_c_lists', JSON.stringify(cList))
        await this.onLogin({ tempToken: args.c }, 'temp_token', {
          'x-appletEmbed': '1',
          'x-authType': '2'
        })
      }
    } catch (e) {
      console.error('debug handleHashLogin error!', e)
    }
  }

  async init() {
    const isIOS = $global.isiOS
    const isEmbeddedH5Page = $global.isEmbeddedH5Page
    // 如果是ios & 内嵌打开的页面  需要隐藏掉所有的价格
    if (isIOS && isEmbeddedH5Page) {
      try {
        const res: any = await this.$ajax.get(
          '/mocks/switch.json',
          {},
          { 'Cache-Control': 'no-store', 'If-Modified-Since': '0' },
          {
            target: $global.production ? 'https://static.xuantong.cn/' : 'https://testing-static.xuantong.cn'
          }
        )
        $global.setPaymentShowPrice(res.iosPay || false)
      } catch (error) {}
    }
  }

  /**
   * 获取用户信息
   */
  async onGetUserInfo() {
    const isEmbed = (window as any).__wxjs_environment === 'miniprogram'
    // 如果是去mobile fallback页面的，不在此处请求用户信息，携来c参数到fallback页面再请求myself
    // const isToAuthFallbackLogin = Boolean(new URL(location.href).searchParams.get('c'))

    // 处理c参数
    await this.handleHashLogin()

    await this.init()

    // await sleep(4000)
    if (!this.loggedIn) {
      // 加载完成 返回
      this.GetUserAsync.complete()
      return
    }
    try {
      await this.onExpirationRefresh()
      const res = await this.$ajax.get<UserInfo>(EnumApiUser.Userinfo)
      // 获取账号状态
      if (res.id) {
        const _alertInfo = await this.$ajax.get<AccountAlertInfo>(EnumApiUser.AccounBanInfo, {
          memberId: res.id,
          devId: $global.devId
        })
        const alertInfo = parseAccountBanInfo(_alertInfo)
        this.setAccountBanInfo(alertInfo)
      }
      const info = lodash.assign(toJS(this.UserInfo), res)
      this.setUserInfo(info)

      if (this.level !== 10) {
        runInAction(() => {
          this.progress = ((this.count - this.sumexperience[this.level]) / this.upgrade[this.level + 1]) * 100
        })
      }

      if (this.UserInfo.status === 3 && !this.isUserInCalmnessPage() && this.shouldIgnoreRedirect()) {
        window.location.href = this.getCalmnessPath()
        return
      } else if (this.UserInfo.status === 1 && this.isUserInCalmnessPage() && !isEmbed) {
        // // 内嵌直接回首页
        // if (isEmbed) {
        //   wx.miniProgram.reLaunch({
        //     url: '/pages/index/index'
        //   })

        //   return
        // }

        // 用户取消注销，刷新页面依旧停留在CalmnessPage
        window.location.href = '/'
        return
      }

      if (this.UserInfo.status === 3) {
        this.GetUserAsync.complete()
        return
      }

      this.onGetCourseListHasbuy()
      if (!this.giftsLoaded) {
        this.onGetGifts()
        this.onGetCouponRefundNotice()

        // 6.30
        // const tabbarArr = ['index', 'toclass', 'my']
        // if (this.isMobile() && window.$nuxt.$route) {
        //   if (!tabbarArr.includes(window.$nuxt.$route.name)) {
        //     this.onGetUniformGift()
        //   }
        // } else {
        //   this.onGetUniformGift()
        // }

        this.giftsLoaded = true
      }
      await this.onGetUserReceivedCouponList()
      // 设置智齿客服客户信息
      this.setZhichiUser(info)
    } catch (error) {
      console.error(error)
      // this.onOutLogin()
    }
    this.GetUserAsync.complete()
  }
  /**
   * 更新用户信息
   */
  async onUpdatingUserInfo() {
    try {
      const res: any = await this.$ajax.get(EnumApiUser.Userinfo)
      this.setUserInfo(lodash.assign(toJS(this.UserInfo), res))

      if (this.UserInfo.status === 3 && !this.isUserInCalmnessPage() && this.shouldIgnoreRedirect()) {
        window.location.href = this.getCalmnessPath()
        return
      } else if (this.UserInfo.status === 1 && this.isUserInCalmnessPage()) {
        // 用户取消注销，刷新页面依旧停留在CalmnessPage
        window.location.href = '/'
        return
      }
      return res
    } catch (error) {
      console.error(error)
    }
  }
  /**
   * 获取用户笔山
   */
  async onGetBishans() {
    const res = await this.$ajax.get(EnumApiUser.Bishans)
    this.setUserInfo(lodash.assign(toJS(this.UserInfo), { bishans: res }))
  }
  /**
   * 请求用户购买课程的时间点
   */
  GetCourseListHasbuyTime = undefined
  /**
   * 获取我购买的课程ids
   */
  async onGetCourseListHasbuy() {
    try {
      if (!this.loggedIn) {
        return
      }
      // 小于20秒 忽略
      if (this.GetCourseListHasbuyTime && moment().diff(this.GetCourseListHasbuyTime, 'seconds') < 20) {
        return
      }
      this.GetCourseListHasbuyTime = Date.now()
      const res: Array<number> = await this.$ajax.get(EnumApiCourse.CourseListHasbuy)
      // 是否有团购订单
      const groupHasBuy: Array<any> = await this.$ajax.get(EnumApiProduct.productHasBuy)

      Course.onSetUserHasbuy(res, groupHasBuy)
      this.setCourseListHasbuy(res, groupHasBuy)
    } catch (error) {
      console.error(error)
    }
    // 加载完成 返回
    this.GetUserAsync.complete()
  }
  async onRefreshCourseListHasbuy(isMounted?: string) {
    try {
      if (!this.loggedIn) {
        return
      }
      const res: Array<number> = await this.$ajax.get(EnumApiCourse.CourseListHasbuy)
      // 是否有团购订单
      const groupHasBuy: Array<any> = await this.$ajax.get(EnumApiProduct.productHasBuy)
      Course.onSetUserHasbuy(res, groupHasBuy)
      this.setCourseListHasbuy(res, groupHasBuy)
    } catch (error) {
      console.error(error)
    }
    // 加载完成 返回
    this.GetUserAsync.complete()
  }

  /**
   * 获取赠送给我的课程列表
   */
  async onGetGifts() {
    const res: Array<any> = await this.$ajax.get(EnumApiOrder.OrderGifts)
    this.setOrderGifts(res)
    this.setOrderGiftsData(res)
  }

  async onGetUserReceivedCouponList() {
    const res = await this.$ajax.get<Array<BaseCouponItem>>(EnumApiCoupon.CouponMessage)
    if (Array.isArray(res)) {
      this.setUserReceivedCouponList(res)
    }
  }

  /**
   * 接收礼物
   */
  async onReceiveGift(id) {
    let res = await this.$ajax.put(EnumApiOrder.ReceiveGifts, { id })
    return res
  }

  onResetGiftPopup() {
    const keys = []
    this.OrderGiftsData.forEach(item => {
      keys.push(`gift-${item.orderNo}`)
    })
    keys.forEach(key => {
      localStorage.removeItem(key)
    })
    this.onUpdateOrderGifts(this.OrderGiftsData)
  }

  onUpdateOrderGifts(list: Array<any>) {
    this.setOrderGifts(list)
  }

  /** 查询我的礼物领取状态 */
  async onGetGiftReceiveStatus(orderNo: string) {
    return this.$ajax.get<0 | 1 | 2>(EnumApiOrder.GiftReceiveStatus, { orderNo })
  }

  /**
   * 6.30 统一的礼物弹窗
   */
  async onGetUniformGift() {
    const rejectNoticeReq = this.$ajax.get<any[]>(EnumApiOrder.OrderGiveawayRejectRefundNotice)
    const noticeReq = this.$ajax.get<any[]>(EnumApiOrder.OrderGiveawayRefundNotice)
    const unclaimedReq = this.$ajax.get<any[]>(EnumApiOrder.OrderUnclaimed)
    const giftReq = this.$ajax.get<any[]>(EnumApiOrder.OrderGifts)
    let [rejectNotice, refundNotice, unclaimedGiveaway, gifts] = await Promise.all([rejectNoticeReq, noticeReq, unclaimedReq, giftReq])

    rejectNotice = rejectNotice.length > 0 ? rejectNotice.map(v => ({ type: 'reject-notice', ...v })) : rejectNotice
    refundNotice = refundNotice.length > 0 ? refundNotice.map(v => ({ type: 'notice', ...v })) : refundNotice
    unclaimedGiveaway = unclaimedGiveaway.length > 0 ? unclaimedGiveaway.map(v => ({ type: 'giveaway', orderNo: v })) : unclaimedGiveaway
    gifts = gifts.length > 0 ? gifts.map(v => ({ type: 'gift', ...v })) : gifts

    const uniformData = [...rejectNotice, ...refundNotice, ...unclaimedGiveaway, ...gifts]
    this.setUniformGift(uniformData)
    this.setUniformGiftData(uniformData)
  }

  async onGetCouponRefundNotice() {
    const resp = await this.$ajax.get<any[]>(EnumApiOrder.couponRefundNotice)
    this.setCouponRefundNotice(resp)
    this.setCouponRefundNoticeData(resp)
  }

  async onCouponConfirmRefund(couponId: number) {
    try {
      const resp = await this.$ajax.post<boolean>(EnumApiOrder.couponConfirmRefund, { couponId })
      return resp
    } catch (error) {
      return false
    }
  }

  /**
   * 6.30
   * 获取已认领赠品
   */
  async onGetClaimedGift() {
    const claimedGift = await this.$ajax.get<string[]>(EnumApiOrder.OrderClaimed)
    this.setClaimedGift(claimedGift)
  }

  /**
   * 6.30 赠品确认退还
   */
  async onGiveawayRefundConfirm(giftsRecordId: number) {
    const resp = await this.$ajax.post<boolean>(EnumApiOrder.OrderGiveawayRefundConfirm, { giftsRecordId })
    return resp
  }

  /**
   * 6.30 赠品拒绝退还
   */
  async onGiveawayRefundReject(giftsRecordId: number) {
    const resp = await this.$ajax.post<boolean>(EnumApiOrder.OrderGiveawayRefundReject, { giftsRecordId })
    return resp
  }

  /**
   * 6.30
   */
  onResetUniformGift() {
    const keys = []
    this.uniformGiftData.forEach(item => {
      if (item.type === 'notice') {
        keys.push(`refund-notice-${item.orderNo}`)
      } else if (item.type === 'giveaway') {
        keys.push(`giveaway-${item.orderNo}`)
      } else if (item.type === 'gift') {
        keys.push(`gift-${item.productId}`)
      }
    })
    keys.forEach(key => {
      localStorage.removeItem(key)
    })
    this.setUniformGift(this.uniformGiftData)
  }

  /**
   * 退出登录状态
   */
  async onOutLogin() {
    let _this = this
    if (_this.UserInfo.access_token) {
      try {
        const xtChanner_asdew_asd23 = new BroadcastChannel('xtChanner_asdew_asd23')
        xtChanner_asdew_asd23.postMessage('xt_login_out')
      } catch (e) {
        console.log(e)
      }
      // 先备份必要字段 DEBUG!!!!
      const stashKeys = [
        BackupKeys.user_uc,
        BackupKeys.user_show,
        StorageKeys.LastDevIdH5,
        StorageKeys.LastDevIdPC,
        StorageKeys.LastMemberIdH5,
        StorageKeys.LastMemberIdPC,
        StorageKeys.LastXTDevIdH5,
        StorageKeys.LastXTDevIdPC,
        StorageKeys.TraceId,
        StorageKeys.UserIgnoredToMiniprogramPop,
        StorageKeys.AccountAlertModal
      ]

      const stash = {}
      stashKeys.forEach(key => {
        stash[key] = localStorage.getItem(key) || null
      })

      this.clearUserInfo()
      localStorage.clear()

      // 恢复下之前的登录数据
      Object.keys(stash).forEach(key => {
        if (stash[key]) {
          localStorage.setItem(key, stash[key])
        }
      })
    }
  }

  clearUserInfo() {
    this.setUserInfo({})
  }

  /**
   * 更新个人信息
   */
  async onUpdateUserInfo(body: {
    id?: number //id
    address?: string // 地址
    birthday?: string // 生日
    career?: string // 职业
    education?: string // 学历
    industry?: string // 行业
    location?: number // 所在地（0 未知，1 国内，2国外）
    city?: string // 城市
    country?: string // 国家
    nickname?: string // 昵称
    province?: string // 省份
    sex?: number // 性别(0 未知 1 男 2女)
    headerThumbUrl?: string // 头像缩略图
    headerUrl?: string // 用户头像
  }) {
    await this.$ajax.put(EnumApiUser.UpdateUserInfo, body)
    this.setUserInfo(lodash.assign(toJS(this.UserInfo), body))
  }

  /** 登录相关 */

  /**
   * 手机验证码登录
   * @param body
   */
  async onLoginByPhone(phoneNum) {
    await this.$ajax
      .request({
        url: EnumApiUser.LoginByPhone,
        method: 'POST',
        headers: {
          'Content-Type': 'application/xml'
        },
        body: phoneNum
      })
      .toPromise()
  }

  /**
   * 邮箱验证码登录
   * @param body
   */
  async onLoginByEmail(email) {
    await this.$ajax
      .request({
        url: EnumApiUser.LoginByEmail,
        method: 'POST',
        headers: {
          'Content-Type': 'application/xml'
        },
        body: email
      })
      .toPromise()
  }

  /**
   * 手机验证码找回
   * @param body
   */
  async onFindPwdByPhone(phoneNum) {
    await this.$ajax
      .request({
        url: EnumApiUser.FindPwdByPhone,
        method: 'POST',
        headers: {
          'Content-Type': 'application/xml'
        },
        body: phoneNum
      })
      .toPromise()
  }

  /**
   * 邮箱验证码找回
   * @param body
   */
  async onFindPwdByEmail(email) {
    await this.$ajax
      .request({
        url: EnumApiUser.FindPwdByEmail,
        method: 'POST',
        headers: {
          'Content-Type': 'application/xml'
        },
        body: email
      })
      .toPromise()
  }

  /**
   * 检测手机号是否存在
   * @param body
   */
  // async onCheckPhone(body: { phoneNum?: string }) {
  //   await this.$ajax.get(EnumApiUser.CheckPhone, body)
  // }

  /**
   * 检测邮箱是否存在
   * @param body
   */
  // async onCheckEmail(body: { email?: string }) {
  //   await this.$ajax.get(EnumApiUser.CheckEmail, body)
  // }

  /**
   * 绑定邮箱
   * @param body
   */
  async onBindEmail(body: {
    email?: string //邮箱
    verifyCode?: string //验证码
  }) {
    return await this.$ajax.post(EnumApiUser.BindEmail, body)
  }

  /**
   * 绑定手机
   * @param body
   */
  async onBindPhone(body: {
    phoneNum?: string //手机号码
    verifyCode?: string //验证码
  }) {
    return await this.$ajax.post(EnumApiUser.BindPhone, body)
  }

  /**
   * 绑定微信
   * @param body
   */
  async onBindwechat(body: {
    code?: any //wxcode
    wxPlatformType?: number //微信平台 1 开放平台，2 公众平台
  }) {
    return await this.$ajax.post(EnumApiUser.Bindwechat, body)
  }

  /**
   * 校验绑定微信的账户是否一致
   * @param body
   * @returns
   */
  async onCheckBind(body: { code: any; memberId: string }) {
    try {
      const resp = await this.$ajax.get<boolean>(EnumApiUser.BindCheck, body)
      return resp
    } catch (error) {
      if (error === false) {
        return error
      }
    }
  }

  /**
   * 修改邮箱
   * @param body
   */
  async onChangeEmail(body: {
    newEmail?: string //新邮箱
    newEmailVerifyCode?: string //新邮箱验证码
    oldEmail?: string //旧邮箱
    oldEmailVerifyCode?: string //旧邮箱验证码
  }) {
    return await this.$ajax.post(EnumApiUser.ChangeEmail, body)
  }

  /**
   * 修改手机号
   * @param body
   */
  async onChangePhone(body: {
    newPhoneNum?: string //新手机号
    newPhoneVerifyCode?: string //新手机验证码
    oldPhoneNum?: string //旧手机号
    oldPhoneVerifyCode?: string //旧手机验证码
  }) {
    return await this.$ajax.post(EnumApiUser.ChangePhone, body)
  }

  /**
   * 手机号找回密码
   * @param body
   */
  async onFindByPhone(body: {
    confirmNewPassword?: string //确认密码
    newPassword?: string //新密码
    phoneNum?: string //手机号码
    verifyCode?: string //验证码
  }) {
    return await this.$ajax.post(EnumApiUser.FindByPhone, body)
  }

  /**
   * 邮箱找回密码
   * @param body
   */
  async onFindByEmail(body: {
    confirmNewPassword?: string //确认密码
    newPassword?: string //新密码
    email?: string //邮箱
    verifyCode?: string //验证码
  }) {
    return await this.$ajax.post(EnumApiUser.FindByEmail, body)
  }

  /**
   * 修改密码
   * @param body
   */
  async onUpdatePwd(body: {
    confirmNewPassword?: string //确认密码
    newPassword?: string //新密码
    oldPassword?: string //旧密码
  }) {
    return await this.$ajax.put(EnumApiUser.UpdatePwd, body)
  }

  /**
   * 解绑微信
   * @param body
   */
  async onUnbindwechat() {
    return await this.$ajax.post(EnumApiUser.Unbindwechat)
  }

  /**
   * 获取验证码
   * @param body
   */
  async onGetCode(body: {
    device?: string //设备
    verifyCodeType?: number
    // 验证码类型
    // 1 手机验证码登录，2 邮箱验证码登录，3 绑定手机，4 绑定邮箱，5 更换手机，6 更换邮箱，7 手机找回密码，8 邮箱找回密码
  }) {
    let res = await this.$ajax.post(EnumApiUser.Verifycodes, body)
    if (lodash.eq(res, false)) throw EnumMessage.send_code_error
  }

  /**
   * 不校验登录获取验证码
   * @param body
   */
  async onGetCodeIgnoreLogin(body: {
    device?: string //设备
    tempToken?: string
    verifyCodeType?: number
    // 验证码类型
    // 10 登录绑定手机号，11 登录绑定邮箱
  }) {
    let res = await this.$ajax.post(EnumApiUser.VerifycodesWhitelist, body)
    if (lodash.eq(res, false)) throw EnumMessage.send_code_error
  }

  /**
   * 校验验证码
   * @param body
   */
  async onCheckCode(body: {
    device?: string //设备
    verifyCode?: string //验证码
    verifyCodeType?: number
    // 验证码类型
    // 1 手机验证码登录，2 邮箱验证码登录，3 绑定手机，4 绑定邮箱，5 更换手机，6 更换邮箱，7 手机找回密码，8 邮箱找回密码
  }) {
    await this.$ajax.put(EnumApiUser.VerifycodesCheck, body)
  }

  /**
   * 根据验证码获取一个临时token給注销账号使用
   * @param body
   */
  async onGetTempTokenForCancelAccount(body: {
    device: string // 设备
    verifyCode: string // 验证码
    verifyCodeType: string // 1 手机验证码登录，2 邮箱验证码登录，3 绑定手机，4 绑定邮箱，5 更换手机，6 更换邮箱，7 手机找回密码，8 邮箱找回密码，10 小程序登录绑定手机号，11 小程序登录绑定邮箱
  }) {
    const tempToken = await this.$ajax
      .request<string>({
        url: EnumApiCurrency.GetTempToken,
        body,
        method: 'put',
        responseType: 'text'
      })
      .toPromise()

    if (!tempToken) {
      throw new Error(EnumMessage.verify_failed)
    }

    this.cancelAccountTempToken = tempToken
  }

  /**
   * 获取用户基本信息
   * @param device
   */
  async onBaseinfo(device) {
    return this.$ajax.get<void | { id: string; nickname: string }>(EnumApiUser.baseinfo, { device })
  }

  /**
   * 获取登录二维码
   * @returns
   */
  async onGetLoginQrcode(deviceInfo) {
    try {
      const str = await this.$ajax
        .request({
          url: EnumApiUser.LoginQrcode,
          method: 'post',
          responseType: 'text',
          body: deviceInfo
        })
        .toPromise()
      return str
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 轮询获取登录状态
   * @param uuid 唯一
   */
  async onPollingQrcodeState(uuid: string) {
    const res = await this.$ajax.get(EnumApiUser.QrcodeStatePolling, { uuid })
    return res
  }

  /**
   * 轮训获取绑定微信状态
   * @param uuid 唯一
   */
  async onPollingQrcodeWXBindState(uuid: string) {
    return await this.$ajax.get(EnumApiUser.BindwxStatus, { uuid })
  }

  /**
   * 获取绑定微信小程序二维码
   * @param page 页面 page
   * @param query 后台生成预览二维码时传参为空，前台生成分享二维码时传参query参数
   * @returns
   */
  async onGetBindWXQrcode(page, query?) {
    try {
      const str = await this.$ajax
        .request({
          url: EnumApiUser.AppletQrcode,
          method: 'get',
          responseType: 'blob',
          body: { page, query },
          headers: {
            'Content-Type': 'image/jpeg'
          }
        })
        .toPromise()
      return str
    } catch (error) {
      console.log(error)
    }
  }

  @observable checkUserInfo: ICheckUserInfoResp = {}

  /**
   * 获取用户注销前需要校验的一些信息
   * @returns
   */
  async getUserCancelAccountCheckInfo() {
    const res = (await this.$ajax.get(EnumApiCurrency.checkWechat)) as ICheckUserInfoResp
    runInAction(() => {
      this.checkUserInfo = res
    })
    return res
  }

  /**
   * 注销账号/取消注销
   * @param cancel 是否注销 true注销，false取消注销
   */
  async cancelAccount(cancel: boolean) {
    const body: {
      cancel: boolean
      tempToken?: string
    } = { cancel }

    body.tempToken = cancel ? this.cancelAccountTempToken : ''
    // 用完清理
    this.cancelAccountTempToken = ''

    await this.$ajax.put(EnumApiCurrency.cancelWechat, body)
  }

  @observable verifyWechat: boolean = true

  @action
  updateVerifyWechat(flag: boolean) {
    this.verifyWechat = flag
  }

  @action
  showWeiXinBind(meta?: BindWXMetaType) {
    this.visibleWXBind = true
    if (meta) {
      this.bindWXMeta = meta
    }
  }

  @action
  hideWeiXinBind() {
    this.visibleWXBind = false
    this.bindWXMeta = null
  }

  @action
  showWeiXinVerify(meta: VerfiyWXMetaType) {
    this.visibleWXVerify = true
    this.verifyWXMeta = meta
  }

  @action
  hideWeiXinVerify() {
    this.visibleWXVerify = false
    this.verifyWXMeta = null
  }

  @action
  showWechatVerify(meta: VerifyWechatMetaType) {
    this.visibleVerifyWechat = true
    this.verifyWechatMeta = meta
  }

  @action
  hideWechatVerify() {
    this.visibleVerifyWechat = false
    this.verifyWechatMeta = null
  }

  @action
  toggleStudentWelfare(visible: boolean) {
    this.visibleStudentWelfare = visible
  }

  @action.bound
  public setAccountBanInfo(banInfo: AccountAlertInfo | null) {
    this.accountBanInfo = banInfo
  }

  async shareRecord(body: {
    showId: number | string
    worksId: number | string
    shareType: number
    shareContentType: number
    path: string
    templatePath: string
  }) {
    try {
      console.warn('即将生成分享记录信息', {
        appId: $global.xtAppid,
        devId: $global.devId,
        path: body.path,
        shareExtra: JSON.stringify({
          showId: body.showId,
          worksId: body.worksId,
          shareContentType: body.shareContentType,
          href: location.href
        }),
        shareType: body.shareType,
        templatePath: body.templatePath
      })

      await this.$ajax.post(EnumApiCurrency.shareRecordV2, {
        appId: process.env.TARO_PRIVATE_APPID,
        devId: $global.devId,
        path: body.path,
        shareExtra: JSON.stringify({
          showId: body.showId,
          worksId: body.worksId,
          shareContentType: body.shareContentType,
          href: location.href
        }),
        shareType: body.shareType,
        templatePath: body.templatePath
      })
    } catch {}
  }

  async onRefreshUserAddress() {
    try {
      const resp = await this.$ajax.get<{
        id: string | null
        city: string | null
        detailAddress: string | null
        district: string | null
        nickname: string | null
        phoneNum: string | null
        postalCode: string | null
        province: string | null
        street: string | null
      }>(EnumApiUser.UserAddress)

      if (typeof resp.id !== 'undefined') {
        this.onChangeUserAddress(resp)
        return resp
      }

      return null
    } catch {
      return null
    }
  }

  async onUpdateUserAddress(data: {
    city: string
    detailAddress: string
    district: string
    nickname: string
    phoneNum: string
    postalCode: string
    province: string
    street: string
  }) {
    try {
      if (!this.userAddress.id) return

      const resp = await this.$ajax.post(EnumApiUser.UserAddress, {
        ...data,
        id: this.userAddress.id
      })
      await this.onRefreshUserAddress()

      return resp === true
    } catch {
      return false
    }
  }

  /**
   * 校验用户是否存在
   * @param device 手机号｜邮箱
   * @param type 类型
   * @returns
   */
  async onValidAccountExist(entities: {
    device: string
    verifyCode: string
    /** 1 手机验证码登录，2 邮箱验证码登录，3 绑定手机，4 绑定邮箱，5 更换手机，6 更换邮箱，7 手机找回密码，8 邮箱找回密码，10 小程序登录绑定手机号，11 小程序登录绑定邮箱 */
    verifyCodeType: number
  }) {
    return await this.$ajax.post<boolean>(EnumApiUser.AccountExist, entities)
  }
}
export default ControllerUser
