/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-08-05 14:12:45
 * @modify date 2020-08-05 14:12:45
 * @desc [description]
 */
import '@xt/client/utils/socket'
import '@xt/client/utils/shared'
import '~/components/index'
import './icon'
import './usePlugins/alife-logger'
import './usePlugins/ant'
import i18n from './usePlugins/i18n'
import './usePlugins/lazyload'
import './usePlugins/swiper'
import './usePlugins/waterfall'
import '@xt/client/experimental/server-time/debug'
export default context => {
  console.log('LENG: context', context)
  i18n(context)
  console.groupEnd()
}
