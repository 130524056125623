import { Component, Prop, Vue, Provide, Inject, Watch, Emit } from 'vue-property-decorator'
import { CouponItem as CouponItemType, CouponInfoListItem } from '@xt/client/types/coupon'
import { EnumCouponType, EnumCouponValidTimeType, EnumPreferentialType, IS_COMPOSE_COPPER, IS_COMPOSE_COUPON } from '@xt/client/enums/order'
import { getCouponUseInfo } from '@xt/client/utils/coupon'
import { seconds2Hours } from '@xt/client/utils/parser'

@Component
export class MixinsCouponItem extends Vue {
  @Prop({ required: true }) data: CouponItemType
  @Prop({ default: true }) clickable: boolean
  @Prop({ default: false }) disabled: boolean
  // 时间类型，如果是activity则需要按照validType判断相对时间还是绝对时间，如果是coupon则可以直接取validStartTime｜validEndTime
  @Prop({ default: 'activity' }) timeType: 'activity' | 'coupon'

  // 券是否可以和别的券叠加
  get canUseWithAnotherCoupon(): boolean {
    return !!(this.data.composeFlag & IS_COMPOSE_COUPON)
  }

  // 使用本张券后是否可以使用铜板
  get canUseWithCopper(): boolean {
    return !!(this.data.composeFlag & IS_COMPOSE_COPPER)
  }

  // 是否是折扣券
  get isDiscountCoupon(): boolean {
    return this.data.preferentialType === EnumPreferentialType.Discount
  }

  // 当前券是否是
  get isRelativeTimeCoupon(): boolean {
    return this.timeType === 'activity' && this.data.validType === EnumCouponValidTimeType.Relative
  }

  // 是否是满减券
  get isFullReductionCoupon(): boolean {
    return this.data.preferentialType === EnumPreferentialType.FullReduction
  }

  get isCompanionCardCoupon(): boolean {
    return this.data.preferentialType === EnumPreferentialType.StudyBuff && this.data.couponType === EnumCouponType.Companion
  }

  get couponExpiredTime(): string {
    if (this.isRelativeTimeCoupon) return ''

    return this.moment(this.data.validEndTime).utcOffset(+8).format('YYYY.MM.DD HH:mm')
  }

  get couponStartTime(): string {
    if (this.isRelativeTimeCoupon) return ''

    return this.moment(this.data.validStartTime).utcOffset(+8).format('YYYY.MM.DD HH:mm')
  }

  get relativeCouponExpiredDay(): number {
    if (this.isRelativeTimeCoupon) {
      return this.data.validTimeDuration / 60 / 60 / 24
    }

    return 0
  }

  get companionCardDuration() {
    if (!this.isCompanionCardCoupon) {
      return 0
    }

    return seconds2Hours(this.data.denominations)
  }

  get couponTip(): string {
    const result: Array<string> = []

    if (this.data.couponType === EnumCouponType.Discount || this.data.couponType === EnumCouponType.FullReduction) {
      if (this.data.allowGiveUse) {
        result.push('限购买指定课程使用')
      } else {
        result.push('限自买指定课程使用')
      }

      if (!this.canUseWithCopper) {
        result.push('桐板儿不可用')
      }
    } else if (this.data.couponType === EnumCouponType.Exchange) {
      result.push('限兑换指定课程使用')
    } else if (`${this.data.couponType}`.startsWith('2')) {
      result.push('限暄桐文房微信店使用')
    } else if (`${this.data.couponType}`.startsWith('3')) {
      result.push('限学习指定课程使用')
    }

    return result.join('，')
  }

  // 券使用说明
  get couponInfoList(): Array<CouponInfoListItem> {
    const result = getCouponUseInfo(this.data, this.isRelativeTimeCoupon)
    return result
  }

  @Emit('click-use-info')
  handleClickUseInfo() {
    return {
      list: this.couponInfoList,
      coupon: this.data
    }
  }

  handleClick() {
    if (!this.disabled) {
      this.$emit('click', this.data.id)
    }
  }
}
