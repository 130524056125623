




















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { BaseCouponItem } from '@xt/client/types/coupon'
import { formatCouponInfo, FormatCouponInfo, getCouponUseInfo } from '@xt/client/utils/coupon/index'
import { EnumCouponType, EnumCouponValidTimeType, EnumPreferentialType } from '@xt/client/enums'

@Observer
@Component({
  components: {}
})
export default class GiftCoupon extends Vue {
  @Prop({ required: true }) data: BaseCouponItem
  @Prop({ required: true }) success: boolean
  formatCouponInfo: FormatCouponInfo

  get couponType(): 'FullReduction' | 'Discount' | 'Exchange' | 'Companion' {
    if (this.data.preferentialType === EnumPreferentialType.StudyBuff && this.data.couponType === EnumCouponType.Companion) {
      return 'Companion'
    }

    switch (this.data.preferentialType) {
      case EnumPreferentialType.FullReduction:
        return 'FullReduction'
      case EnumPreferentialType.Discount:
        return 'Discount'
      case EnumPreferentialType.Exchange:
        return 'Exchange'
    }
  }

  get couponUseInfo() {
    return getCouponUseInfo(this.data, !this.success)
  }

  get couponStatus() {
    if (this.success) return { text: '', style: '' }

    return this.data.stockQty === 0 ? { text: '已发完', style: 'black' } : { text: '发放失败，已达领取上限', style: 'red' }
  }

  handleClickUseInfo() {
    this.$emit('click-use-info', { list: this.couponUseInfo, coupon: this.data })
  }

  created() {
    this.formatCouponInfo = formatCouponInfo(this.data, this.success ? 'coupon' : 'activity')
  }
}
