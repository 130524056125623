/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-10-20 20:26:03
 * @modify date 2020-10-20 20:26:03
 * @desc 课程 课时详情
 */
import { Component, Prop, Vue, Provide, Inject, Watch, Emit } from 'vue-property-decorator'
import { EnumMessage } from '../../languages'
import { EnumCourseState } from '../../enums'
import lodash from 'lodash'
import { Course } from '../../entities'
@Component
export class MixinsCourseHelpers extends Vue {
  /** 课程数据 */
  @Prop({ default: () => ({}) }) dataSource: Course
  CourseHelpers = {
    /**
     * 课程未结课
     * @param dataSource
     */
    isNotEndofClass(dataSource: Course) {
      // 存在全阶
      if (dataSource.isFullHasOwn) {
        return !dataSource.isEndofClass
      }
      return !lodash.eq(dataSource.state, EnumCourseState.EndofClass)
    },
    /**
     * 已 开课
     */
    isInClass(dataSource: Course) {
      return dataSource.isInClass
    },
    /**
     * 自己是否购买课程
     * @param dataSource
     */
    isHasOwn: (dataSource: Course) => {
      return dataSource.isHasOwn
    },
    /**
     * 展示文字提示
     * @param dataSource
     */
    getEmptyText(dataSource: Course) {
      // 已报名
      if (dataSource.hasOwn) {
        // 单阶课及所属全阶课都已结课
        if (dataSource.isEndofClass) {
          return '课程结束啦，不能发布了哦'
        }
        // 未开课
        if (!dataSource.isInClass) {
          return '还未开课，再等等吧~'
        }
      }
      return false
    },
    /**
     * 检查课程权限
     */
    onCheck: (dataSource: Course) => {
      console.log('LENG: 校验的课程信息 ', dataSource)
      this.$InspectUser()
      if (!this.CourseHelpers.isNotEndofClass(dataSource)) {
        throw EnumMessage.course_end_of_class
      }
      if (!dataSource.hasOwn) {
        throw EnumMessage.course_not_registered
      }
      return true
    },
    /**
     * 检查课时权限
     */
    onCheckClasshour: (dataSource: { publishStatus: number }) => {
      console.log('LENG: 校验的课时信息 ', dataSource)
      if (dataSource.publishStatus !== 1) {
        throw EnumMessage.classhour_ont_publish
      }
      return true
    }
  }
}
