import { BindAll } from 'lodash-decorators'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EntitiesBasics } from '../basics/entities'
import { AtItem, GenseeLiveBase, LiveChannel, LiveQAItem, PublicChat, QAItemParsed } from './types.d'
import { chainLoadScript } from './utils'
import { action, observable, runInAction } from 'mobx'
import { WidgetParams } from './playback'
import moment from 'moment'
import { uuid } from '@xt/client/utils/uuid'
import lodash from 'lodash'
import global from '@xt/client/store/global'
import { EnumApiUser } from '@xt/client/api'
import socketClient from '@xt/client/utils/socket'
import { Loop } from '@xt/client/utils/loop'
import emojiList from './emoji'
import { reportEntryLog } from '@xt/client/utils/log'
import { VideoLog } from './log'

declare const GS: GenseeLiveBase

// 播放中 已暂停直播

export enum LiveStatus {
  Init = '直播未开始',
  Playing = '直播中...',
  // Paused = '已暂停',
  Ended = '直播结束'
}

@BindAll()
export class ControllerGenseeLive extends EntitiesBasics {
  private channel: LiveChannel
  private pollingLoop: Loop

  // 展示互动直播组件上的参数
  @observable widgetParams: WidgetParams = {
    ownerid: '',
    uid: '',
    uname: '',
    k: '',
    classhour: 0
  }

  // 当前账号的用户在不同浏览器上观看直播或回放 返回首页 此时账号以及被登出
  @observable isWatchOnOtherBrowser = false
  // 当前账号的用户在相同浏览器上观看直播或回放 返回首页 不登出
  @observable isWatchOnSameBrowser = false

  @observable isLoading = true
  skeletonLoadingStart = 0

  @observable liveStatus: LiveStatus = LiveStatus.Init

  liveVideoStatus: 'play' | 'pause' = 'pause'

  // 发送消息频繁的限制  单位：秒
  minInterval = 3
  canChat = true
  disableChat = false
  canQA = true
  disableQA = false

  // 聊天
  @observable chatList: Array<PublicChat> = []

  // 点击用户头像，将用户加入at列表中，可选择一个人at
  @observable atList: Array<AtItem> = []
  @observable atInfo: AtItem | null = null

  @observable answerInfo: { questionId?: string; qaContent?: string; answerername?: string } | null = null

  @observable netSettingList: Array<{ netName: string; label: string; selected: boolean }> = []

  // qa
  @observable qaList: Array<QAItemParsed> = []

  // 音量
  @observable volume = 75

  // 是否全屏
  @observable isFullScreen = false

  @observable authOpenSound = false
  openSoundTimer: NodeJS.Timer | null = null

  @observable mute = false

  emojiList = emojiList

  currentTabId: string

  // 页面10s还没有loading完增加弹窗提醒
  pageReloadTimer: null | ReturnType<typeof setTimeout>
  // 页面持续卡在loading
  @observable isPageLoadingTooLong = false

  isFirstVideoPlay = false

  get isPC() {
    return process.env.platform === 'PC'
  }

  constructor(protected $ajax: AjaxBasics) {
    super($ajax, {})

    // 初始化tabId数据
    this.currentTabId = sessionStorage.getItem('tab-id')
  }

  async init(groupName: string) {
    const scriptLoaded = await chainLoadScript(['/assets/jquery-1.9.1.min.js', '/assets/gssdk-1.3.js'])

    if (scriptLoaded) {
      const channel = GS.createChannel(groupName)
      this.channel = channel
      this.bindEvent()
    }
  }

  private bindEvent() {
    this.channel.bind('onDataReady', ({ data }) => {
      console.log('onDataReady~', data)
      this.minInterval = data.minInterval
      reportEntryLog(VideoLog.live_gensee_data_ready, { data, tabId: this.currentTabId })
    })

    this.channel.bind('onVideoConfig', e => {
      console.log('onVideoConfig', e)
    })

    this.channel.bind('onAPIError', ({ data }) => {
      console.log('onAPIError', data)
      reportEntryLog(VideoLog.live_gensee_error, { data, tabId: this.currentTabId })
    })

    this.channel.bind('onStatus', ({ data }) => {
      console.log('onStatus', data)
      // 直播未开始
      if (data.type === 2) {
        this.setLoading(false)
        this.setLiveStatus(LiveStatus.Init)
      }
      reportEntryLog(VideoLog.live_gensee_status, { data, tabId: this.currentTabId })
    })

    this.channel.bind('onPublicChat', ({ data }) => {
      console.log('onPubChat', data)
      this.appendChatList({ ...data, privateMessageInfo: null })
    })

    this.channel.bind('onPriChat', ({ data }) => {
      console.log('onPriChat', data)
      this.appendChatList({
        ...data,
        privateMessageInfo: {
          isSelfSendMessage: false,
          from: {
            uid: data.senderUid,
            uname: data.sender
          },
          to: {
            uid: this.widgetParams.uid,
            uname: this.widgetParams.uname
          }
        },
        // warn 以下两个数据接口给的空
        id: this.getUUID(),
        utctime: Date.now()
      })
    })

    this.channel.bind('onChatRemove', ({ data }) => {
      console.log('onChatRemove', data)
      data.censorList.forEach(({ type, id }) => {
        this.removeChatMessgae(type, id)
      })
    })

    this.channel.bind('onQAList', ({ data }) => {
      console.log('onQAList', data.list, this.parseQAList(data.list))
      this.setQAList(this.parseQAList(data.list))
    })

    this.channel.bind('onQA', ({ data }) => {
      console.log('onQA', data)
      this.appendQAItem(data)
    })

    this.channel.bind('onQARemove', ({ data }) => {
      console.log('onQARemove', data)
      this.removeQAItem(data.id)
    })

    this.channel.bind('onSetting', ({ data }) => {
      console.log('onSetting', data)
      if (data.option === 'chat') {
        this.disableChat = data.enable === 'false'
      } else if (data.option === 'question') {
        this.disableQA = data.enable === 'false'
      }
    })

    this.channel.bind('onKickOut', ({ data }) => {
      console.log('onKickOut', data)
      if (this.pollingLoop) {
        this.pollingLoop.stopPolling()
      }
      this.updateBrowserWatchData(false)
      this.resetSoundTimer()
      reportEntryLog(VideoLog.live_user_kickout_by_gensee, { reason: data.reason, tabId: this.currentTabId })
    })

    this.channel.bind('onStart', () => {
      console.log('onStart')
      this.setLoading(false)
      this.setLiveStatus(LiveStatus.Playing)
    })

    this.channel.bind('onPause', () => {
      console.log('onPause')
      this.liveVideoStatus = 'pause'
      // this.setLoading(false)
    })

    this.channel.bind('onPlay', () => {
      console.log('onPlay')
      this.liveVideoStatus = 'play'
      if (!this.netSettingList.length) {
        this.channel.send('requireNetSettings')
      }

      if (!this.isFirstVideoPlay) {
        this.isFirstVideoPlay = true

        // 视频第一次播放
        const videoElement = document.querySelector('video')
        if (videoElement && !videoElement.muted) {
          console.warn('手动设置成了静音～')
          videoElement.muted = true
          this.resetVolumePlay()
          this.setAuthOpenSound(true)
          this.openSoundTimer = setTimeout(this.resetSoundTimer, 10000)
        }
      }
    })

    this.channel.bind('onStop', () => {
      console.log('onStop')
      this.setLiveStatus(LiveStatus.Ended)
    })

    this.channel.bind('onUserOnline', ({ data }) => {
      console.log('onUserOnline', data)
    })

    this.channel.bind('onNetSettings', ({ data }) => {
      console.log('onNetSettings', data)
      if (Array.isArray(data.list)) {
        this.setNetSettingList(data.list)
      }
    })

    this.channel.bind('onManualAutoplay', ({ data }) => {
      if (global.platform === 'PC') {
        console.warn('onManualAutoplay', data)
        this.resetVolumePlay()
        this.setAuthOpenSound(true)
        this.openSoundTimer = setTimeout(this.resetSoundTimer, 10000)
      }
    })

    this.channel.bind('onMute', ({ data }) => {
      console.log('onMute', data.mute)
      this.setMute(data.mute)
    })
  }

  private getUUID() {
    return uuid()
  }

  public toggleVideoPlay() {
    if (this.channel) {
      if (this.liveVideoStatus === 'pause') {
        this.channel.send('submitPlay')
      } else {
        this.channel.send('submitPause')
      }
    }
  }

  public pauseVideo() {
    this.channel.send('submitPause')
  }

  public playVideo() {
    this.channel.send('submitPlay')
  }

  public async submitChatMessage(msg: string): Promise<string> {
    // 处理msg
    // msg = msg.replaceAll('<', '&lt;').replaceAll('>', '&gt;')
    msg = msg.trim()

    if (msg.length === 0) {
      return Promise.resolve('请先输入内容')
    }

    if (msg.length > 250) {
      return Promise.resolve('文本不能超过250个字符')
    }

    if (this.disableChat) {
      return Promise.resolve('当前禁止聊天，您的信息发送不成功')
    }

    if (!this.canChat) {
      return Promise.resolve('发送过于频繁，请稍后再试')
    }

    this.canChat = false
    setTimeout(() => {
      this.canChat = true
    }, this.minInterval * 1000)

    const that = this
    if (this.channel) {
      return new Promise(resolve => {
        // 判断当前是否是私聊
        if (this.atInfo) {
          this.channel.send(
            'submitChatTo',
            {
              content: msg.replace(this.getAtPrefix(this.atInfo.uname), ''),
              security: 'default',
              receiverId: this.atInfo.senderId,
              receiver: this.atInfo.uname
            },
            ({ data, result }) => {
              if (result === false) return resolve('出错啦，请稍后重试')

              that.appendChatList({
                id: data.id,
                sender: that.widgetParams.uname,
                senderId: that.getUUID(),
                content: data.content,
                richtext: data.richtext,
                utctime: Date.now(),
                senderUid: this.widgetParams.uid,
                privateMessageInfo: {
                  isSelfSendMessage: true,
                  from: {
                    uid: this.widgetParams.uid,
                    uname: this.widgetParams.uname
                  },
                  to: {
                    uid: that.atInfo.uid,
                    uname: that.atInfo.uname
                  }
                }
              })

              // 发送完成了，清除at信息
              that.setAtInfo(null)

              return resolve('')
            }
          )
        } else {
          this.channel.send('submitChat', { content: msg, security: 'default' }, ({ data, result }) => {
            if (result === false) return resolve('出错啦，请稍后重试')

            that.appendChatList({
              id: data.id,
              sender: that.widgetParams.uname,
              senderId: that.getUUID(),
              content: data.content,
              richtext: data.richtext,
              utctime: Date.now(),
              senderUid: this.widgetParams.uid,
              privateMessageInfo: null
            })

            return resolve('')
          })
        }
      })
    } else {
      return Promise.resolve('出错啦，请稍后重试')
    }
  }

  async submitQAMessage(msg: string): Promise<string> {
    // 处理msg
    // msg = msg.replaceAll('<', '&lt;').replaceAll('>', '&gt;')
    msg = msg.trim()

    if (msg.length === 0) {
      return Promise.resolve('发送内容为空，请重新输入')
    }

    if (msg.length > 250) {
      return Promise.resolve('您输入的字数已超出250个字符')
    }

    if (this.disableQA) {
      return Promise.resolve('当前禁止提问，您的信息发送不成功')
    }

    if (!this.canQA) {
      return Promise.resolve('发送过于频繁，请稍后再试')
    }

    this.canQA = false
    setTimeout(() => {
      this.canQA = true
    }, this.minInterval * 1000)

    if (this.channel) {
      return new Promise(resolve => {
        if (this.answerInfo) {
          this.channel.send(
            'submitQuestion',
            {
              content: msg,
              questionId: this.answerInfo.questionId,
              type: 'qaanswer',
              qaContent: this.answerInfo.qaContent,
              ownername: this.answerInfo.answerername
            },
            ({ result }) => {
              if (result === false) return resolve('出错啦，请稍后重试')

              const index = this.qaList.findIndex(item => item.id === this.answerInfo.questionId)
              if (index > -1) {
                const answerItem = {
                  answer: msg,
                  answerBy: this.widgetParams.uname,
                  answerTime: AjaxBasics.serviceDate.utcOffset(+8).format('HH:mm:ss'),
                  // 没有answererId
                  answererId: '',
                  mainId: this.answerInfo.questionId,
                  // 根据数据得来的  如果是我回复老师的问答  那么改值为0
                  mainQAownerId: '0'
                }
                runInAction(() => {
                  this.qaList[index].answerList.push(answerItem)
                })
              }

              return resolve('')
            }
          )
        } else {
          this.channel.send('submitQuestion', { content: msg }, ({ result, id }) => {
            if (result === false) return resolve('出错啦，请稍后重试')

            const qaItem = {
              id,
              question: msg,
              submitor: this.widgetParams.uname,
              submitTime: AjaxBasics.serviceDate.utcOffset(+8).format('HH:mm:ss'),
              submitorId: this.widgetParams.uid,
              answerList: []
            }
            runInAction(() => {
              this.qaList.push(qaItem)
            })

            return resolve('')
          })
        }
      })
    } else {
      return Promise.resolve('出错啦，请稍后重试')
    }
  }

  /**
   * 格式化获取到的QA列表
   * @param list
   * @returns
   */
  parseQAList(list: LiveQAItem[]): QAItemParsed[] {
    const formatData = Object.values(lodash.groupBy(list, 'id')).map(list =>
      list.sort((a, b) => Number(a.answerTime) - Number(b.answerTime))
    )

    const result: Array<QAItemParsed> = []
    formatData.forEach((list, index) => {
      list.forEach(item => {
        const { id, question, submitor, submitTime, qaownerId, answer, answerBy, answerTime } = item
        // 首次提问
        if (!item.answer) {
          result.push({
            id,
            question,
            submitor,
            submitTime: moment(parseInt(`${submitTime}`, 10) * 1000)
              .utcOffset(+8)
              .format('HH:mm:ss'),
            submitorId: qaownerId,
            answerList: []
          })
        } else {
          result[index].answerList.push({
            answer: answer,
            answerBy: answerBy,
            answerTime: moment(parseInt(`${answerTime}`, 10) * 1000)
              .utcOffset(+8)
              .format('HH:mm:ss'),
            // 没有answererId
            answererId: '',
            // 外部id
            mainId: item.id,
            // 回答是哪个人的id
            mainQAownerId: item.qaownerId
          })
        }
      })
    })

    return result
  }

  @action
  appendQAItem(item: LiveQAItem) {
    const index = lodash.findIndex(this.qaList, qa => item.id === qa.id)
    const { id, question, submitor, submitTime, answer, answerBy, answerTime, qaownerId } = item
    if (index > -1) {
      // 之前发送问题的已回显
      if (!answer) return

      // 之前回复老师的问题已回显
      if (qaownerId === '0') return

      // 回复
      if (answer) {
        this.qaList[index].answerList.push({
          answer: answer,
          answerBy: answerBy,
          answerTime: moment(parseInt(`${answerTime}`, 10) * 1000)
            .utcOffset(+8)
            .format('HH:mm:ss'),
          // 没有answererId
          answererId: '',
          mainId: id,
          mainQAownerId: qaownerId
        })
      }
    } else {
      // 提问
      this.qaList.push({
        id,
        question,
        submitor,
        submitTime: moment(parseInt(`${submitTime}`, 10) * 1000)
          .utcOffset(+8)
          .format('HH:mm:ss'),
        submitorId: qaownerId,
        answerList: []
      })
    }
  }

  @action
  removeQAItem(id: string) {
    const index = lodash.findIndex(this.qaList, qa => id === qa.id)
    if (index > -1) {
      this.qaList.splice(index, 1)
    }
  }

  resetSoundTimer() {
    if (this.openSoundTimer) {
      clearTimeout(this.openSoundTimer)
      this.openSoundTimer = null
    }
    this.setAuthOpenSound(false)
  }

  /** 初始直播为静音自动播放，需要手动点击解除静音 */
  recoverVolumePlay() {
    this.channel.send('submitMute', { mute: false })
    // 初始默认声音设置成75
    this.setVolume(75)
    this.channel.send('submitVolume', { value: this.volume / 100 })
  }

  /** 音量重置为之前的静音状态 */
  resetVolumePlay() {
    this.channel.send('submitMute', { mute: true })
    this.setVolume(0)
    this.channel.send('submitVolume', { value: this.volume / 100 })
  }

  /**
   * 通过拖动音量进度条来改变视频播放音量
   * @param volume 0-100
   */
  updateVolumeByDrag(volume: number) {
    this.channel.send('submitVolume', { value: volume / 100 })
    this.setVolume(volume)
  }

  changeNetworkChoice(netName: string) {
    this.channel.send('submitNetChoice', { label: netName })
    this.channel.send('requireNetSettings')
  }

  getAtPrefix(uname: string) {
    return `@${uname}：`
  }

  async pageInit(courseId: string): Promise<boolean> {
    if (!courseId) return false

    this.setLoading(true)
    this.skeletonLoadingStart = Date.now()
    reportEntryLog(VideoLog.live_skeleton_start, { time: this.skeletonLoadingStart, tabId: this.currentTabId })

    // 设置页面监听定时器
    if (this.pageReloadTimer) {
      clearTimeout(this.pageReloadTimer)
    }
    this.pageReloadTimer = setTimeout(() => {
      if (this.isLoading) {
        this.setPageLoadingTooLong(true)
        reportEntryLog(VideoLog.live_loading_too_long, { tabId: this.currentTabId })
      }
    }, 10 * 1000)

    const that = this
    const tabId = socketClient.getTabId()
    const result = await this.$ajax.post(EnumApiUser.VideoInit, {
      courseId,
      tabId
    })

    if (result === true && !this.pollingLoop) {
      this.pollingLoop = new Loop(
        async () => {
          try {
            await this.$ajax.put<true>(EnumApiUser.VideoHeart, { courseId, tabId })
            return Loop.CheckResult.Loop
          } catch (e) {
            if (e === false) {
              that.updateBrowserWatchData(false)
              reportEntryLog(VideoLog.live_user_kickout_by_xt, { type: 0, tabId: this.currentTabId })
              return Loop.CheckResult.Stop
            }

            // {"errCode":1031100069,"errMsg":"账号正在另一台设备登录","e":null}
            if (e && 'response' in e && e.response.errCode === 1031100069) {
              that.updateBrowserWatchData(true)
              reportEntryLog(VideoLog.live_user_kickout_by_xt, { type: 1, tabId: this.currentTabId })
              return Loop.CheckResult.Stop
            }

            return Loop.CheckResult.Retry
          }
        },
        {
          pollingErrorRetryCount: 3,
          pollingInterval: 5 * 60 * 1000,
          checkResult(result) {
            return result
          }
        }
      )
    }

    return true
  }

  @action
  setAtInfo(atItem: AtItem | null) {
    this.atInfo = atItem
  }

  @action
  toggleAtSelect(atItem: AtItem | null) {
    if (atItem && this.atInfo && atItem.uid === this.atInfo.uid) return

    this.atList = this.atList.map(v => {
      v.isSelect = false
      return v
    })

    if (atItem) {
      const item = this.atList.find(item => item.uid === atItem.uid)
      item.isSelect = true
    }
    this.setAtInfo(atItem)
  }

  /**
   * 移除聊天消息
   * @param type 用户｜聊天
   * @param id 用户id｜聊天id
   */
  @action
  removeChatMessgae(type: 'user' | 'chat', id: string) {
    if (type === 'chat') {
      const index = this.chatList.findIndex(item => item.id === id)
      if (index > -1) {
        this.chatList.splice(index, 1)
      }
    } else {
      this.chatList = this.chatList.filter(item => item.senderUid !== id)
    }
  }

  @action
  appendMessageToAtList(msgInfo: PublicChat) {
    const clickSelf = msgInfo.senderUid === this.widgetParams.uid && !msgInfo.privateMessageInfo
    if (clickSelf) return

    this.atList = this.atList.map(v => {
      v.isSelect = false
      return v
    })

    const item = this.atList.find(item => {
      if (!msgInfo.privateMessageInfo) {
        return item.uid === msgInfo.senderUid
      } else {
        return msgInfo.privateMessageInfo.isSelfSendMessage
          ? item.uid === msgInfo.privateMessageInfo.to.uid
          : item.uid === msgInfo.privateMessageInfo.from.uid
      }
    })

    if (item) {
      item.senderId = msgInfo.senderId
      item.isSelect = true
      this.setAtInfo(item)
      return
    }

    const atItem: AtItem = !msgInfo.privateMessageInfo
      ? {
          isSelect: true,
          uname: msgInfo.sender,
          uid: msgInfo.senderUid,
          senderId: msgInfo.senderId,
          chatId: msgInfo.id
        }
      : msgInfo.privateMessageInfo.isSelfSendMessage
        ? {
            isSelect: true,
            uname: msgInfo.privateMessageInfo.to.uname,
            uid: msgInfo.privateMessageInfo.to.uid,
            senderId: msgInfo.senderId,
            chatId: msgInfo.id
          }
        : {
            isSelect: true,
            uname: msgInfo.privateMessageInfo.from.uname,
            uid: msgInfo.privateMessageInfo.from.uid,
            senderId: msgInfo.senderId,
            chatId: msgInfo.id
          }

    this.atList.push(atItem)
    this.setAtInfo(atItem)
  }

  @action
  setAnswerInfo(answerInfo: { questionId?: string; qaContent?: string; answerername?: string } | null) {
    this.answerInfo = answerInfo
  }

  @action
  setNetSettingList(list: Array<{ label: string; selected: 'true' | 'false' }>) {
    this.netSettingList = list.map(item => ({
      netName: item.label,
      label: item.label.indexOf('（') !== -1 ? item.label.split('（')[0] : item.label,
      selected: item.selected === 'true'
    }))
  }

  @action
  setQAList(list: QAItemParsed[]) {
    this.qaList = list
  }

  @action
  appendChatList(msg: PublicChat) {
    this.chatList.push(msg)
  }

  @action
  setWidgetParams(params: WidgetParams) {
    this.widgetParams = params
  }

  @action
  private setLiveStatus(status: LiveStatus) {
    this.liveStatus = status
  }

  @action
  setVolume(value: number) {
    this.volume = value
  }

  @action
  setFullScreen(fullScreen: boolean) {
    this.isFullScreen = fullScreen
  }

  @action
  setAuthOpenSound(show: boolean) {
    this.authOpenSound = show
  }

  @action
  setMute(mute: boolean) {
    this.mute = mute
  }

  @action updateBrowserWatchData(isLogout: boolean) {
    if (isLogout) {
      this.isWatchOnOtherBrowser = true
    } else {
      this.isWatchOnSameBrowser = true
    }
  }

  @action clearBrowserWatchData() {
    this.isWatchOnOtherBrowser = false
    this.isWatchOnSameBrowser = false
  }

  @action
  setLoading(isLoading: boolean) {
    // 正在加载 & 要关闭加载
    if (this.isLoading && !isLoading) {
      const now = Date.now()
      reportEntryLog(VideoLog.live_skeleton_done, { time: now, duration: Date.now() - this.skeletonLoadingStart, tabId: this.currentTabId })

      // 已经加载出来了页面，取消刷新定时器
      if (this.pageReloadTimer) {
        clearTimeout(this.pageReloadTimer)
        this.pageReloadTimer = null
      }
    }

    this.isLoading = isLoading
  }

  @action
  setPageLoadingTooLong(isLoadingTooLong: boolean) {
    this.isPageLoadingTooLong = isLoadingTooLong
  }
}
