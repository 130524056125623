




































import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { Pagination, PaginationInfiniteEvent } from '@xt/client'
import lodash from 'lodash'
@Observer
@Component({
  components: {}
})
export default class Page extends Vue {
  @Prop({}) body: any
  @Prop({ default: 'id' }) rowKey: string
  @Prop({ default: true }) split: boolean
  // 瀑布流
  @Prop({ default: true }) infinite: boolean
  @Prop({ default: false }) useIndex: boolean
  @Prop({ required: true }) Pagination: Pagination<any>
  @Prop({ default: true }) forceUseInfiniteWrapper
  @Prop({ default: true }) initShow: boolean
  @Prop({ default: true }) initLoading: boolean

  inspectName = this.$route.name

  getRowKey(rowDate, index) {
    return lodash.get(rowDate, this.rowKey || this.Pagination.key, index)
  }

  async onLoading(event: PaginationInfiniteEvent) {
    await this.Pagination.onLoading(this.body, null, event)
    this.onComplete()
  }
  /**
   *  初始化 和 页码 更改调用
   */
  async onCurrentChange(current, reset = false) {
    await this.Pagination.onCurrentChange(current, this.body)
    this.onComplete()
  }
  // 加载完成后的 事件
  @Emit('complete')
  onComplete() {
    return this
  }

  created() {
    this.Pagination.onReset({ infinite: this.infinite })
  }

  // 参数变化
  @Watch('body')
  bodyUpdate(to, from, next) {
    if (this.inspectName === this.$route.name) {
      if (!lodash.isEqual(this.body, this.Pagination.oldBody)) {
        this.Pagination.onReset({ infinite: this.infinite })
      }
    }
  }
  mounted() {}
  updated() {}
}
