import { reportEntryLog } from '@xt/client/utils/log'
import { throttle } from 'lodash'
import $global from '@xt/client/store/global'
import moment from 'moment'

class ServerTimeDebugLog {
  private lastRequestDone: number
  private id: number = 0
  private tabId: string = ''
  private timer: null | ReturnType<typeof setTimeout> = null

  constructor() {
    this.tabId = sessionStorage.getItem('tab-id')
    this.sync = this.sync.bind(this)
    this.log = this.log.bind(this)
    this.handleVisible = throttle(this.handleVisible.bind(this), 3 * 1000, { leading: true, trailing: false })

    // 初始立即上报
    this.log()

    document.addEventListener('visibilitychange', this.handleVisible)
    window.addEventListener('beforeunload', () => {
      if (this.timer) {
        clearTimeout(this.timer)
      }

      document.removeEventListener('visibilitychange', this.handleVisible)
    })
  }

  async sync() {
    try {
      const beforeRequestDone = this.lastRequestDone

      const requestStart = performance.now()
      const response = await window.fetch($global.target + '/date', { method: 'GET' })
      const requestDone = performance.now()
      this.lastRequestDone = requestDone

      const rtt = requestDone - requestStart
      const dateHeader = response.headers.get('Date')

      return {
        reqStart: requestStart,
        reqDone: requestDone,
        beforeReqDone: beforeRequestDone,
        rtt,
        dateHeader,
        date: moment(dateHeader).valueOf(),
        target: $global.target,
        client: Date.now()
      }
    } catch (e) {
      return {
        supportFetch: typeof window.fetch !== 'undefined',
        supportPerf: typeof window.performance !== 'undefined',
        target: $global.target,
        e
      }
    }
  }

  handleVisible() {
    if (document.visibilityState === 'visible') {
      this.log()
    }
  }

  log() {
    this.sync().then(data => {
      reportEntryLog('server-time.sync-v2.debug', { ...data, id: this.id++, tabId: this.tabId })

      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        if (typeof window.performance !== 'undefined') {
          reportEntryLog('server-time.sync-v2.debug-5s', { now: performance.now(), client: Date.now(), id: this.id++, tabId: this.tabId })
        } else {
          reportEntryLog('server-time.sync-v2-err.debug-5s', { id: this.id++, client: Date.now(), tabId: this.tabId })
        }
      }, 5000)
    })
  }
}

const serverTimeLog = new ServerTimeDebugLog()
export default serverTimeLog
