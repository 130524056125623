
























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { BaseCouponItem, CouponInfoListItem } from '@xt/client/types/coupon'
import SlidePop from '../coupon/SlidePop.vue'
import GiftCoupon from './GiftCoupon.vue'
import CouponUseInfo from '@xt/www/components/coupon/CouponUseInfo.vue'

@Observer
@Component({
  components: {
    SlidePop,
    GiftCoupon,
    CouponUseInfo
  }
})
export default class OrderCouponGift extends Vue {
  @Prop({ required: true }) data: { failList: Array<BaseCouponItem>; successList: Array<BaseCouponItem> }
  show = false
  slide = 0

  secondViewInfo: {
    list: CouponInfoListItem[]
    coupon: BaseCouponItem | null
  } = {
    list: [],
    coupon: null
  }

  handleClickUseInfo({ list, coupon }: { list: Array<CouponInfoListItem>; coupon: BaseCouponItem }) {
    this.secondViewInfo = {
      list,
      coupon
    }
    this.slide = 1
  }

  async readMessage() {
    await this.$store.$coupon.readOrderSuccessCouponGift(this.data.successList.map(data => data.couponCode))
  }

  async handleClose() {
    await this.readMessage()

    this.show = false
    this.$emit('close')
  }

  async handleConfirm() {
    await this.handleClose()

    if (!this.$route.path.endsWith('/my/packs')) {
      this.$router.replace('/my/packs')
    }
  }

  mounted() {
    const isShow = this.data && (this.data.failList.length !== 0 || this.data.successList.length !== 0)

    if (isShow) {
      this.show = true
    }
  }
}
