import CryptoJS from 'crypto-js'
import $global from '@xt/client/store/global'

export function loadScript(src: string): Promise<boolean> {
  const now = Date.now()
  return new Promise(resolve => {
    const script = document.createElement('script')
    script.src = src + `?t=${now}`

    script.onload = () => {
      resolve(true)
    }

    script.onerror = () => {
      resolve(false)
    }

    document.body.appendChild(script)
  })
}

export async function chainLoadScript(srcList: string[]) {
  for (const src of srcList) {
    const isSuccess = await loadScript(src)
    if (!isSuccess) return false
  }

  return true
}

export type GenseeLinkSearch = {
  courseId: string
  isGray: boolean
  k: string
  nickname: string
  sdk: string
  uid: string
  wxx?: boolean
  gensee: string
  live: boolean
}

const parse = (value: string) => {
  if (['true', 'false'].includes(value)) {
    return value === 'true'
  }

  return value
}

export function parseGenseeLink<T extends Record<string, string | boolean>>(p: string, type: 'live' | 'playback'): T | null {
  if (!p) return null

  try {
    const result: Record<string, string | boolean> = {}
    const url = new URL(p)

    url.searchParams.forEach((value, key) => {
      if (key === 'sdk') {
        const word = CryptoJS.enc.Base64.parse(value)
        result[key] = word.toString(CryptoJS.enc.Utf8)
      } else {
        result[key] = parse(value)
      }
    })

    const genseeLink = new URL(url.pathname, url.origin)
    genseeLink.searchParams.set('k', result['k'] as string)
    genseeLink.searchParams.set('uid', result['uid'] as string)
    genseeLink.searchParams.set('nickname', result['nickname'] as string)
    if (result['wxx']) {
      genseeLink.searchParams.set('wxx', `${result['wxx']}`)
    }
    result['gensee'] = genseeLink.toString()

    return result as T
  } catch (e) {
    return null
  }
}

export class PlaybackWatchDataSync {
  static StorageKey = `${$global.localStorageStartsWith}playback_watch`

  private getParsedStorage(): Array<{ classhour: number | string; time: number }> {
    const _value = localStorage.getItem(PlaybackWatchDataSync.StorageKey)
    if (!_value) {
      return []
    }
    try {
      return JSON.parse(_value)
    } catch {
      return []
    }
  }

  private setStorage(item: { classhour: number | string; time: number }) {
    const origin = this.getParsedStorage()

    if (origin.length === 0) {
      localStorage.setItem(PlaybackWatchDataSync.StorageKey, JSON.stringify([item]))
    } else {
      const data = origin.find(_item => _item.classhour == item.classhour)
      if (!data) {
        localStorage.setItem(PlaybackWatchDataSync.StorageKey, JSON.stringify(origin.concat(item)))
      } else {
        localStorage.setItem(
          PlaybackWatchDataSync.StorageKey,
          JSON.stringify(
            origin.map(_item => {
              if (_item.classhour === item.classhour) {
                _item.time = item.time
              }

              return _item
            })
          )
        )
      }
    }

    console.warn('同步观看数据成功了', item)
  }

  // 读取观看数据
  getWatchTime(classhour: number | string) {
    const data = this.getParsedStorage()
    const item = data.find(item => item.classhour == classhour)

    return item?.time ?? 0
  }

  // 保存观看数据
  saveWatchData(classhour: number | string, time: number) {
    this.setStorage({ classhour, time })
  }
}

export interface FormattedRes {
  days: number
  hours: number
  minutes: number
  seconds: number
  milliseconds: number
}

export const parseMs = (milliseconds: number): FormattedRes => {
  return {
    days: Math.floor(milliseconds / 86400000),
    hours: Math.floor(milliseconds / 3600000) % 24,
    minutes: Math.floor(milliseconds / 60000) % 60,
    seconds: Math.floor(milliseconds / 1000) % 60,
    milliseconds: Math.floor(milliseconds) % 1000
  }
}
