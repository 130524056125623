













































import { Prop, Vue, Component, Emit, Watch } from 'vue-property-decorator'
import { CouponAssocProductItem } from '@xt/client/types/product'
import { EnumContentType, EnumContentTypeText, EnumProductType } from '@xt/client/enums'

@Component
export default class ExchangeCoursePopup extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: '确认兑换信息' }) title: string
  @Prop({ default: '' }) couponActivityId: number

  productList: CouponAssocProductItem[] = []
  activeKey = 0
  disabledBtn = false
  isExchanging = false

  get PageStore() {
    return this.$store.$coupon.myPacks
  }

  getCourseContentType(contentType: EnumContentType) {
    switch (contentType) {
      case EnumContentType.Painting:
        return EnumContentTypeText.Painting
      case EnumContentType.Calligraphy:
        return EnumContentTypeText.Calligraphy
      case EnumContentType.CalligraphyAndReading:
        return EnumContentTypeText.CalligraphyAndReading
      case EnumContentType.Health:
        return EnumContentTypeText.Health
      case EnumContentType.Physical:
        return EnumContentTypeText.Physical
      case EnumContentType.Reading:
        return EnumContentTypeText.Reading
      case EnumContentType.Writing:
        return EnumContentTypeText.Writing
      case EnumContentType.AestheticEducation:
        return EnumContentTypeText.AestheticEducation
      default:
        return EnumContentTypeText.Calligraphy
    }
  }

  getBizProdCount(item: CouponAssocProductItem) {
    switch (item.productType) {
      case EnumProductType.single:
      case EnumProductType.fullstage:
        return `共${item.bizProdCount}课`
      case EnumProductType.dayCourse:
      case EnumProductType.selfStudy:
        return `共${item.bizProdCount}天`
      case EnumProductType.offlineExhibition:
      case EnumProductType.onlineExhibition:
        return `共${item.bizProdCount + 2}关`
    }
  }

  onSelectCourse(item: CouponAssocProductItem) {
    if (!item.canExchange || this.activeKey === item.id) return
    this.activeKey = item.id
  }

  @Emit('close')
  onCancel() {}

  onConfirmExchange() {
    if (this.disabledBtn || this.isExchanging) return

    if (!this.activeKey) {
      this.$msg('请选择要兑换的课程')
      return
    }

    this.isExchanging = true

    this.$emit('confirmExchange', this.activeKey, () => {
      this.isExchanging = false
    })
  }

  @Watch('visible', { immediate: true })
  async changeVisible(value: boolean) {
    if (!value) return
    this.productList = await this.PageStore.onGetCouponAssocProduct(this.couponActivityId)
    if (this.productList.length === 1) {
      if (this.productList[0].canExchange) {
        this.activeKey = this.productList[0].id
      } else {
        this.disabledBtn = true
      }
    }
  }
}
