





































































































































import { Component, Vue, Ref, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import { Alert } from 'ant-design-vue'
import 'ant-design-vue/lib/alert/style/index'
import moment from 'moment'
import ExperiencePopup from '../../components/experience/index.vue'
import Teleport from '@xt/client/mixins/components/teleport.vue'
import HeaderImage from '../../components/headerImage/index.vue'
import LazyImg from '@/components/lazyImg/index.vue'
import Logout from './logout/index.vue'
import { EnumLogoutType } from './logout/enum/index'

export interface ICheckUserInfoResp {
  bishanPointCount?: number
  coin?: number
  purchaseProductCount?: number
  uncompleteProductCount?: number
}

@Observer
@Component({
  components: { AAlert: Alert, ExperiencePopup, Teleport, HeaderImage, LazyImg, Logout }
})
export default class PageView extends Vue {
  //展示积分过期提醒
  tips: any = false
  bishanMessage = ''
  allcourse: boolean = false
  tabPane = [
    { key: 'course', path: 'my', icon: '', img: 'https://static.xuantong.cn/images/userCenter/pc/pc_mine_attend_class_im.png' },
    { key: 'homework', path: 'my-homework', icon: 'iconpc_mine_task_ic' },
    { key: 'showHomework', path: 'my-showHomework', icon: 'iconpc_mine_publish_job_ic' },
    { key: 'order', path: 'my-order', icon: '', img: 'https://static.xuantong.cn/images/userCenter/pc/pc_mine_order_im.png' },
    { key: 'packs', path: 'my-packs', icon: 'iconpack_ic' },
    { key: 'exchange', path: 'my-exchange', icon: 'iconpoint_exchange_hollow_ic' },
    { key: 'information', path: 'my-information', icon: 'iconpc_mine_personal_ic' },
    { key: 'security', path: 'my-security', icon: 'iconpc_mine_securityl_ic' },
    { key: 'allCourse', path: 'my-allcourse', parent: 'course', hide: true },
    { key: 'calendar', path: 'my-calendar', parent: 'course', hide: true },
    { key: 'bishanRecord', path: 'my-exchange-record-bishan', hide: true },
    { key: 'coinRecord', path: 'my-exchange-record-coin', hide: true },
    { key: 'exchangeRecord', path: 'my-exchange-record', parent: 'exchange', hide: true },
    { key: 'exchangeDetail', path: 'my-exchange-detail', parent: 'exchange', hide: true }
  ]
  offsetTop: number = 0 // 右边底部贴近左边底部时，之后全部向上偏移的距离
  checkUserInfo: ICheckUserInfoResp = {}
  loading: boolean = false

  @Ref('left-container')
  leftContainer: HTMLDivElement
  @Ref('right-container')
  rightContainer: HTMLDivElement

  get PageStore() {
    return this.$store.$storeUser
  }
  get defaultActiveKey() {
    let nowRouterName = this.$route.name.replace(/-(copy|index)/g, '')
    if (['my-exchange-detail', 'my-exchange-record'].includes(nowRouterName)) {
      nowRouterName = 'my-exchange'
    }
    return lodash.get(
      lodash.find(this.tabPane, item => item.path === nowRouterName),
      'key',
      'course'
    )
  }
  get activeKey() {
    return this.$route.name.replace(/-(copy|index)/g, '')
  }
  get activeItem(): any {
    return lodash.find(this.tabPane, item => item.path === this.activeKey) || {}
  }
  get experienceBackground() {
    switch (this.PageStore.level) {
      case 0:
        return '#E7E7DD'
      case 1:
        return '#EBE0D0'
      case 2:
        return '#EBD9BE'
      case 3:
        return '#E7D4BD'
      case 4:
        return '#E7D2C4'
      case 5:
        return '#E7CEC0'
      case 6:
        return '#E7CCBD'
      case 7:
        return '#E6C8C5'
      case 8:
        return '#E7C7C4'
      case 9:
        return '#E7C4C3'
      case 10:
        return ''
    }
  }

  experienceBtn() {
    this.$router.push({ name: 'experience' })
  }
  //隐藏tips显示
  hideTips() {
    this.tips.isShow = false
    this.$store.$my.BishanWork.read()
  }
  tabsChange(activeKey) {
    let path = lodash.get(
      lodash.find(this.tabPane, item => item.key == activeKey),
      'path',
      'my'
    )
    this.$router.push({ name: path })
    document.documentElement.scrollTo({ top: 0 })
  }
  closeAlert() {
    this.hideTips()
  }
  copyUserId() {
    const disappear = 'position: fixed;z-index: -2;top: -100px'
    let input = document.createElement('input')
    input.value = this.PageStore.UserInfo.userName
    input.setAttribute('style', disappear)
    document.body.appendChild(input)
    input.focus()
    input.select()
    if (document.execCommand('copy', false, null)) {
      //success info
      this.$msg('复制成功', 'success')
    } else {
      this.$msg('请手动复制ID号')
    }
    document.body.removeChild(input)
  }
  onGoPointDetail(type: 'bishan' | 'coin') {
    this.$router.push({
      name: `my-exchange-record-${type}`
    })
  }
  // 显示注销弹窗
  async showLogout() {
    try {
      this.loading = true
      this.checkUserInfo = (await this.PageStore.getUserCancelAccountCheckInfo()) as ICheckUserInfoResp
      setTimeout(() => {
        this.loading = false
        this.PageStore.onToggleLogoutVisible(true)
        this.PageStore.onToggleLogoutModal((this.checkUserInfo.purchaseProductCount && EnumLogoutType.ACCOUNT) || EnumLogoutType.NOTICE)
      }, 500)
    } catch (error) {
      setTimeout(() => {
        this.loading = false
      }, 500)
      console.log(error)
    }
  }
  // 隐藏注销弹窗
  hideLogout() {
    this.PageStore.onToggleLogoutVisible(false)
  }

  toPageByName(key) {
    for (const item of this.tabPane) {
      if (item.key === key) return this.$router.push({ name: item.path })
    }
  }

  onScroll() {
    const topHeight = 96
    const leftHeight = this.leftContainer.offsetHeight,
      rightHeight = this.rightContainer.offsetHeight
    const safeDistance = 30
    if (leftHeight + window.scrollY >= rightHeight - safeDistance) {
      this.offsetTop = Math.max(leftHeight + window.scrollY - rightHeight + safeDistance, 0)
      this.leftContainer.style.top = this.$px2rem(topHeight - this.offsetTop)
    } else {
      this.offsetTop = 0
      this.leftContainer.style.top = this.$px2rem(topHeight)
    }
  }

  @Watch('$route')
  RouteUpdate() {
    this.allcourse = this.$route.name === 'my-allcourse'
  }

  async created() {
    this.allcourse = this.$route.name === 'my-allcourse'
    await this.PageStore.onGetBishans()
    let tips = await this.$store.$my.BishanWork.onRemind()
    this.tips = tips
    let outeDateTime = moment(this.tips['expireTime']).format('YYYY年MM月DD日')
    this.bishanMessage = `您有${this.tips.bishanNum ? this.tips.bishanNum : 0}个笔山即将在${outeDateTime}过期失效，记得尽快兑换哦～`
  }

  mounted() {
    this.$nextTick(() => {
      this.onScroll()
      window.addEventListener('scroll', this.onScroll)
    })

    const layoutContainer = document.querySelector('.xt-layout-content') as HTMLDivElement
    layoutContainer.style.backgroundColor = '#F6F6F6'
  }
  updated() {}
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)

    const layoutContainer = document.querySelector('.xt-layout-content') as HTMLDivElement
    layoutContainer.style.backgroundColor = '#FFFFFF'
  }
  destroyed() {}
}
