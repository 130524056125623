














































































import { Component, Prop, Vue, Provide, Inject, Watch } from 'vue-property-decorator'
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import Fab from './views/fab.vue'
import lodash from 'lodash'
import { Observer } from 'mobx-vue'
import GiftModal from './views/giftModal.vue'
import Header from './views/header.vue'
import MSAlert from './views/msAlert.vue'
import LayoutFooter from './views/footer.vue'
import Breadcrumb from './views/breadcrumb.vue'
import WX from '@xt/client/mixins/login/weixin'
import WXBindPopup from '@xt/www/components/popup/bindWX/index.vue'
import WXVerifyPopup from '@xt/www/components/popup/verifyWX/index.vue'
import AccountAlert from '@xt/www/components/accountAlert/index.vue'
import StudentWelfare from '@xt/www/components/popup/welfare/index.vue'
import CouponRefundNoticeModal from './views/couponRefundNoticeModal.vue'
import { AjaxBasics, giftCache } from '@xt/client/helpers'
import OrderCouponGift from '@xt/www/components/orderCouponGift/index.vue'
// import track from '@xt/client/utils/tracker'
// import { Loop } from '@xt/client/utils/loop'
import sharedWork from '@xt/client/utils/shared/index'
import { Messages } from '@xt/client/utils/shared/types'
import moment from 'moment'
import { reportEntryLog } from '@xt/client/utils/log'
// import xtChanner_asdew_asd23 from "@xt/client/entities/user/channel"
@Observer
@Component({
  components: {
    Fab,
    MSAlert,
    GiftModal,
    Header,
    Breadcrumb,
    LayoutFooter,
    WXBindPopup,
    WXVerifyPopup,
    AccountAlert,
    StudentWelfare,
    CouponRefundNoticeModal,
    OrderCouponGift
  }
})
export default class extends Vue {
  get config() {
    return {
      autoInsertSpaceInButton: false,
      locale: { zh: zh_CN, en: null }[this.$store.$locale.locale]
    }
  }
  get pageClass() {
    return 'xt-page-' + this.$route.name
  }
  get production() {
    return this.$store.$global.production
  }
  get keepAlive() {
    return !this.$store.$global.dev
  }
  get version() {
    return 'Version: ' + this.$store.$global.version + ', Build Time: ' + this.$store.$global.timestamp
  }
  get locale() {
    return this.$EnumLocaleLinks
  }
  get PageStore() {
    return this.$store.$storeUser
  }

  showPopup(key) {
    const value = giftCache.get(key)
    if (value) {
      this.PageStore.removeUniformGift()
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    }
    return !!value
  }

  showGiftPopup(item) {
    const value = giftCache.get(`gift-${item.orderNo}`)
    if (value) {
      this.PageStore.removeOrderGifts(item.id)
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    }
    return !!value
  }

  showRefundNoticePopup(item) {
    const value = giftCache.get(`coupon-refund-notice-${item.orderNo}`)
    if (value) {
      this.PageStore.removeCouponRefundNotice(item.couponId)
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    }
    return !!value
  }

  closeWXBindHandler() {
    this.PageStore.hideWeiXinBind()
  }

  closeWXVerifyHandler() {
    this.PageStore.hideWeiXinVerify()
  }

  async onOutLogin() {
    await this.PageStore.onOutLogin()
    window.location.replace(this.$router.resolve('/').href)
  }

  goTenYear() {
    window.open('https://mp.weixin.qq.com/s/PuFhSFFrhCfOQXsffZaJ-w')
  }

  hasGifts(list) {
    return !lodash.isEmpty(list)
  }

  keepAliveProps = {
    include: ['PageIndex', 'PageVideo', 'PageStationery', 'PageCourse', 'PageAbout']
  }
  mousewheel(e, b) {
    e.stopPropagation()
  }
  stopPropagation(e) {
    e.stopPropagation()
  }
  wxAuth() {
    !this.$store.$global.isMobilePage && WX.wxAuth()
  }
  @Watch('$route')
  RouteUpdate(to, from, next) {
    let fromMy = from.fullPath.startsWith('/my')
    let toMy = to.fullPath.startsWith('/my')
    let toMyPoints = to.fullPath.startsWith('/my/points')
    let toMyInfo = to.fullPath.startsWith('/my/information')
    let loggedIn = this.$store.$storeUser.loggedIn
    if (((!fromMy && toMy) || toMyPoints) && loggedIn && !toMyInfo) this.$store.$storeUser.onUpdatingUserInfo()
    this.wxAuth()
  }
  // async handleSyncDate() {
  //   try {
  //     await this.$ajax.post('/date')
  //     return true
  //   } catch {
  //     return false
  //   }
  // }

  handleSharedWorkerMessage(message: Messages) {
    switch (message.type) {
      case 'SyncServerDate':
        const clientTime = Date.now()
        const serverTime = moment(message.payload.date).valueOf()
        AjaxBasics.clientServerDateDiff = serverTime + message.payload.delay / 2 - clientTime
        // 如果diff大于等于5分钟则主动上传一个点 并且页面在前台才发日志
        if (Math.abs(AjaxBasics.clientServerDateDiff) >= 5 * 60 * 1000 && document.visibilityState === 'visible') {
          try {
            reportEntryLog('app.server-date.diff', {
              diff: AjaxBasics.clientServerDateDiff,
              // 网络请求时延
              delay: message.payload.delay,
              clientTime: moment(clientTime).utcOffset(+8).format('YYYY-MM-DD HH:mm:ss.SSS'),
              serverTime: moment(serverTime).utcOffset(+8).format('YYYY-MM-DD HH:mm:ss.SSS'),
              // 用户时区
              tz: new Date().getTimezoneOffset(),
              // 谁执行的任务
              executer: message.payload.executer,
              // 是否在前台
              state: document.visibilityState,
              // 方式几
              type: message.payload.type,
              // 谁上报的日志
              report: sessionStorage.getItem('tab-id'),
              // 原始请求Date字符串
              date: message.payload.date,
              // 当前精度时间
              perf: performance.now(),
              // 导航开始时间
              navi: performance.timing.navigationStart
            })
          } catch (e) {
            reportEntryLog('app.server-date.diff-error', { e })
          }
        }
        console.warn(
          `同步服务端时间：\ndiff = ${AjaxBasics.clientServerDateDiff}\n服务端时间 = ${AjaxBasics.serviceDate
            .utcOffset(+8)
            .format('YYYY-MM-DD HH:mm:ss.SSS')} ${AjaxBasics.serviceDate.valueOf()}\n客户端时间 = ${moment().format(
            'YYYY-MM-DD HH:mm:ss.SSS'
          )} ${moment().valueOf()}\nmessage = ${JSON.stringify(message.payload, undefined, 2)}`
        )
        break
    }
  }

  mounted() {
    // track.record(this.$ajax)
    // new Loop(this.handleSyncDate, {
    //   pollingInterval: 60 * 1000,
    //   pollingErrorRetryCount: 2,
    //   checkResult(result) {
    //     return result === true ? Loop.CheckResult.Loop : Loop.CheckResult.Retry
    //   }
    // })
    sharedWork.addMessageListener(this.handleSharedWorkerMessage)

    this.wxAuth()
    try {
      const xtChanner_asdew_asd23 = new BroadcastChannel('xtChanner_asdew_asd23')
      xtChanner_asdew_asd23.onmessage = async ev => {
        await this.$store.$storeUser.onOutLogin()
        if (this.$route.fullPath === '/') return (window.location.href = window.location.href)
        this.$router.replace('/')
      }
    } catch (e) {
      console.log(e)
    }
  }
  resetUniformGiftHandler() {
    this.PageStore.onResetUniformGift()
  }
  orderGiftHandler() {
    this.PageStore.onResetGiftPopup()
  }
  created() {
    window.localStorage.setItem('history-state-key', window.history.state.key)
    window.addEventListener('storage', async e => {
      if (e.key === 'message' && e.newValue.indexOf('logout') != -1) {
        await this.onOutLogin()
      }
    })
  }
  isComplete(title) {
    return lodash.eq(this.locale.links_complete, title)
  }
  get hideAll() {
    return this.$route.name === 'groupbuy-groupNo'
  }
}
