




































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import ExchangeCarouselComp from './views/carousel.vue'
import ExchangeTabsComp from './views/tabs.vue'
import ExchangeProductComp from './views/product.vue'
import EmptyComp from '@xt/www/components/system/empty.vue'

@Observer
@Component({
  scrollToTop: true,
  components: { ExchangeCarouselComp, ExchangeTabsComp, ExchangeProductComp, EmptyComp }
})
export default class PageView extends Vue {
  @Prop() offsetTop: number
  isSticky = false

  @Ref('exchange-header-box')
  exchangeHeaderBox: HTMLDivElement

  get PageStore() {
    return this.$store.$storeUser
  }

  get Pagination() {
    return this.$store.$storeTicket.ticketPagination
  }

  get body() {
    return {
      productType: this.productType
    }
  }

  tabActiveKey = 0
  productType = '0'

  onTabChange(active: number) {
    if (this.tabActiveKey === active) return

    this.Pagination.onReset()
    this.tabActiveKey = active
    this.productType = `${active}00`
  }

  goExchangeRecord() {
    this.$router.push({
      name: 'my-exchange-record'
    })
  }

  onScroll() {
    if (this.offsetTop) return
    if (window.scrollY >= 240 + 40) {
      this.isSticky = true
    } else {
      this.isSticky = false
    }
  }

  @Watch('offsetTop')
  changeOffsetTop(value: number) {
    if (value === 0) {
      this.exchangeHeaderBox.style.removeProperty('top')
      return
    }
    if (this.isSticky) {
      this.exchangeHeaderBox.style.top = this.$px2rem(166 - value)
    }
  }

  created() {
    this.$nextTick(() => {
      document.addEventListener('scroll', this.onScroll)
    })
  }
}
