import Decimal from 'decimal.js-light'
import { EnumPreferentialType, IS_COMPOSE_COUPON } from '@xt/client/enums'
import { BaseCouponItem } from '@xt/client/types/coupon'

type CouponDiscountInfo = {
  // 当前券是否可用
  canUse: boolean
  // 使用这张券真实的优惠金额 单位分
  realDiscount: number
  // 使用这张券的优惠金额 单位分
  discount: number
  // 优惠后的金额 单位分
  restMoney: number
}

export class CouponPriceHelper {
  // 计算满减券优惠价格
  private getFullReductionCouponDiscount(initPrice: number, coupon: BaseCouponItem): CouponDiscountInfo {
    const discount = Math.min(coupon.limitAmount, coupon.denominations)

    return {
      canUse: initPrice >= coupon.condition,
      realDiscount: discount,
      discount: this.checkAndReturnDiscount(initPrice, discount),
      restMoney: Math.max(0, new Decimal(initPrice).sub(discount).toNumber())
    }
  }

  // 计算折扣券的优惠价格
  private getDiscountCouponDiscount(initPrice: number, coupon: BaseCouponItem): CouponDiscountInfo {
    const discount = Math.min(coupon.limitAmount, new Decimal(initPrice).times(new Decimal(100).sub(coupon.discount)).div(100).toNumber())

    return {
      canUse: initPrice >= coupon.condition,
      realDiscount: discount,
      discount: this.checkAndReturnDiscount(initPrice, discount),
      restMoney: Math.max(0, new Decimal(initPrice).sub(discount).toNumber())
    }
  }

  // 计算兑换券的优惠价格
  private getExchangeCouponDiscount(initPrice: number, coupon: BaseCouponItem): CouponDiscountInfo {
    // 相信前置已经校验过券能否使用了，这里直接默认可以使用该兑换券
    return {
      canUse: true,
      realDiscount: initPrice,
      discount: initPrice,
      restMoney: 0
    }
  }

  // 给定一个初始金额和一张优惠券 计算 CouponDiscountInfo
  public getCouponDiscountInfo(initPrice: number, coupon: BaseCouponItem) {
    switch (coupon.preferentialType) {
      case EnumPreferentialType.FullReduction:
        return this.getFullReductionCouponDiscount(initPrice, coupon)
      case EnumPreferentialType.Discount:
        return this.getDiscountCouponDiscount(initPrice, coupon)
      case EnumPreferentialType.Exchange:
        return this.getExchangeCouponDiscount(initPrice, coupon)
      default:
        throw new Error('')
    }
  }

  // 给定一个初始金额和一组优惠券 计算最终的 CouponDiscountInfo
  public getCouponComposeDiscountInfo(initPrice: number, couponList: BaseCouponItem[]): CouponDiscountInfo {
    if (couponList.length === 0) {
      return {
        canUse: false,
        discount: 0,
        realDiscount: 0,
        restMoney: initPrice
      }
    }

    if (couponList.length === 1) {
      return this.getCouponDiscountInfo(initPrice, couponList[0])
    }

    // 可叠加券
    const c1: BaseCouponItem = this.isComposeUseCoupon(couponList[0]) ? couponList[0] : couponList[1]
    // 不可叠加券
    const c2: BaseCouponItem = !this.isComposeUseCoupon(couponList[0]) ? couponList[0] : couponList[1]

    return [c1, c2].reduce(
      (result, coupon) => {
        const { canUse, restMoney, discount, realDiscount } = this.getCouponDiscountInfo(result.restMoney, coupon)

        return {
          canUse: canUse && result.canUse,
          discount: discount + result.discount,
          realDiscount: realDiscount + result.realDiscount,
          restMoney
        }
      },
      { canUse: true, discount: 0, restMoney: initPrice, realDiscount: 0 }
    )
  }

  // 最后检查金额并返回 最终金额不能被抵0元 -> 0.1元
  private checkAndReturnDiscount(initPrice: number, discountPrice: number): number {
    const restMoney = new Decimal(initPrice).sub(discountPrice).toNumber()

    if (restMoney > 0) {
      return discountPrice
    }

    return new Decimal(initPrice).sub(1 /** 1分，单位分 */).toNumber()
  }

  /**
   * 检查c1和c2两张券可不可以同时使用
   * @param c1
   * @param c2
   */
  public getLegitimateCouponId(c1: BaseCouponItem, c2?: BaseCouponItem) {}

  // 检查c1,c2两张券是否券可叠加标志位相同
  public isCouponComposeFlagSame(c1: BaseCouponItem, c2: BaseCouponItem) {
    return !((c1.composeFlag & IS_COMPOSE_COUPON) ^ (c2.composeFlag & IS_COMPOSE_COUPON))
  }

  // 是否是可叠加券
  public isComposeUseCoupon(c1: BaseCouponItem) {
    return !!(c1.composeFlag & IS_COMPOSE_COUPON)
  }
}

export default CouponPriceHelper
