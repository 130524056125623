






























import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
@Component
export default class ExchangeSuccessPopup extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: '' }) title: boolean
  // 是否是文房券
  @Prop({ default: false }) isWenfangCoupon: boolean

  @Emit('close')
  onCancel() {}
}
