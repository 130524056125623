/*
 * @Author: Erlin
 * @CreateTime: 2020-08-28 17:37:48
 * @LastEditors: Erlin
 * @LastEditTime: 2020-09-06 11:15:06
 * @Description: 我的
 */
import { BindAll } from 'lodash-decorators'
import { EnumApiTicket } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { MyTicketWork } from './myTicketWork'
import { Pagination } from '../basics/pagination'
import { ExchangeProductItem } from './product'

@BindAll()
export class ControllerTicket {
  constructor(protected $ajax: AjaxBasics) {}

  MyTicketWork = new MyTicketWork(this.$ajax)

  async getTickets() {
    return await this.$ajax.get(EnumApiTicket.TICKET_LIST + '?pageSize=10')
  }

  ticketPagination = new Pagination<ExchangeProductItem[]>(this.$ajax, {
    url: EnumApiTicket.TICKET_LIST,
    method: 'get',
    defaultPageSize: 10
  })

  async getTicketDetail(id) {
    return await this.$ajax.get<ExchangeProductItem>(EnumApiTicket.TICKET_DETAIL, { id: id })
  }
  async checkYouzan(phone, productId) {
    return await this.$ajax.get(EnumApiTicket.CHECK_YOUZAN, {
      memberPhoneNum: phone,
      productId: productId
    })
  }
  async sendMsgByPhone<T>(phone) {
    return await this.$ajax.post<T>(EnumApiTicket.SEND_MSG_BY_PHONE + '?device=' + phone)
  }
  /**
   * 校验验证码
   * @param body
   */
  async checkPhoneCode(body: {
    device?: string //设备
    verifyCode?: string //验证码
    verifyCodeType?: number
    // 验证码类型
    // 1 手机验证码登录，2 邮箱验证码登录，3 绑定手机，4 绑定邮箱，5 更换手机，6 更换邮箱，7 手机找回密码，8 邮箱找回密码
  }) {
    let res = await this.$ajax.put(EnumApiTicket.CHECK_CODE, body)
    return res
  }

  async exchangeTicket(memberPhoneNum, productId, yzUserId, avatar) {
    return await this.$ajax.post<boolean>(EnumApiTicket.EXCHANGE_TICKET, {
      memberPhoneNum,
      productId,
      yzUserId,
      avatar
    })
  }

  /**
   * 校验用户笔山是否充足(参数只给商品id即可)
   */
  async isExchange(productId, memberPhoneNum) {
    return await this.$ajax.get<boolean>(EnumApiTicket.CHECK_BISHAN_COUNT, { productId, memberPhoneNum })
  }
}
export default ControllerTicket
