import { BindAll } from 'lodash-decorators'
import lodash from 'lodash'
import { action, observable } from 'mobx'
import { Pagination } from '../basics/pagination'
import { AjaxBasics } from '../../helpers'
import { EnumApiCoupon } from '../../api/coupon'
import { CouponPackItem } from '../../types/coupon'
import { EnumCouponStatus } from '../../enums'
import { CouponAssocProductItem } from '../../types/product'

@BindAll()
export class ControllerMyPacks extends Pagination<any> {
  constructor($ajax: AjaxBasics) {
    super($ajax, {})
  }

  @observable myPacksList: Array<CouponPackItem> = []

  /**
   * 获取我的卡券列表
   * @param couponType 100：福利券相关  200：文房券相关  300：伴学卡相关
   */
  async onGetMyPacks(couponType: number | string) {
    let packs = await this.$ajax.get<Array<CouponPackItem>>(EnumApiCoupon.CouponPacks, { couponType })
    packs = packs.map(v => {
      const currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
      // 未使用 & 当前时间大于有效期  手动改成已过期  因为券的过期时间在凌晨才会同步数据  导致已过期状态不精准
      if (v.status === EnumCouponStatus.UnUsed && currentTime > v.validEndTime) {
        lodash.set(v, 'status', EnumCouponStatus.Expired)
      }
      return v
    })
    this.setMyPacksList(packs)
    return packs
  }

  /**
   * 获取营销活动对应的商品列表
   */
  async onGetCouponAssocProduct(couponActivityId: number) {
    try {
      const resp = await this.$ajax.get<CouponAssocProductItem[]>(EnumApiCoupon.CouponAssocProduct, { couponActivityId })
      return resp
    } catch (error) {
      return []
    }
  }

  /**
   * 领取文房券
   */
  onReceiveCoupon(body: { avatar: string; couponId: number; memberPhoneNum: string; yzUserId: string }) {
    return this.$ajax.post<boolean>(EnumApiCoupon.CouponReceive, body)
  }

  onResetMyPackList() {
    this.setMyPacksList([])
  }

  @action
  setMyPacksList(list: Array<CouponPackItem>) {
    this.myPacksList = list
  }
}
