















import { Component, Emit, Vue } from 'vue-property-decorator'
import lodash from 'lodash'

@Component({})
export default class ExchangeTabsComp extends Vue {
  tabPane = [
    { key: 0, title: '全部' },
    { key: 1, title: '福利券' },
    { key: 2, title: '文房券' }
    // { key: 3, title: '伴学卡' }
  ]
  defaultActiveKey = 0
  activeKey = lodash.get(this.$route.query, 'active', this.defaultActiveKey)

  @Emit('change')
  tabsChange(activeKey: string) {
    this.activeKey = activeKey
    return +this.activeKey
  }
}
