/// <reference types="./course" />
import { BindAll } from 'lodash-decorators'
import { EnumApiProduct, EnumApiCourse } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EntitiesBasics } from '../basics/entities'

@BindAll()
export class ClassHourLive extends EntitiesBasics<IClasshourEntities> {
  constructor(protected $ajax: AjaxBasics) {
    super($ajax, {
      url: EnumApiProduct.productCalendar
    })
  }

  /**
   * 日历信息
   * @param startTime 开始时间
   * @param countDay 所需天数
   * @returns
   */
  async onCalendarInfo(startTime, countDay: number) {
    try {
      const res: Array<any> = await this.$ajax.post(EnumApiProduct.productCalendar, {
        startTime,
        countDay
      })
      // const calendarInfo = lodash.map(res, item => {
      //   return new Classhour(item)
      // })
      // 过滤出来已发布的课时
      // return lodash.filter(res, item => {
      //   return item.publishStatus === 1
      // })
      return res
    } catch (error) {
      console.log(error)
      return []
    }
  }

  async onCurrentExistLive() {
    try {
      const exist: boolean = await this.$ajax.get(EnumApiProduct.courseExistLive, {})
      return exist
    } catch (error) {}
  }

  /**
   * 去看直播
   * @param id 课时id
   * @returns
   */
  async onGolive(id) {
    const url = await this.$ajax.request<string>({ url: EnumApiCourse.CourseLive, body: { id }, responseType: 'text' }).toPromise()
    return url
  }
}
