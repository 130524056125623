





























import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  scrollToTop: true
})
export default class PageView extends Vue {
  body = null
  get PageStore() {
    return this.$store.$coupon
  }
  get Pagination() {
    return this.PageStore.exchangeRecord
  }
  biShanType(itemType) {
    let types = ['上课', '活动', '消耗', '系统']
    return types[itemType - 1]
  }

  created() {}
  mounted() {}
  beforeDestroy() {
    //防止出现一些数据不显示问题
    this.Pagination.onReset()
  }
}
