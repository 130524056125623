
























































































import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import Video from './components/video.vue'
import Doc from './components/doc.vue'
import Footer from './components/footer.vue'
import Chat from './components/chat.vue'
import Question from './components/question.vue'
import Popup from './components/popup.vue'
import track from '@xt/client/utils/tracker'
import { UserInfo } from '@xt/client/entities/user/types'
import { reportEntryLog } from '@xt/client/utils/log'
import { GenseeLinkSearch, parseGenseeLink } from '@xt/client/entities/gensee/utils'
import { VideoLog } from '@xt/client/entities/gensee/log'

@Observer
@Component({
  components: {
    Video,
    Doc,
    Footer,
    Chat,
    Question,
    Popup
  },
  head() {
    return {
      htmlAttrs: {
        'xmlns:gs': 'http://www.gensee.com/ec'
      }
    }
  },
  async asyncData(ctx) {
    const { classhour } = ctx.query as { classhour: string }
    const tabId = sessionStorage.getItem('tab-id')

    if (!classhour) {
      reportEntryLog('playback.page-params.loss', { tabId })
      return ctx.redirect('/')
    }

    // 确保用户登录
    await ctx.store.$storeUser.onUpdatingUserInfo()
    const userInfo: UserInfo = ctx.store.$storeUser.UserInfo

    if (!ctx.store.$storeUser.isLogin || !userInfo) {
      reportEntryLog('playback.check-user.fail', { isLoigin: ctx.store.$storeUser.isLogin, tabId })
      return ctx.redirect('/')
    }

    // 获取直播链接
    const liveLink = await ctx.store.$storeCourse.Details.Map.Details.onGolive(classhour)

    if (!liveLink) {
      reportEntryLog('playback.get-link.fail', { tabId })
      return ctx.redirect('/')
    }

    // 解析直播链接参数
    const { isGray, gensee, sdk, k, courseId } = parseGenseeLink<GenseeLinkSearch>(liveLink, 'live')

    reportEntryLog('playback.gensee.debug', { gensee, isGray, tabId })

    if (!isGray) {
      location.replace(gensee)
      return
    }

    // 课时详情
    await ctx.store.$storeCourse.Details.Map.Details.onLoading({ id: classhour })

    await ctx.store.$genseePlayback.pageInit(courseId)

    ctx.store.$genseePlayback.initWidgetParams({
      uname: userInfo.nickname,
      uid: userInfo.id,
      ownerid: sdk,
      k,
      classhour
    })

    return { gensee }
  },
  layout: 'clean'
})
export default class PlaybackPageView extends Vue {
  // 是否让视频和文档大小屏幕切换  初始不切换
  toggle: boolean = false
  videoNode: HTMLMediaElement | null = null
  // 原直播间地址
  gensee: string = ''
  seekMillSeconds = 10 * 1000

  timer: null | ReturnType<typeof setTimeout> = null
  percentage = 0
  downtimeText = ''

  get PageStore() {
    return this.$store.$genseePlayback
  }

  get UserStore() {
    return this.$store.$storeUser
  }

  get classhourInfo() {
    return this.$store.$storeCourse.Details.Map.Details.dataSource.classhourInfo
  }

  get studyBuffEndTime() {
    return this.classhourInfo?.original?.studyBuffEndTime
  }

  get totalSeconds() {
    return this.classhourInfo?.original?.studyBuff ?? 0
  }

  get courseId() {
    return this.classhourInfo?.original?.courseId
  }

  // 切换屏幕
  onToggleScreen(toggle: boolean) {
    this.toggle = toggle
  }

  openSoundHandler() {
    this.PageStore.recoverVolumePlay()
    this.PageStore.resetSoundTimer()
  }

  ignoreSoundHandler() {
    this.PageStore.resetSoundTimer()
  }

  backOldVersionHandler() {
    reportEntryLog(VideoLog.playback_user_click_back_to_gensee, { tabId: this.PageStore.currentTabId })
    location.replace(this.gensee)
  }

  logoutToHomeHandler() {
    this.UserStore.clearUserInfo()
    this.PageStore.clearBrowserWatchData()
    this.$nextTick(() => {
      location.replace('/')
    })
  }

  toHomeHandler() {
    this.PageStore.clearBrowserWatchData()
    location.replace('/')
  }

  toMapHandler() {
    this.PageStore.clearBrowserWatchData()
    location.replace(`/course/${this.courseId}`)
  }

  keyDownHandler(e: KeyboardEvent) {
    if (e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) return

    switch (e.code) {
      case 'ArrowRight':
        this.PageStore.seekVideoByTimestamp(
          Math.min(this.PageStore.videoState.current + this.seekMillSeconds, this.PageStore.videoState.duration)
        )
        break
      case 'ArrowLeft':
        this.PageStore.seekVideoByTimestamp(Math.max(this.PageStore.videoState.current - this.seekMillSeconds, 0))
        break
      case 'Space':
        this.PageStore.toggleVideoPlay()
        break
    }
  }

  handleTick() {
    if (this.timer) {
      clearTimeout(this.timer)
    }

    const { isCountdownEnd, h, m, restPercentage } = this.PageStore.getStudyBuffRestTime(this.studyBuffEndTime, this.totalSeconds)
    console.warn('~Tick', isCountdownEnd, h, m, restPercentage)

    if (isCountdownEnd) {
      this.downtimeText = '0分钟后回顾结束'
      this.percentage = 0
      this.PageStore.updateStudyBuffEnd(true)
      return
    }

    this.downtimeText = `${h !== 0 ? `${h}小时` : `${Math.max(m, 1)}分钟`}后回顾结束`
    this.percentage = restPercentage
    console.log(this.percentage)

    this.timer = setTimeout(() => {
      this.handleTick()
    }, 1000 * 10)
  }

  handleReloadPage() {
    reportEntryLog(VideoLog.playback_loading_too_long_reload, { tabId: this.PageStore.currentTabId })
    this.PageStore.setPageLoadingTooLong(false)

    window.location.reload()
  }

  handleKeepWaiting() {
    reportEntryLog(VideoLog.playback_loading_too_long_keep_wait, { tabId: this.PageStore.currentTabId })
    this.PageStore.setPageLoadingTooLong(false)
  }

  created() {
    window.addEventListener('keydown', this.keyDownHandler)

    if (this.studyBuffEndTime) {
      this.handleTick()
    }
  }
  mounted() {
    track.record(this.$ajax)
    this.PageStore.init('playback-group')
  }
  destroyed() {
    window.removeEventListener('keydown', this.keyDownHandler)

    clearTimeout(this.timer)
  }
}
