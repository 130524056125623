















































































import { Component, Prop, Vue, Emit, Watch, Ref } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { BaseCouponItem } from '@xt/client/types/coupon'
import { seconds2Hours } from '@xt/client/utils/parser/index'

@Observer
@Component
export default class PlaybackExtensionPopup extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ required: true }) classhourId: string
  @Prop({ default: () => [] }) dataSource: BaseCouponItem[]

  get existSelectItem() {
    return this.selectIndex !== null
  }

  get CourseDetailStore() {
    return this.$store.$storeCourse.Details
  }

  selectIndex: number | null = null
  viewMaxShowLength = 9
  viewLastIndex = 8
  useExtensionCoupon = false

  @Ref('study-review-scroll')
  studyReviewScroll: HTMLDivElement

  hours(seconds: number) {
    return seconds2Hours(seconds)
  }

  onSelect(index: number) {
    if (this.useExtensionCoupon) return
    if (!this.existSelectItem) {
      this.selectIndex = index
      return
    }
    if (index !== this.selectIndex) {
      this.selectIndex = index
      return
    }
    this.selectIndex = null
  }

  onToggle(type: 'prev' | 'next') {
    if (this.studyReviewScroll) {
      if (type === 'next' && this.viewLastIndex === this.dataSource.length - 1) return
      if (type === 'prev' && this.viewLastIndex === this.viewMaxShowLength - 1) return

      if (type === 'next') {
        this.viewLastIndex++
      } else {
        this.viewLastIndex--
      }
      const target = -94 * (this.viewLastIndex + 1 - this.viewMaxShowLength)
      this.studyReviewScroll.style.transform = `translateX(${this.$px2rem(target)})`
    }
  }

  activing = false
  async activeCompanionCard() {
    try {
      if (this.activing) return
      this.activing = true
      const result = await this.CourseDetailStore.onActiveCompanionCard(this.classhourId, this.dataSource[this.selectIndex].id)
      if (result === true) {
        this.$msg('使用成功', 'success')
        this.useExtensionCoupon = true
        this.$emit('success')
      }
    } finally {
      this.activing = false
    }
  }

  async extensionHandler() {
    if (!this.existSelectItem) {
      this.$msg('请选择你要使用的回顾卡')
      return
    }

    if (this.useExtensionCoupon) {
      this.$emit('jump')
    } else {
      await this.activeCompanionCard()
    }
  }

  reset() {
    this.selectIndex = null
    this.viewLastIndex = 8
    this.useExtensionCoupon = false
    this.studyReviewScroll.style.transform = 'translateX(0)'
  }

  @Emit('close')
  closeMadal() {}

  @Watch('visible')
  changeVisible(visible: boolean) {
    if (!visible) {
      this.reset()
    } else {
      if (this.dataSource.length === 1) {
        this.selectIndex = 0
      }
    }
  }

  created() {}
  mounted() {}
}
