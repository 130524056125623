




































































































import lodash from 'lodash'
import moment from 'moment'
import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { DatePicker, Cascader } from 'ant-design-vue'
import { dataSource_My } from '@xt/client'
import { dataSource_country_provinc, countryCascaderFilter, getLocationCascader } from '@xt/client'
import UpdateAvatar from './views/updateAvatar.vue'
import HeaderImage from '../../../../components/headerImage/index.vue'
import { identity } from 'rxjs'

@Observer
@Component({
  scrollToTop: true,
  async fetch(ctx) {
    const store = ctx.store.$storeUser
    await store.onUpdatingUserInfo()
  },
  components: { DatePicker, Cascader, UpdateAvatar, HeaderImage }
})
export default class PageView extends Vue {
  @Ref('nickNameInput') nickNameInput
  submitNickName: boolean = false
  get PageStore() {
    return this.$store.$storeUser
  }
  inputVisible = false
  userForm = onCreateModel()

  // 地区 配置
  get countryOptions() {
    return dataSource_country_provinc
  }
  //  地区 搜索
  countrySearch = { filter: countryCascaderFilter }
  created() {
    lodash.defaults(this, dataSource_My)
    this.setUserForm(this.PageStore.UserInfo)
  }
  setUserForm(dataSource) {
    let data = lodash.pick(dataSource, [
      'nickname', // 昵称
      'address', // 地址
      'birthday', // 生日
      'career', // 职业
      'education', // 学历
      'id', //id
      'industry', // 行业
      'location', // 所在地（0 未知，1 国内，2国外）
      'city', // 城市
      'country', // 国家
      'province', // 省份
      'sex' // 性别(0 未知 1 男 2女)
    ])
    if (data.birthday) {
      data.birthday = this.moment(data.birthday)
    }
    this.userForm = getLocationCascader(data)
  }

  /** 获取icon type */
  getLink(item) {
    return lodash.get(['question', 'man', 'woman'], item)
  }
  /**
   * 切换nickName输入框
   */
  toggleInput() {
    this.inputVisible = !this.inputVisible
    if (this.inputVisible) {
      this.$nextTick(() => {
        this.nickNameInput.focus()
      })
    }
  }

  test() {}

  /**
   * input取消焦点 更新nickname
   */
  async onBlur() {
    this.submitNickName = true
    try {
      // 昵称未改变
      if (this.userForm.nickname == this.PageStore.UserInfo.nickname) {
        this.inputVisible = false
        this.submitNickName = false
        return
      }
      // 昵称为空
      if (this.userForm.nickname.trim() == '') {
        this.userForm.nickname = this.PageStore.UserInfo.nickname
        this.inputVisible = false
        this.submitNickName = false
        return
      }
      await this.onUpdate({
        id: this.userForm.id,
        nickname: this.userForm.nickname
      })
      this.submitNickName = false
      this.inputVisible = false
    } catch (error) {
      this.submitNickName = false
      this.$msg(error)
    }
  }
  async onSubmit() {
    if (this.submitNickName) return
    let data = lodash.cloneDeep(this.userForm)
    // 去掉昵称
    lodash.unset(data, 'nickname')
    // 转换生日时间戳
    data.birthday = data.birthday.valueOf()
    // 转换地址
    data = getLocationCascader(data, true)
    await this.onUpdate(data)
    // await this.PageStore.onUpdatingUserInfo();
  }

  async onUpdate(data: any = this.userForm) {
    try {
      await this.PageStore.onUpdateUserInfo(data)
      this.$msg(this.$EnumMessage.save_success, 'success')
      let userForm = await this.PageStore.onUpdatingUserInfo()
      this.setUserForm(userForm)
    } catch (error) {
      this.$msg(error)
    }
  }
  updated() {}
  destroyed() {}
}
function onCreateModel() {
  return {
    address: '', // 地址
    birthday: '', // 生日
    career: '', // 职业
    education: '', // 学历
    id: '', //id
    industry: '', // 行业
    location: '', // 所在地（0 未知，1 国内，2国外）
    city: '', // 城市
    country: '', // 国家
    locationCascader: [],
    nickname: '', // 昵称
    province: '', // 省份
    sex: '' // 性别(0 未知 1 男 2女)
  }
}
