<template>
  <div class="index">
    <!-- 背景图 -->
    <!-- <div class="bg-model">
      <LazyImg className="bg-model-bg" :src="`${dataSource.banner}?x-oss-process=image/format,jpg/quality,q_70`" />
    </div> -->
    <div class="chaozhou-container" v-if="true">
      <div class="travel-bg-model">
        <LazyImg className="travel-bg-model-img" :src="`${$images.chaozhou_cover_url}?x-oss-process=image/format,jpg/quality,q_95`" />
      </div>
      <div class="travel-container-model">
        <div class="travel-content">
          <div class="travel-content-left">
            <img :src="$images.chaozhou_travel_text" class="travel-text-img" />
            <img :src="$images.chaozhou_travel_btn" class="travel-btn-img" @click="goTravelDetail" />
            <img :src="$images.chaozhou_travel_logo" class="travel-logo-img" />
          </div>
          <div class="travel-content-right">
            <img :src="$images.chaozhou_travel_map" class="travel-map-img" />
            <div class="common-station chaozhou-station">
              <img :src="$images.chaozhou_station" class="station-img" />
              <img :src="$images.chaozhou_station_hover" class="station-img station-hover-img" />
              <img :src="$images.chaozhou_station_popup" class="station-popup-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 推荐课程 -->
    <div class="hot-course-model" v-if="dataSource.hotCourse">
      <!-- <LazyImg className="bg-model-text" :src="ossProcessImg($images.home_banner_im, 352, 89)" :noBackground="true" /> -->
      <!-- transition: opacity 0s; opacity: 1; 产品经理让删的 -->
      <!-- :styles="'transition: zIndex 1.2s;'+`z-index: ${index === currenthotCourse ? 7 : 1}`" -->
      <div class="hot-course">
        <div class="hot-course-box">
          <div @mouseenter="hoverHotCourse" @mouseleave="leaveHotCourse">
            <HotCourse
              v-for="(item, index) in dataSource.hotCourse"
              :styles="
                'transition: opacity 1.2s;' + `z-index: ${index === currenthotCourse ? 7 : 1};opacity:${index === currenthotCourse ? 1 : 0}`
              "
              :key="index"
              :dataSource="item"
              @changeHot="changeHot"
            ></HotCourse>
          </div>
          <div v-if="dataSource.hotCourse.length > 1" class="hot-course-track">
            <div
              :style="`
              width: ${(100 / dataSource.hotCourse.length) * (currenthotCourse + 1)}%;
            `"
            ></div>
          </div>
          <div class="hot-course-pre" v-if="dataSource.hotCourse.length > 1" @mouseenter="hoverHotCourse" @mouseleave="leaveHotCourse">
            <div class="hot-course-pre-icon-box">
              <i
                @click="uphotCourse"
                @mouseover="hotCourseUpHover = true"
                @mouseout="hotCourseUpHover = false"
                :class="`iconfont 
                  ${hotCourseUpHover ? 'iconbanner_switch_hover_ic' : 'iconbanner_switch_ic'} 
                  hot-course-icon hot-course-up-icon`"
              ></i>
            </div>
            <!-- 轮播图焦点切换 start -->
            <div
              :class="`hot-course-point-box ${index === currenthotCourse && 'hot-course-point-selected'}`"
              v-for="(item, index) in dataSource.hotCourse"
              @click.stop="changeCurrentCourse(index)"
              :key="index"
            >
              <div class="hot-course-point">
                <LazyImg className="hot-course-point-img" :src="ossProcessImg(item.courseCoverImgDot, 140, 100, true)" />
              </div>
              <LazyImg
                className="hot-course-point-img-Icon"
                v-if="showHotIndexs.includes(item.courseId)"
                :src="ossProcessImg($images.hotIcon, 48, 54)"
                :noBackground="true"
              />
              <div class="hot-course-point-overlayer"></div>
            </div>
            <!-- 轮播图焦点切换 end -->
            <div class="hot-course-next-icon-box">
              <i
                @click="nexthotCourse"
                @mouseover="hotCourseNextHover = true"
                @mouseout="hotCourseNextHover = false"
                :class="`iconfont 
                  ${hotCourseNextHover ? 'iconbanner_switch_hover_ic' : 'iconbanner_switch_ic'} 
                  hot-course-icon hot-course-next-icon`"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 学习地图  -->
    <div class="studio-model">
      <div class="studio-model-box">
        <Studio :dataSource="dataSource" />
      </div>
    </div>
    <!-- 课程展览列表 -->
    <div class="course-model">
      <LazyImg className="course-model-live2" :src="ossProcessImg($images.live2_bg_ele, 595, 792)" :noBackground="true" />
      <LazyImg className="course-model-live1" :src="ossProcessImg($images.live1_bg_ele, 736, 1182)" :noBackground="true" />
      <Swiper
        :jump="goAllCourse('live')"
        v-if="dataSource.liveCourse && dataSource.liveCourse.length"
        :more="true"
        title="直播课"
        :type="EnumProductTypeSimple['Live']"
        :dataSource="this.dataSource.liveCourse"
        :pageSize="3"
        :duration="2000"
      />
      <Swiper
        :jump="goAllCourse('day')"
        v-if="dataSource.dayCourse && dataSource.dayCourse.length"
        :dayclass="true"
        :more="true"
        title="教练日课"
        :type="EnumProductTypeSimple['DayCourse']"
        :dataSource="this.dataSource.dayCourse"
        :pageSize="3"
        :duration="2000"
      />
      <Swiper
        :jump="goAllCourse('exhibition')"
        v-if="dataSource.shows && dataSource.shows.length"
        :more="true"
        title="展览辅导课"
        show="true"
        :type="EnumProductTypeSimple['Exhibition']"
        :dataSource="this.dataSource.shows"
        :pageSize="3"
        :duration="2000"
      />
      <Swiper
        :jump="goAllCourse('selfStudy')"
        v-if="dataSource.selfStudy && dataSource.selfStudy.length"
        :more="true"
        title="自习课"
        :show="false"
        :type="EnumProductTypeSimple['SelfStudy']"
        :dataSource="this.dataSource.selfStudy"
        :pageSize="3"
        :duration="2000"
      />
    </div>
    <!-- 公开课 -->
    <div class="public-course-model" v-if="videoSource && videoSource.length">
      <LazyImg className="public-class-bot-ele" :src="ossProcessImg($images.public_class_bot_ele, 269, 642)" :noBackground="true" />
      <LazyImg className="public-class-top-ele" :src="ossProcessImg($images.public_class_top_ele, 473, 1128)" :noBackground="true" />
      <div class="public-box" v-if="videoSource">
        <div class="public-box-label">公开课</div>
        <PublicCourse v-for="item in videoSource" :key="item.id" :dataSource="item"></PublicCourse>
        <div class="public-course-more" @click="window.open('/video')">查看更多</div>
      </div>
    </div>
    <ClassMates v-if="dataSource.classmates" :dataSource="dataSource.classmates" />
    <Share />
    <WF />
    <Video />
    <About />
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator'
import Swiper from './views/swiper'
import HotCourse from './views/hotCourse'
import PublicCourse from './views/publicCourse'
import Studio from './views/studio/index.vue'
import ClassMates from './views/classmates'
import Share from './views/share'
import Video from './views/video'
import About from './views/about'
import WF from './views/wf'
import LazyImg from '@/components/lazyImg/index.vue'
import { Observer } from 'mobx-vue'
import { EnumProductTypeSimple } from '@xt/client/enums/product'
// import Data from '@xt/client/static/mocks/home.new.json'

@Observer
@Component({
  async asyncData(ctx) {
    const res = await ctx.store.$ajax.get(
      `/mocks/home.new.json`,
      {},
      { 'Cache-Control': 'no-store', 'If-Modified-Since': '0' },
      { target: ctx.store.$global.production ? 'https://static.xuantong.cn' : 'https://testing-static.xuantong.cn' }
    )
    // const res = Data
    return {
      dataSource: res
    }
  },
  components: {
    Swiper,
    HotCourse,
    WF,
    PublicCourse,
    Studio,
    ClassMates,
    Share,
    Video,
    About,
    LazyImg
  },
  name: 'IndexComp'
})
class Index extends Vue {
  dataSource = {}
  rollInterval = null
  data() {
    return {
      videoSource: [],
      currenthotCourse: 0,
      hotCourseNextHover: false,
      hotCourseUpHover: false,
      showPopVisible: false,
      swiperInterval: 5000,
      showHotIndexs: [], //展示小火苗的课程索引
      EnumProductTypeSimple
    }
  }
  goTravelDetail() {
    window.open('/activitys/travel_note')
  }
  goAllCourse(type) {
    if (type == 'day') {
      return 'product/list?typeCurrent=2&directionCurrent=0'
    }
    if (type == 'live') {
      return 'product/list?typeCurrent=1&directionCurrent=0'
    }
    if (type == 'exhibition') {
      return 'product/list?typeCurrent=3&directionCurrent=0'
    }
    if (type == 'selfStudy') {
      return 'product/list?typeCurrent=4&directionCurrent=0'
    }
  }
  //修改火苗
  changeHot(courseId, addRemove) {
    if (addRemove == 'add' && !this.showHotIndexs.includes(courseId)) {
      this.showHotIndexs.push(courseId)
    } else if (addRemove == 'remove' && this.showHotIndexs.includes(courseId)) {
      this.showHotIndexs.splice(
        this.showHotIndexs.findIndex(item => item == courseId),
        1
      )
    }
  }
  isHoverArea = false
  //鼠标移动到卡片上
  hoverHotCourse() {
    clearInterval(this.rollInterval)
    this.isHoverArea = true
    this.rollInterval = null
  }
  //鼠标移出卡片上
  leaveHotCourse() {
    this.isHoverArea = false
    this.setIntervalRoll()
  }
  get production() {
    return this.$store.$global.production
  }

  destroyed() {
    //销毁定时器
    this.hoverHotCourse()
  }
  async created() {
    await this.$store.$storeVideo.RecommendPagination.onLoading()
    this.videoSource = this.$store.$storeVideo.RecommendPagination.dataSource?.filter((v, k) => k < 3)
  }
  mounted() {
    //开始滚动
    this.setIntervalRoll(true)
  }
  get window() {
    return window
  }
  uphotCourse() {
    clearInterval(this.rollInterval)
    if (this.currenthotCourse === 0) {
      this.currenthotCourse = this.dataSource.hotCourse.length - 1
    } else this.currenthotCourse -= 1
    if (!this.isHoverArea) {
      this.setIntervalRoll(true)
    }
    return
  }
  nexthotCourse() {
    clearInterval(this.rollInterval)
    if (this.currenthotCourse === this.dataSource.hotCourse.length - 1) {
      this.currenthotCourse = 0
    } else {
      this.currenthotCourse += 1
    }
    if (!this.isHoverArea) {
      this.setIntervalRoll(true)
    }
    return
  }
  rollhotCourse() {
    if (this.dataSource.hotCourse && this.currenthotCourse === this.dataSource.hotCourse.length - 1) return (this.currenthotCourse = 0)
    return (this.currenthotCourse += 1)
  }
  changeCurrentCourse(index) {
    clearInterval(this.rollInterval)
    this.rollInterval = null
    this.currenthotCourse = index
    if (!this.isHoverArea) {
      this.setIntervalRoll(true)
    }
  }
  setIntervalRoll(clear = false) {
    if (clear) {
      this.clearIntervalRoll()
    }
    this.rollInterval = setInterval(() => {
      this.rollhotCourse()
    }, this.swiperInterval)
  }
  clearIntervalRoll() {
    clearInterval(this.rollInterval)
    this.rollInterval = null
  }
}

export default Index
</script>

<style scoped lang="less">
.index {
  .bg-model {
    width: 100vw;
    min-width: 1920px;
    overflow: hidden;
    min-height: 750px;
    margin-left: 50%;
    height: calc(50vh + 10px);
    transform: translateX(-50%);
    position: relative;
    background: #fcfcfc;
    &-bg {
      width: 100vw;
      min-width: 1920px;
      min-height: 823px;
      height: calc(100vw * (9 / 21));
      user-select: none;
      -webkit-user-drag: none;
    }
  }

  .chaozhou-container {
    position: relative;

    .travel-bg-model {
      position: relative;
      overflow: hidden;
      width: 100vw;
      min-width: 1200px;
      height: 734px;

      &-img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        min-width: 1920px;
        height: auto;
        user-select: none;
        -webkit-user-drag: none;
      }
    }

    .travel-container-model {
      overflow: hidden;
      position: absolute;
      top: 118px;
      display: flex;
      justify-content: center;
      width: 100%;
      min-width: 1200px;
      height: 468px;

      .travel-content {
        display: flex;
        width: 1200px;
        height: 468px;

        &-left {
          width: 436px;
          height: 100%;
          padding-top: 134px;
          flex-shrink: 0;

          .travel-text-img {
            width: 375px;
            height: 156px;
            margin-left: 30px;
          }

          .travel-btn-img {
            width: 128px;
            height: 40px;
            opacity: 0.8;
            transition: opacity 0.3s;
            margin-top: 36px;
            margin-left: 36px;
            cursor: pointer;

            &:hover {
              opacity: 1;
            }
          }

          .travel-logo-img {
            width: 400px;
            height: 32px;
            margin-top: 36px;
            margin-left: 36px;
          }
        }

        &-right {
          position: relative;
          width: 858px;
          height: 476px;
          flex-shrink: 0;
          transform: translate(-108px, -8px);

          .travel-map-img {
            width: 858px;
            height: 476px;
          }

          .common-station {
            position: absolute;
            width: 168px;
            height: 250px;

            .station-img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 2;
            }

            .station-hover-img {
              opacity: 0;
            }

            .station-popup-img {
              position: absolute;
              bottom: 20px;
              right: 100px;
              width: 294px;
              height: 400px;
              opacity: 0;
              transition: opacity 0.3s;
              // 禁止hover事件冒泡
              pointer-events: none;
            }
          }

          .chaozhou-station {
            top: 160px;
            right: 94px;

            &:hover {
              .station-hover-img {
                opacity: 1;
              }

              .station-popup-img {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .hot-course-model {
    position: absolute;
    top: 474px;
    top: 586px;
    width: 100%;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    z-index: 8;

    .hot-course {
      width: 1200px;
      min-width: 1200px;
      height: 445px;
      background: #fcfcfc;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 30px;
      position: relative;
      &-pre {
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 432px;
        bottom: -96px;
        display: inline-flex;
        justify-content: center;
        height: 65px;
        z-index: 2;
        &-icon-box {
          position: relative;
          height: 100%;
          width: 44px;
          margin-right: 27px;
          &:nth-child(1) {
            text-align: right;
          }
          &:nth-child(2) {
            text-align: left;
          }
        }
      }
      &-next {
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 432px;
        bottom: -96px;
        display: inline-flex;
        justify-content: center;
        height: 65px;
        z-index: 2;
        &-icon-box {
          position: relative;
          height: 100%;
          width: 44px;
          margin-left: 27px;
          &:nth-child(1) {
            text-align: right;
          }
          &:nth-child(2) {
            text-align: left;
          }
        }
      }
      &-icon {
        font-size: 44px;
        // color: #BDA893;
        color: rgba(189, 168, 147, 0.8);
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        &:hover {
          cursor: pointer;
          filter: grayscale(25%);
        }
      }
      &-up-icon {
        transform: rotateY(180deg);
        // margin-right: 25px;
      }
      &-next-icon {
        // margin-left: 16px;
      }
      .iconbanner_switch_hover_ic {
        color: rgba(189, 168, 147, 1);
      }
      &-point {
        display: inline-block;
        overflow: hidden;
        width: 70px;
        height: 50px;
        border-radius: 12px;
        // transition: all 500ms cubic-bezier(0.320, 0.600, 0.720, 0.565);
        // transition: all 500ms  cubic-bezier(0.005, 0.980, 0.495, 0.875);
        transition: all 500ms cubic-bezier(0.07, 0.73, 0.695, 0.855);
        &-img {
          width: 70px;
          height: 50px;
          border-radius: 12px;
        }
        &-img-Icon {
          width: 16px;
          height: 18px;
          position: absolute;
          right: 0;
          top: -0px;
          z-index: 999;
        }
        &-box {
          margin: 0px 10px;
          position: relative;
          height: 100%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          transition: all 500ms cubic-bezier(0.32, 0.6, 0.72, 0.565);
          cursor: pointer;
        }
        &-overlayer {
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          left: 0;
          right: 0;
          z-index: 998;
          width: 70px;
          height: 50px;
          border-radius: 12px;
          transition: 0.5s all ease-out;
          // transition: all 500ms cubic-bezier(0.320, 0.600, 0.720, 0.565);
        }
      }
      &-point-box:hover {
        .hot-course-point-overlayer {
          background: none;
        }
        // &-overlayer{
        //   background: rgba(0,0,0,.5);
        //   position:absolute;
        //   left:0;
        //   right:0;
        //   z-index: 998;
        //   width:70px;
        //   height:50px;
        //   border-radius:12px;
        //   transition:0.5s all ease-out;
        //   // transition: all 500ms cubic-bezier(0.320, 0.600, 0.720, 0.565);
        // }
      }
      // &-point-box:hover{
      //   .hot-course-point-overlayer{
      //     background:none;
      //   }
      // }
      &-point-selected {
        transform: scale(1.2);
        .hot-course-point-overlayer {
          background: none;
        }
      }
      &-box {
        width: 1200px;
        min-width: 1200px;
        position: relative;
        height: 445px;
        border-radius: 30px;
        background: #fff;
      }
    }

    .bg-model-text {
      position: absolute;
      bottom: 415px;
      width: 352px;
      height: 89px;
      margin: auto;
      left: 0;
      right: 0;
      top: calc(-89px + -10vh);
      transform: translateX(-345px);
    }
  }

  .studio-model {
    width: 100%;
    min-width: 1200px;
    height: calc(1316px + 586px - 474px);
    background: #f9f5f3;
    padding-top: calc(586px - 474px);
    display: flex;
    justify-content: center;
    &-box {
      width: 1200px;
      min-width: 1200px;
      &-right {
        margin-right: 80px;
      }
    }
  }

  .course-model {
    width: 100%;
    min-width: 1200px;
    background-color: #ede5dd;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 130px;
    padding-top: 50px;
    position: relative;
    &-live2 {
      width: 595px;
      height: 792px;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translateX(-616px);
    }
    &-live1 {
      width: 736px;
      height: 1182px;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      transform: translateX(592px);
    }
  }

  .public-course-more {
    width: 120px;
    height: 36px;
    border-radius: 19px;
    border: 1px solid #8c96a8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #8c96a8;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 80px;
    cursor: pointer;

    &:hover {
      border: 1px solid #8c96a8;
      background: #8c96a8;
      color: white;
    }
  }

  .public-course-model {
    width: 100%;
    min-width: 1200px;
    height: 1673px;
    background: #f6f6f6;
    text-align: center;
    position: relative;
    .public-class-bot-ele {
      width: 269px;
      height: 642px;
      position: absolute;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      transform: translateX(776px);
    }
    .public-class-top-ele {
      width: 473px;
      height: 1128px;
      position: absolute;
      top: 130px;
      margin: auto;
      left: 0;
      right: 0;
      transform: translateX(-706px);
    }
    .public-box {
      width: 1200px;
      min-width: 1200px;
      padding-top: 100px;
      display: inline-block;
      text-align: left;
      z-index: 9;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      &-label {
        font-size: 34px;
        font-weight: 500;
        color: #000000;
        line-height: 34px;
      }
    }
  }
}
</style>
