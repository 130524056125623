




























































































































import { Component, Vue, Watch, Emit, Mixins } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { MixinsCourseClasshour } from '@xt/client/mixins'
import PlaybackExtensionPopup from '@xt/www/components/coupon/PlaybackExtensionPopup.vue'

@Observer
@Component({
  components: {
    PlaybackExtensionPopup
  }
})
export default class PageView extends Mixins(MixinsCourseClasshour) {
  @Watch('$route.query.classhour')
  onWatch(newVal, oldVal) {
    if (newVal) {
      this.onLoading(newVal)
    }
  }

  onJumpPlayback() {
    this.hideCompanionCardPop()
    this.gotoGenseeRoom('playback', this.id as string)
  }

  onRefreshData() {
    // 重新刷新下store数据
    this.onLoading(this.id)
  }

  created() {
    if (this.id) {
      this.onLoading(this.id)
    }
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
