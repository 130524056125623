export enum EnumApiCoupon {
  // 完成订单关联的优惠券列表
  OrderAssocCoupon = '/coupon/api/coupon/activity/assoc/{productId}',
  // 用户的优惠券列表
  CouponPacks = '/coupon/api/coupon/packs',
  // 获取购买该商品最优优惠券组合
  CouponOptimalCompose = '/coupon/api/coupon/optimal/compose',
  // 获取订单关联优惠券
  CouponAssocByOrder = '/coupon/api/coupon/views',
  // 查询营销活动可兑换商品信息
  CouponAssocProduct = '/coupon/api/coupon/activity/assoc/product',
  // 领取有赞文房券
  CouponReceive = '/coupon/api/youzan/verification/receive',
  // 支付成功后查询优惠券礼物
  OrderSuccessCouponGift = '/coupon/api/coupon/issue/result/{orderNo}',
  // 阅读优惠券消息
  ReadCouponMessage = '/coupon/api/coupon/message/read',
  // 查询用户优惠券发放通知
  CouponMessage = '/coupon/api/coupon/message/list',
  // 激活回放延长卡
  CompanionCardUse = `/course/api/use/buff?classhourId={classhourId}&couponId={couponId}`,
  // 优惠券兑换记录
  ExchangeRecode = '/coupon/api/coupon/exchange/record'
}
