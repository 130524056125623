
































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ExchangeProductItem } from '@xt/client/entities/ticket/product'
import { EnumCouponType, EnumPreferentialType, EnumExchangeType } from '@xt/client/enums'
import { FormatCouponInfo, formatCouponInfo } from '@xt/client/utils/coupon'

@Component({})
export default class ExchangeProductComp extends Vue {
  @Prop() dataSource: ExchangeProductItem

  bgUrl = ''
  existPicture = false
  couponInfo: FormatCouponInfo | null = null

  get couponDetail() {
    return this.dataSource.couponActivityDetails
  }

  get EnumExchangeType() {
    return EnumExchangeType
  }

  get isFullReductionCoupon() {
    return this.couponDetail.preferentialType === EnumPreferentialType.FullReduction
  }

  get isDiscountCoupon() {
    return this.couponDetail.preferentialType === EnumPreferentialType.Discount
  }

  get isUnlimited() {
    return this.couponDetail.userTakeNumLimit === 99
  }

  goExchange() {
    this.$router.push({
      name: 'my-exchange-detail',
      query: {
        productId: `${this.dataSource.id}`
      }
    })
    window.scrollTo({ top: 0 })
  }

  created() {
    this.couponInfo = formatCouponInfo(this.couponDetail, 'activity')

    const pictures = this.dataSource.pictureResultVO
    if (pictures && pictures.length > 0) {
      this.existPicture = true
      this.bgUrl = pictures[0].originalUrl
      return
    }

    switch (this.couponDetail.couponType) {
      case EnumCouponType.FullReduction:
        this.bgUrl = this.$images.point_exchange_reduction
        break
      case EnumCouponType.Discount:
        this.bgUrl = this.$images.point_exchange_discount
        break
      case EnumCouponType.Exchange:
        this.bgUrl = this.$images.point_exchange_course
        break
      case EnumCouponType.PencilStore:
        this.bgUrl = this.$images.point_exchange_study
        break
      case EnumCouponType.Companion:
        this.bgUrl = this.$images.point_exchange_companion
        break
    }
  }
}
