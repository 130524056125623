/** * wangsl */
<template>
  <div class="course" @click="toDetail">
    <div class="course-cover">
      <LazyImg :src="ossProcessImg(dataSource.courseCoverImg, 405, 250)" />
    </div>
    <!-- 团购标识 -->
    <!-- <div class="group-icon" v-if="dataSource.isGroup=='true'"><LazyImg :src="$images.home_list_collage_pcim" :noBackground="true" /></div> -->
    <div class="course-types">
      <div class="course-types-label">{{ dataSource.courseContentType }}</div>
      <div class="course-name">{{ dataSource.courseTitle }}</div>
      <!-- <div class="course-types-icon"></div> -->
      <!-- <div class="course-types-text">{{dataSource.courseType == 1 ? "单阶" : (
          dataSource.courseType == 2 ? "全阶" : "教练日课"
        )}}</div> -->
    </div>
    <!-- <div class="course-title">{{dataSource.courseTitle}}</div> -->
    <div class="course-vice">{{ dataSource.courseViceTitle }}</div>
    <div class="course-line"></div>
    <div class="course-wait" v-if="forward"><span>敬请期待～</span></div>
    <div :class="`course-lastline course-lastline${btn.color}`" v-else>
      <div :class="`price-label price-label${btn.color}`">
        <span v-if="dataSource.coursePrice" class="price-label-num" v-money="dataSource.coursePrice"></span>
        <span v-else class="price-label-num">免费</span>
        <span class="price-label-num">/</span>
        <span class="price-label-count">共{{ dataSource.courseCount }}节</span>
      </div>
      <div class="course-lastline-state">
        <img v-if="btn.hot" :src="ossProcessImg($images.fire_stroke_im, 28, 32)" />
        {{ btn.text }}
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'
import { AjaxBasics } from '@xt/client'
import moment from 'moment'

@Component({
  components: {
    LazyImg
  }
})
class Index extends Vue {
  // 数据
  @Prop() dataSource

  get forward() {
    return this.dataSource.isLookforward
  }

  /**
   * 跳转到详情页
   */
  toDetail() {
    if (this.dataSource.isLookforward) return this.$msg('敬请期待~')
    if (this.dataSource.url) return window.open(this.dataSource.url)
    if (this.dataSource.courseType == 1 || this.dataSource.courseType == 2) {
      window.open(`/commodity/${this.dataSource.productId}`)
    }
  }

  data() {
    return {
      countdownText: ''
    }
  }

  interval = null

  countdown() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    // 若报名结束且转报未结束则进行转报倒计时
    if (currentTime < this.dataSource.courseConvertEndTime && currentTime >= this.dataSource.courseSignUpEndTime) {
      return 2
    }
    // 若报名未结束且报名开始则进行报名倒计时
    if (currentTime > this.dataSource.courseSignUpStartTime && currentTime < this.dataSource.courseSignUpEndTime) {
      return 1
    }
    // 不需要倒计时
    return 0
  }

  get btn() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (!this.dataSource.courseSignUpStartTime) {
      return { text: '即将报名', color: '-red' }
    }
    if (currentTime < this.dataSource.courseSignUpStartTime) {
      return { text: `报名时间：${moment(this.dataSource.courseSignUpStartTime).format('YYYY.MM.DD')}`, color: '-red' }
    }
    // 当前时间大于转报结束时间且课程结束则 已结课
    if (currentTime >= this.dataSource.courseConvertEndTime && currentTime >= this.dataSource.courseEndTime) {
      return { text: '已结课', color: '-black' }
    }
    // 当前时间大于转报结束时间且大于报名结束时间且课程已开启则 开课中
    if (
      currentTime >= this.dataSource.courseConvertEndTime &&
      currentTime >= this.dataSource.courseSignUpEndTime &&
      currentTime >= this.dataSource.courseStartTime
    ) {
      return { text: '开课中', color: '-yellow' }
    }
    // 当前时间大于转报结束时间且课程未开启则 转报结束
    if (
      currentTime >= this.dataSource.courseConvertEndTime &&
      currentTime >= this.dataSource.courseSignUpEndTime &&
      currentTime <= this.dataSource.courseStartTime
    ) {
      return { text: '报名结束', color: '-red' }
    }
    // 当前时间大于报名结束时间且转报开启则 转报全阶
    if (currentTime >= this.dataSource.courseSignUpEndTime && currentTime < this.dataSource.courseConvertEndTime) {
      return { text: this.countdownText, color: '-red', hot: true }
    }
    // 当前时间大于报名开始时间且小于报名结束时间则  我要报名
    if (currentTime >= this.dataSource.courseSignUpStartTime && currentTime < this.dataSource.courseSignUpEndTime) {
      return { text: this.countdownText, color: '-red', hot: true }
    }
    // 默认
    return { text: '已结课', color: '-black' }
  }

  get moment() {
    return moment
  }

  showCourseTime() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    return currentTime >= this.dataSource.courseConvertEndTime && currentTime >= this.dataSource.courseSignUpEndTime
  }

  countdownInterval(endtime) {
    this.computed(endtime)
    // this.interval = setInterval(() => {
    //   this.computed(endtime)
    // }, 1000);
    //去掉过渡态
    this.interval = setInterval(() => {
      this.computed(endtime)
    }, 1000)
  }

  computeDay(currentTime, endtime) {
    return Math.floor(this.moment(endtime).diff(currentTime, 'hour') / 24)
  }

  computed(endtime) {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    let diff = endtime - currentTime
    if (diff <= 0) {
      clearInterval(this.interval)
      if (currentTime < this.dataSource.courseConvertEndTime) {
        return this.countdownInterval(this.dataSource.courseConvertEndTime)
      } else {
        return (this.countdownText = '')
      }
    }

    if (diff > 24 * 60 * 60 * 1000) {
      this.countdownText = this.countdown() == 2 ? '转报全阶' : '正在报名'
    } else {
      return (this.countdownText = this.countdown() == 2 ? '转报全阶' : '正在报名')
    }
  }

  mounted() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (this.countdown() == 2) this.countdownInterval(this.dataSource.courseConvertEndTime)
    else if (this.countdown() == 1) this.countdownInterval(this.dataSource.courseSignUpEndTime)
  }
  destroyed() {
    clearInterval(this.interval)
  }
}

export default Index
</script>

<style scoped lang="less">
.course {
  //border: 1px solid #E8E8E8;
  box-sizing: border-box;
  background-color: white;
  position: relative;
  .group-icon {
    position: absolute;
    right: 30px;
    top: 202px;
    width: 40px;
    height: 40px;
    img {
      width: 40px;
      height: 40px;
    }
  }
  &:hover {
    .course-cover {
      img {
        transform: scale(1.1);
      }
    }
  }
  &-cover {
    width: 100%;
    height: 222px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      user-select: none;
      -webkit-user-drag: none;
      transition: transform 0.5s;
    }
  }
  &-types {
    width: 100%;
    display: flex;
    align-items: center;
    height: 18px;
    margin-top: 20px;
    padding: 0 30px;
    &-label {
      padding: 0 4px;
      height: 18px;
      background: #ad9174;
      border-radius: 2px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: #ffffff;
      line-height: 18px;
    }
    &-icon {
      width: 4px;
      height: 4px;
      background: #ad9174;
      border-radius: 50%;
      margin: 0 6px;
    }
    &-text {
      font-size: 12px;
      color: #ad9174;
      line-height: 18px;
    }
    .course-name {
      font-size: 18px;
      line-height: 18px;
      height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 258px;
      font-weight: 500;
      color: #181c25;
      margin-left: 10px;
    }
  }
  &-title {
    font-size: 18px;
    font-weight: 500;
    color: #181c25;
    line-height: 18px;
    margin-top: 20px;
    padding: 0 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    width: 360px;
  }
  &-vice {
    font-size: 12px;
    color: #888888;
    line-height: 12px;
    height: 12px;
    margin: 16px auto 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    width: 300px;
  }
  &-line {
    margin: 20px 30px 0;
    width: 300px;
    height: 1px;
    background: #e8e8e8;
  }
  .course-wait {
    margin-top: 20px;
    width: 100%;
    height: 38px;
    line-height: 38px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #888888;
  }
  &-lastline {
    display: flex;
    height: 38px;
    align-items: center;
    padding: 0 30px;
    margin-top: 20px;
    .price-label {
      height: 38px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0 12px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: white;
        border-radius: 50%;
        margin: auto;
        left: -4px;
        top: 0;
        bottom: 0;
        z-index: 5;
      }
      &::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: white;
        border-radius: 50%;
        margin: auto;
        right: -4px;
        top: 0;
        bottom: 0;
        z-index: 5;
      }
      &-num {
        font-size: 20px;
        font-weight: 500;
      }
      &-count {
        font-size: 12px;
        font-weight: 500;
        transform: translateY(3px);
      }
      &-red {
        background: fade(#ba5a5a, 10%);
      }
      &-yellow {
        background: fade(#ad8153, 10%) !important;
      }
      &-black {
        background: fade(#888888, 10%) !important;
      }
    }
    &-state {
      flex: 1;
      text-align: right;
      font-size: 14px;
      font-weight: 500;
      img {
        margin-right: 4px;
        width: 14px;
        height: 16px;
        vertical-align: text-top;
      }
    }
    &-red {
      color: #ba5a5a;
    }
    &-yellow {
      color: #ad8153 !important;
    }
    &-black {
      color: #888888 !important;
    }
  }
}
</style>
