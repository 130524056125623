
















































































































































































































































































































































import { Component, Prop, Vue, Mixins, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash, { max } from 'lodash'
import { MixinsCoursePayment } from '@xt/client/mixins'
import { EnumProductType, EnumOrderStateTypeNumber } from '@xt/client/enums'
import PayPopup from '../../components/payPopup/index.vue'
import Teleport from '@xt/client/mixins/components/teleport.vue'
import HeaderImg from '../../components/headerImage/index.vue'
import LazyImg from '@/components/lazyImg/index.vue'
import Card from './views/Card.vue'
import ViewCourse from './views/Course.vue'
import ViewComposition from './views/Composition.vue'
import GiftCoupon from './views/GiftCoupon.vue'
import CopperButton from './views/CopperButton.vue'
import GiftCouponPop from '@xt/www/components/coupon/GiftCouponPop.vue'
import WelfareCouponSelectPop from '@xt/www/components/coupon/WelfareCouponSelectPop.vue'
import CouponItem from '@xt/www/components/coupon/CouponItem.vue'
import SelectCouponInfoPop from './views/SelectCouponInfoPop.vue'
import { CouponInfoListItem, BaseCouponItem } from '@xt/client/types/coupon'
import GiftUserPop from './views/GiftUserPop.vue'
import QuestionPop from './views/QuestionPop.vue'

@Observer
@Component({
  // 每次进入页面都会调用
  async asyncData(ctx) {
    const store = ctx.store.$storeCourse.Details
    // 更新用户信息 获取桐板儿
    const userInfo = ctx.store.$storeUser.onUpdatingUserInfo()
    await ctx.store.$storeUser.onGetCourseListHasbuy()
    await store.onOrderinfo(ctx.query.productId, ctx.query.give == 'true' ? false : true)
    await userInfo
    // 重新支付订单
    const orderNo = ctx.query?.orderNo
    if (orderNo) {
      await ctx.store.$storeOrder.Details.onLoading({ orderNo })
    }

    return {}
  },
  validate({ query }) {
    return /^\d+$/.test(query.productId as string)
  },
  components: {
    Card,
    LazyImg,
    ViewCourse,
    ViewComposition,
    GiftCoupon,
    HeaderImg,
    CopperButton,
    Teleport,
    PayPopup,
    GiftCouponPop,
    WelfareCouponSelectPop,
    CouponItem,
    SelectCouponInfoPop,
    GiftUserPop,
    QuestionPop
  }
})
export default class PageView extends Mixins(MixinsCoursePayment) {
  burst: boolean = false // 初始礼花🎉不迸发
  againVerifyPendingOrder: boolean = false // 再次验证是否是待支付订单

  selectedCouponInfoPop: {
    show: boolean
    data: {
      list: CouponInfoListItem[]
      coupon: BaseCouponItem | null
    }
  } = {
    show: false,
    data: {
      coupon: null,
      list: []
    }
  }

  copperAnswer = [
    '1、它是暄桐教室同学在成功购买课程时给予的返利优惠，仅可在暄桐教室网站购买课程时抵扣使用。桐板儿不能兑现，不可转让。',
    ' ',
    '2、获得桐板儿：在暄桐教室网站购买课程订单且交易成功后，即可获得为实付金额100:1数量的桐板儿（100元=1个桐板儿），如遇小数点，四舍五入取整。如实付学费小于100元，将不纳入桐板儿计算。如用户申请退课，在点击【申请退课】按钮时，将会扣除该课程订单所获得的桐板儿；点击【取消申请退课】按钮，桐板儿将再次返还用户的账户。',
    ' ',
    '3、使用桐板儿：桐板儿可用于在暄桐教室网站支付课程订单（1个桐板儿=8元）时的学费抵扣。用户实际支付金额不足8元时，可以使用一个桐板儿扣减至0元。使用桐板儿抵扣的课程订单在申请退课时，桐板儿将返回用户的账户。',
    ' '
  ]

  couponAnswer = [
    '1、它是暄桐教室同学在购课时可使用的优惠。福利券分为三类：满减券、折扣券和兑换券，所有类型的福利券均不能兑现。',
    ' ',
    '2、其中，满减券和折扣券可在暄桐教室购买课程时用于减少学费。满减券在达到一定消费金额后提供固定的金额减免；折扣券则按照一定的比例降低课程费用。兑换券则可用于直接换取指定课程。兑换券支持转赠，但转赠后无法再赠课给Ta人。',
    ' ',
    '3、福利券有可叠加和不可叠加两种类型。对于可叠加的福利券，学员可以在同一笔交易中使用多张。然而，并非所有的福利券都能与其他优惠（如“桐板儿”优惠）共同使用。',
    ' ',
    '4、为保证您能够顺利地享受福利，请务必在福利券的有效期内使用。并建议您在购课前仔细阅读每张券的具体使用规则和限制条件。',
    ' '
  ]

  get isRealPendingOrder() {
    return this.isAgain && this.againVerifyPendingOrder
  }

  qrCodeAliLoading() {
    console.log('正在加载请稍等')
  }

  // 微信为true  支付宝为false
  get selectPayWays() {
    return this.paymentType === 2
  }
  onOrderOvertimeCancel() {
    this.visibleOvertime = false
    this.toProductDetail()
  }

  copperBlur() {
    if (!this.copper) {
      this.copper = 0
    }
    if (this.copper > this.maxCopper) {
      this.copper = this.maxCopper
    }
  }

  handleUseCopper(value: number) {
    this.copper = value
  }

  handleViewGiftCoupon() {
    // @ts-ignore
    this.$refs['giftCouponPopIns']?.show()
  }

  handleUseCoupon() {
    // 当前是赠课，但是还没有添加赠课人
    if (this.give && !this.giftUser) {
      this.$msg('请先添加赠课桐学')
      return
    }

    // @ts-ignore
    this.$refs['welfareCouponSelectPopIns']?.show()
  }

  handleShowGiftUserPop() {
    if (this.isAgain) return

    // @ts-ignore
    this.$refs['giftUserPopIns']?.show()
  }

  // async giftUserBlur() {
  //   this.receiveHasbuy = []
  //   await this.onBaseinfo()
  //   this.burst = !lodash.isEmpty(this.giftUserInfo.nickname) ? true : false
  // }

  async handleAddGiftUser({ device, id, nickname }: { device: string; id: string; nickname: string }) {
    this.giftUser = device
    this.giftUserInfo = { id, nickname }
    this.receiveHasbuy = []
    // 查询当前用户对于被赠人的可用优惠券
    await this.onGiftCouponInfo()
    // this.giftUserBlur()
  }

  handleConfirmCouponSelect({ dicount, useWithCopper, coupondIds }: { dicount: number; useWithCopper: boolean; coupondIds: number[] }) {
    this.couponComposePrice = dicount
    this.couponComposeWithCopper = useWithCopper
    this.couponComposeIds = coupondIds

    if (!useWithCopper) {
      this.copper = 0
    }
  }

  copperLimit(e) {
    let { value } = e.target
    this.copper = value.replace(/[^\d]/g, '')
    this.copper = value.replace(/^(0+)|[^\d]+/g, '')
    if (value.length > 8) {
      this.copper = value.substring(0, 8)
    }
  }

  get showCourseCount() {
    if (this.PageStore.Orderinfo.original.productType == EnumProductType.dayCourse) {
      return false
    }
    return true
  }

  giftUserUpdate(e) {
    let value = e.target.value
    if (value.length > 30) return (this.giftUser = value.substring(0, 30))
    this.onBaseinfo()
  }

  hanldeViewCouponInfo(data: { list: CouponInfoListItem[]; coupon: BaseCouponItem | null }) {
    this.selectedCouponInfoPop = {
      show: true,
      data
    }
  }

  handleColseSelectCouponInfoPop() {
    this.selectedCouponInfoPop = {
      show: false,
      data: {
        list: [],
        coupon: null
      }
    }
  }

  get shouldPrice() {
    if (this.$route.query.group == 'true') {
      return this.PageStore.Orderinfo?.original?.groupPrice
    }
    return this.PageStore.Orderinfo.discountPrice
  }
  async isCompletePayment() {
    const { orderNo } = this.$route.query
    let res = await this.PaymentStore.Details.onLoading({ orderNo })
    if (res?.orderStatus === EnumOrderStateTypeNumber.NotPay) {
      // 如果是待支付订单，弹出Toast提示
      this.$msg('您还没有支付该订单哟~')
    } else if (res?.orderStatus === EnumOrderStateTypeNumber.HasPay) {
      this.onSuccess(orderNo)
    } else {
      const query = lodash.clone(this.$route.query)
      lodash.unset(query, 'orderNo')
      lodash.unset(query, 'memberBeforeOption')
      await this.$router.replace({ query })
      this.onDownTimerVisible(false)
      this.againVerifyPendingOrder = false
      this.toProductDetail()
    }
  }
  async created() {
    this.initCoupon()

    let orderNo = this.orderNo ? this.orderNo : ''
    const productId = this.$route.query.productId
    if (!this.give) {
      // this.isHasDiscount = await this.PaymentStore.onCheckHasDiscount(+productId, false)
    }
    if (orderNo) {
      let orderDetail = await this.PaymentStore.Details.onLoading({ orderNo })
      if (orderDetail?.orderStatus === EnumOrderStateTypeNumber.NotPay) {
        this.onDownTimerVisible(true)
        this.againVerifyPendingOrder = true
        if (this.give) {
          // this.isHasDiscount = await this.PaymentStore.onCheckHasDiscount(+productId, true, this.giftUserInfo.id)
        }
      } else {
        const query = lodash.clone(this.$route.query)
        lodash.unset(query, 'orderNo')
        lodash.unset(query, 'memberBeforeOption')
        await this.$router.replace({ query })
        this.onDownTimerVisible(false)
        this.againVerifyPendingOrder = false
        this.toProductDetail()
      }
    }
    await this.onInit()
    this.burst = !lodash.isEmpty(this.giftUserInfo.nickname) ? true : false
  }
  ob: IntersectionObserver | null = null
  showFloatWindow: boolean = true
  mounted() {
    this.$nextTick(() => {
      const paymentBtn = document.querySelector('.pay-footer')
      this.ob = new IntersectionObserver(
        entries => {
          entries.forEach(item => {
            this.showFloatWindow = !item.isIntersecting
          })
        },
        {
          threshold: 1
        }
      )
      this.ob.observe(paymentBtn)
    })
  }
  updated() {}
  destroyed() {
    if (this.downtimeTimer) {
      clearInterval(this.downtimeTimer)
      this.downtimeTimer = null
    }
    this.PollingKey = lodash.uniqueId()
    this.ob.disconnect()
    // 清除优惠券数据
    this.CouponStore.clearUserCouponList()
  }
}
