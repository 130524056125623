































import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  scrollToTop: true
})
export default class PageView extends Vue {
  body = null
  get PageStore() {
    return this.$store.$my
  }
  get Pagination() {
    return this.PageStore.MyCoin
  }
  tongBanType(useWay) {
    /*
      数据迁移获得(0, "数据迁移获得"),
      买课消耗(1, "买课消耗"),
      买课获得(2, "买课获得"),

      取消订单获得(3, "取消订单获得"),

      退课消耗(5, "退课消耗"),
      退课获得(6, "退课获得"),

      取消退课获得(7, "取消退课获得"),
      取消退课消耗(8, "取消退课消耗"),

      系统赠送(9, "系统赠送"),

      积分补偿获得(10, "积分补偿获得"),
    */

    let types = [
      ['买课', '获得'],
      ['买课', '消耗'],
      ['买课', '获得'],
      ['取消订单', '获得'],
      ['', ''],
      ['退课', '消耗'],
      ['退课', '获得'],
      ['取消退课', '获得'],
      ['取消退课', '消耗'],
      ['系统', '赠送'],
      ['积分补偿', '获得'],
      ['桐板儿兑换', '消耗']
    ]
    return types[useWay]
  }
  created() {}
  mounted() {}
  beforeDestroy() {
    //防止出现一些数据不显示问题
    this.Pagination.onReset()
  }
}
