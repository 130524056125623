
























import InfiniteLoading from 'vue-infinite-loading'
import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
import { Debounce, BindAll } from 'lodash-decorators'
import lodash from 'lodash'
@Component({
  components: {
    InfiniteLoading
  }
})
export default class infinite extends Vue {
  @Prop({ default: Date.now() }) identifier
  @Prop({ default: true }) forceUseInfiniteWrapper
  @Prop({ default: true }) showTip: boolean
  /** 初始化是否需要加载一次数据 */
  @Prop({ default: true }) initLoading: boolean

  // @Debounce(50)
  // onLoading(event) {
  //   this.$emit("loading", event);
  // }
  onInfinite(event?) {
    this.onLoading(event)
  }

  @Debounce(100)
  onLoading(event) {
    this.$emit('loading', event)
  }
  // onLoading = lodash.debounce((event) => {
  //   this.$emit("loading", event);
  // }, 100);
  created() {}
  mounted() {
    if (this.initLoading) {
      this.onInfinite()
    }
  }
}
