










import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  components: {}
})
export default class WenfangPopup extends Vue {
  @Prop({ default: false }) visible: boolean

  @Emit('cancel')
  cancelPopupHandler() {}

  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
