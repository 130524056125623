









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class EmptyComp extends Vue {
  @Prop({ default: '暂时找不到数据哦~' }) tip: string
}
