function splitString(str: string, arr: string[]) {
  const reg = new RegExp(arr.join('|'), 'g')
  str = str.replaceAll(reg, token => {
    return `$$__${token}$$__`
  })
  const result = str.split('$$__')

  return result
}

export type ParsedResult = {
  type: 'Normal' | 'Keyword'
  text: string
}

export function parseContent(content: string, keywords = ['暄桐客服', '暄桐教室客服']): Array<ParsedResult> {
  if (!content) {
    return []
  }

  return splitString(content, keywords).map(item => {
    if (keywords.includes(item)) {
      return {
        type: 'Keyword',
        text: item
      }
    } else {
      return {
        type: 'Normal',
        text: item
      }
    }
  })
}

/**
 * 将秒数转换为小时数，精确到小数点后一位，整点不显示尾部 .0
 * @param seconds 秒数
 */
export const seconds2Hours = (seconds: number) => {
  return (seconds / 60 / 60).toFixed(1).replace(/\.0$/, '')
}

/**
 * 将秒数转换为分钟数，精确到小数点后一位，整点不显示尾部 .0
 * @param seconds 秒数
 */
export const seconds2Minutes = (seconds: number) => {
  return Math.floor(seconds / 60)
}
