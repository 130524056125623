import { BindAll } from 'lodash-decorators'
import { EntitiesBasics } from '../basics/entities'
import { action, observable, toJS, computed } from 'mobx'
import ControllerCouponAssocOrder from './order'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EnumApiCoupon } from '@xt/client/api/coupon'
import { EnumCouponStatus, EnumPreferentialType, IS_COMPOSE_COPPER, IS_COMPOSE_COUPON } from '@xt/client/enums/order'
import { BaseCouponItem, CouponItem } from '@xt/client/types/coupon'
import CouponPriceHelper from './price'
import { cloneDeep } from 'lodash'
import { ControllerMyPacks } from './packs'
import Pagination from '@xt/client/entities/basics/pagination'

type CouponPacks = {
  available: CouponItem[]
  unavailable: CouponItem[]
}

type OrderInfoConfig = {
  productId: string
  // 单位 分
  orderPrice: number
  isGive: boolean
  // 当前登录用户的memberId
  currentMemberId: string
  isGroup: boolean
}

@BindAll()
export class ControllerCoupon extends EntitiesBasics {
  // 订单关联的优惠券赠品列表
  @observable orderAssocCouponList: BaseCouponItem[] = []
  // 用户的可用优惠券和不可用优惠券列表
  @observable orderCoupons: CouponPacks = { available: [], unavailable: [] }
  // 如果是待支付订单，保存待支付订单使用的优惠券列表
  @observable orderCouponViews: CouponItem[] = []

  // 重要参数，用来网络请求和后续计算
  private orderInfo: OrderInfoConfig = { productId: '', orderPrice: 0, isGive: false, currentMemberId: '', isGroup: false }

  // 记录下用户打开弹窗选择优惠券时之前的选择组合
  private lastUserSelectCoupon: CouponItem[] = []
  // 后端计算出来的最优券组合 仅备份
  private optimalCouponComposeList: BaseCouponItem[] = []

  /** 关联订单的store */
  assocOrder = new ControllerCouponAssocOrder(this.$ajax)
  /** 我的卡券 store */
  myPacks = new ControllerMyPacks(this.$ajax)
  /** 兑换记录 */
  exchangeRecord = new Pagination(this.$ajax, {
    url: EnumApiCoupon.ExchangeRecode,
    method: 'get',
    key: 'id',
    onMapValues: 'data'
  })

  private priceHelper = new CouponPriceHelper()

  /** 计算属性 */

  // 用户选择的优惠券列表
  @computed
  get userSelectCouponList() {
    // 叠加券排在前面
    const result = this.orderCoupons.available.filter(i => i.selected)
    result.sort((a, b) => {
      if (this.priceHelper.isComposeUseCoupon(a)) {
        return -1
      } else {
        return 1
      }
    })
    return result
  }

  @computed
  get userSelectCouponIds() {
    return this.userSelectCouponList.map(i => i.id)
  }

  // 用户选择的优惠券总价 单位分
  @computed
  get userSelectCouponDiscount() {
    console.warn('debug compute userSelectCouponDiscount')
    return this.priceHelper.getCouponComposeDiscountInfo(this.orderInfo.orderPrice, this.userSelectCouponList).discount
  }

  @computed
  get useWithCopper() {
    return this.userSelectCouponList.every(i => !!(i.composeFlag & IS_COMPOSE_COPPER))
  }

  constructor(protected $ajax: AjaxBasics) {
    super($ajax, {})
  }

  /** 异步数据获取开始 */

  /**
   * 获取订单关联的券赠品
   */
  @action
  private initOrderAssocCouponList(couponList: BaseCouponItem[]) {
    this.orderAssocCouponList = couponList
  }
  public async fetchOrderAssocCouponList({ productId }: { productId: string }) {
    try {
      const response = await this.$ajax.get<Array<BaseCouponItem>>(EnumApiCoupon.OrderAssocCoupon, { productId })

      if (Array.isArray(response)) {
        this.initOrderAssocCouponList(response)
      }
    } catch {}
  }

  /**
   * 获取用户的可以券列表，并根据后端生成的最优券组合初始化可用券列表和不可用券列表数据
   */
  @action
  private initOrderCoupons(couponList: BaseCouponItem[], couponCompose: BaseCouponItem[]) {
    // 过滤出来当前订单可用优惠券和不可用优惠券

    const _available: CouponItem[] = []
    const _unavailable: CouponItem[] = []
    // 先找出不可用的优惠券
    couponList.forEach(coupon => {
      if (this.isCouponCanUse(coupon)) {
        _available.push({ ...coupon, selected: couponCompose.some(_coupon => _coupon.id === coupon.id) })
      } else {
        _unavailable.push({ ...coupon, selected: false })
      }
    })

    this.orderCoupons = {
      available: _available,
      unavailable: _unavailable
    }
  }
  public async fetchUserCouponList({ productId, memberId }: { productId: string; memberId: string }) {
    try {
      const couponPacks = await this.$ajax.get<Array<BaseCouponItem>>(EnumApiCoupon.CouponPacks, { status: EnumCouponStatus.UnUsed })
      const couponCompose = await this.$ajax.get<Array<BaseCouponItem>>(EnumApiCoupon.CouponOptimalCompose, {
        memberId: memberId,
        productId,
        isGroup: `${this.orderInfo.isGroup}`
      })

      if (Array.isArray(couponPacks) && Array.isArray(couponCompose)) {
        this.optimalCouponComposeList = couponCompose
        this.initOrderCoupons(couponPacks, couponCompose)
      }
    } catch (e) {}
  }

  public clearUserCouponList() {
    this.optimalCouponComposeList = []
    this.initOrderCoupons([], [])
  }

  // 获取当前订单的优惠券赠品信息，获取当前用户的所有可用优惠券列表
  public async fetchOrderCoupon() {
    try {
      const { productId, isGive, currentMemberId } = this.orderInfo

      if (!productId) {
        throw new Error('productId required')
      }

      // 如果是赠课场景，初始的时候不调用fetchUserCouponList，等用户输入被赠人后再掉用
      if (isGive) {
        await this.fetchOrderAssocCouponList({ productId })
      } else {
        // 自己
        await Promise.all([
          this.fetchOrderAssocCouponList({ productId }),
          this.fetchUserCouponList({ productId, memberId: currentMemberId })
        ])
      }
    } catch (e) {
      console.error('fetchOrderCoupon err', e)
      // TODO error Log report
    }
  }

  // 完成支付后查询礼物弹窗数据
  public async fetchOrderSuccessCouponGift(orderNo: string) {
    const list = await this.$ajax.get<{ failList: Array<BaseCouponItem>; successList: Array<BaseCouponItem>; completePayment: boolean }>(
      EnumApiCoupon.OrderSuccessCouponGift,
      { orderNo }
    )

    if (!list) return null

    if ('successList' in list || 'failList' in list) {
      return list
    }

    return null
  }

  public async readOrderSuccessCouponGift(couponCodes: string[]) {
    if (couponCodes.length === 0) return

    await this.$ajax.put(EnumApiCoupon.ReadCouponMessage, { couponCodes })
  }

  // 已支付订单获取当前订单使用的优惠券回显展示
  @action
  private setOrderCouponView(couponList: CouponItem[]) {
    // 可叠加券优先在前展示
    if (couponList.length === 2) {
      couponList.sort((a, b) => {
        if (this.priceHelper.isComposeUseCoupon(a)) {
          return -1
        } else {
          return 0
        }
      })
    }

    this.orderCouponViews = couponList
  }
  public async fetchOrderCouponViews(orderNo: string) {
    try {
      const result = await this.$ajax.get<Array<BaseCouponItem>>(EnumApiCoupon.CouponAssocByOrder, { usedOrderNo: orderNo })

      if (Array.isArray(result)) {
        this.setOrderCouponView(result.map(i => ({ ...i, selected: true })))
      }
    } catch (e) {
      console.error('orderCouponViews err', e)
      // TODO error Log report
    }
  }

  /** 异步数据获取结束 */

  /** 对外暴露的方法 */
  // 设置store必须的参数
  public setOrderInfo({
    productId,
    orderPrice,
    isGive,
    currentMemberId,
    isGroup
  }: {
    productId: string
    orderPrice: number
    isGive: boolean
    currentMemberId: string
    isGroup: boolean
  }) {
    this.orderInfo = {
      productId,
      orderPrice,
      isGive,
      currentMemberId,
      isGroup
    }
    console.log('设置订单信息', orderPrice, productId, isGive, currentMemberId)
  }

  // 重新整理用户可用优惠券和不可用优惠券列表
  @action
  public rearrangeOrderCoupons() {}

  // 用户点击了一张券，重新计算当前可用优惠券组合勾选状态
  @action
  public onSelectCoupon(couponId: number) {
    const selectCouponList = [...this.userSelectCouponList]
    const current = this.orderCoupons.available.find(c => c.id === couponId)

    if (!current) return

    // case 之前没有选择
    if (!selectCouponList.length) {
      console.warn('命中规则 之前没有选择')
      this.orderCoupons.available = this.orderCoupons.available.map(coupon => {
        coupon.selected = coupon.id === couponId
        return coupon
      })

      return
    }

    // case 反选
    if (selectCouponList.some(item => item.id === couponId)) {
      console.warn('命中规则 反选')
      this.orderCoupons.available = this.orderCoupons.available.map(coupon => {
        if (coupon.id === couponId) {
          coupon.selected = false
        }
        return coupon
      })

      return
    }

    // case 价格干到0互斥
    if (selectCouponList.length === 1) {
      console.warn('命中规则 价格干到0互斥')
      const lastPrice = this.priceHelper.getCouponDiscountInfo(this.orderInfo.orderPrice, selectCouponList[0]).restMoney
      const restPrice = this.priceHelper.getCouponDiscountInfo(this.orderInfo.orderPrice, current).restMoney

      if (lastPrice === 0 || restPrice === 0) {
        this.orderCoupons.available = this.orderCoupons.available.map(coupon => {
          coupon.selected = coupon.id === current.id

          return coupon
        })

        return
      }
    }

    // case 之前选中了2张券，把同类型的券替换掉
    if (selectCouponList.length === 2) {
      console.warn('命中规则 之前选中了2张券，把同类型的券替换掉')
      const restPrice = this.priceHelper.getCouponDiscountInfo(this.orderInfo.orderPrice, current).restMoney
      if (restPrice === 0) {
        // 互斥
        this.orderCoupons.available = this.orderCoupons.available.map(coupon => {
          coupon.selected = coupon.id === current.id

          return coupon
        })
      } else {
        const differentCoupon = this.priceHelper.isCouponComposeFlagSame(current, selectCouponList[0])
          ? selectCouponList[1]
          : selectCouponList[0]

        this.orderCoupons.available = this.orderCoupons.available.map(coupon => {
          coupon.selected = [couponId, differentCoupon.id].includes(coupon.id)

          return coupon
        })
      }

      return
    }

    // case 独占券(不管之前的选择，无脑选中当前的券)
    if (
      this.isExchangeCoupon(current) ||
      this.isExchangeCoupon(selectCouponList[0]) ||
      this.priceHelper.isCouponComposeFlagSame(current, selectCouponList[0])
    ) {
      console.warn('命中规则 独占券')
      this.orderCoupons.available = this.orderCoupons.available.map(coupon => {
        coupon.selected = coupon.id === couponId
        return coupon
      })

      return
    }

    // case 要不选中当前券形成券组合。要不取消之前的券选择，选择当前券
    // 先处理可一起使用的
    console.warn('命中规则 先处理可一起使用的')
    this.orderCoupons.available = this.orderCoupons.available.map(coupon => {
      coupon.selected = [selectCouponList[0].id, current.id].includes(coupon.id)

      return coupon
    })

    return
  }

  public backupAvailableCouponList() {
    this.lastUserSelectCoupon = cloneDeep(toJS(this.orderCoupons.available))
  }

  @action
  public rollbackCurrentAvailableCouponSelect() {
    this.orderCoupons.available = this.lastUserSelectCoupon
  }

  private isExchangeCoupon(coupon: BaseCouponItem) {
    return coupon.preferentialType === EnumPreferentialType.Exchange
  }

  // 当前优惠券是否可以使用
  public isCouponCanUse(coupon: BaseCouponItem): boolean {
    const { productId, orderPrice, isGive, currentMemberId } = this.orderInfo
    const now = AjaxBasics.serviceDate

    if (!Array.isArray(coupon.exchangeProducts)) return false

    // 非福利券下单时不可使用
    if (coupon.couponType >= 200) return false

    // 先校验课程是否满足
    if (coupon.exchangeProducts.every(product => product.productId !== parseInt(productId, 10))) {
      return false
    }

    // 校验时间参数
    if (now.isBefore(coupon.validStartTime) || now.isAfter(coupon.validEndTime)) {
      return false
    }

    // 校验赠课可用券
    if (isGive && !coupon.allowGiveUse) {
      return false
    }

    // 检验券门槛
    if (coupon.condition > orderPrice) {
      return false
    }

    // 自己的券赠送给别人了
    if (coupon.ownerId !== currentMemberId) {
      return false
    }

    return true
  }
}
