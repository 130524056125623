



































import { Component, Vue, Emit, Prop, Mixins, Watch } from 'vue-property-decorator'
@Component({
  name: 'PayPopup'
})
export default class PayPopup extends Vue {
  @Prop({ default: 'wx' }) type: 'wx' | 'zfb'
  @Prop() visible: boolean
  @Prop() shouldPrice: number

  get title() {
    return this.type === 'wx' ? '微信支付' : '支付宝支付'
  }

  @Emit('hide')
  hidePopup() {}
}
