import { render, staticRenderFns } from "./ExchangeConfirmPopup.vue?vue&type=template&id=46aec2a3&scoped=true&"
import script from "./ExchangeConfirmPopup.vue?vue&type=script&lang=ts&"
export * from "./ExchangeConfirmPopup.vue?vue&type=script&lang=ts&"
import style0 from "./ExchangeConfirmPopup.vue?vue&type=style&index=0&id=46aec2a3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46aec2a3",
  null
  
)

export default component.exports