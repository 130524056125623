













































































import { Component, Mixins } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { MixinsCouponItem } from '@xt/client/mixins'

@Observer
@Component({
  components: {}
})
export default class CouponItem extends Mixins(MixinsCouponItem) {}
