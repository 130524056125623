export enum EnumApiTicket {
  // 文房券列表
  TICKET_LIST = '/bsp/api/bishanpoints/product',
  // 我的文房券列表
  MY_TICKET_LIST = '/bsp/api/bishanpoints/exchange/myself',
  // 文房券详情
  TICKET_DETAIL = '/bsp/api/bishanpoints/product/detail/{id}',
  // 检验用户有赞账号
  CHECK_YOUZAN = '/bsp/api/bishanpoints/check/youzan',
  // 笔山兑换文房券
  EXCHANGE_TICKET = '/bsp/api/bishanpoints/product/exchange/{productId}',
  /// 检验笔山数量是否大于当前文房券价格 and 文房券是否够库存充足
  CHECK_BISHAN_COUNT = '/bsp/api/bishanpoints/check/bishan',
  //检查验证码
  CHECK_CODE = '/bsp/api/bishanpoints/verifycodes/check',
  //获取手机号
  SEND_MSG_BY_PHONE = '/bsp/api/bishanpoints/verifycodes'
}
