










































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import lodash from 'lodash'
import { Observer } from 'mobx-vue'

import DeviceModal from '@xt/www/components/deviceModal/index.vue'
import Signin from './login/signin.vue'
import PhoneLogin from './login/phoneLogin.vue'
import EmailLogin from './login/emailLogin.vue'
import BindDevice from './login/bindDevice.vue'
import Retrieve from './login/retrieve.vue'
import CompleteInfo from './login/completeInfo.vue'
import HeaderImage from '../../components/headerImage/index.vue'
import HeaderLink from './headerLink.vue'
import HeaderNotice from './headerNotice.vue'
import { action } from 'mobx'

enum EnumAgreementType {
  /** 用户协议 */
  USER = 'user',
  /** 隐私协议 */
  PROVACY = 'privacy'
}

@Observer
@Component({
  components: {
    Signin,
    PhoneLogin,
    EmailLogin,
    BindDevice,
    Retrieve,
    CompleteInfo,
    HeaderImage,
    DeviceModal,
    HeaderLink,
    HeaderNotice
  }
})
export default class extends Vue {
  agreeVisible: boolean = false
  type: string = ''
  isTop = true
  showLink = false
  showNotice = false
  hoverLinkBox = false
  hoverNoticeBox = false
  menu = [
    {
      key: 'my-homework',
      name: '我的作业'
    },
    {
      key: 'my-order',
      name: '我的订单'
    },
    {
      key: 'my-showHomework',
      name: '我晒出的作业'
    }
  ]
  get config() {
    return {
      autoInsertSpaceInButton: false,
      locale: { zh: zh_CN, en: null }[this.$store.$locale.locale]
    }
  }
  get ClasshourPageStore() {
    return this.$store.$storeCourse.Details.ClasshourLive
  }
  get pageClass() {
    return 'xt-page-' + this.$route.name
  }
  get production() {
    return this.$store.$global.production
  }
  get keepAlive() {
    return !this.$store.$global.dev
  }
  get version() {
    return 'Version: ' + this.$store.$global.version + ', Build Time: ' + this.$store.$global.timestamp
  }
  get locale() {
    return this.$EnumLocaleLinks
  }
  get PageStore() {
    return this.$store.$storeUser
  }
  get DeviceStore() {
    return this.$store.$device
  }
  get noticeStore() {
    return this.PageStore.messageNotice
  }

  get window() {
    return window
  }
  get EnumAgreementType() {
    return EnumAgreementType
  }
  toMyPage() {
    if (this.PageStore.UserInfo.status === 3) return
    this.window.open(`/my?t=${Date.now()}`)
  }
  onOk() {
    this.onVisible(false)
  }
  onCancel() {
    this.onVisible(false)
    this.changeCheckState(false)
    if (lodash.hasIn(this.$route.query, 'login')) {
      const query = lodash.cloneDeep(this.$route.query)
      lodash.unset(query, 'login')
      this.$router.replace({ query })
    }
  }
  @action
  changeCheckState(state: boolean) {
    this.PageStore.checkState = state
  }
  validHandler(info: any) {
    this.type = info.type
    if (!info.checkState) {
      this.agreeVisible = true
    } else {
      this.PageStore.onToggleModal(info.type)
    }
  }
  onOkAgree() {
    this.agreeVisible = false
    this.changeCheckState(true)
    this.PageStore.onToggleModal(this.type)
  }
  onCancelAgree() {
    this.agreeVisible = false
    // this.onVisible(true)
  }
  goProtocolPage(e, type) {
    e.stopPropagation()
    let jumpStr = type === EnumAgreementType.USER ? '/protocol/service' : '/protocol/privacy'
    window.open(jumpStr)
  }

  async onOutLogin() {
    await this.PageStore.onLogout()
    await this.PageStore.onOutLogin()
    window.location.replace(this.$router.resolve('/').href)
  }
  toPage(name) {
    if (name === this.$route.name) return
    if (name === 'index') this.isTop = true
    else this.isTop = false
    this.$router.push({ name })
  }
  goPage(path) {
    this.$router.push({
      path
    })
  }
  goTenYear() {
    window.open('https://mp.weixin.qq.com/s/PuFhSFFrhCfOQXsffZaJ-w')
  }
  onVisible(visible, title?) {
    this.$store.$storeUser.onToggleVisible(visible)
    if (title) this.$store.$storeUser.onToggleModal(title)
  }
  get isHome() {
    return this.$route.name?.replace(/-(index)/g, '') === 'index'
  }

  get isDayCourseActivity() {
    return this.$route.name === 'activitys-datcourse'
  }
  /** 检测用户是否登录 */
  get loginIn() {
    return this.$store.$storeUser.loggedIn
  }

  get isVideo() {
    return this.$route.name === 'video'
  }
  get isStationery() {
    return this.$route.name === 'stationery'
  }
  get isAllCourse() {
    return this.$route.name === 'product-list'
  }
  get isBMYX() {
    return this.$route.name === 'activitys-bmyx'
  }
  get animation() {
    return this.isHome
  }
  mousewheel(e, b) {
    e.stopPropagation()
  }
  stopPropagation(e) {
    e.stopPropagation()
  }
  clickHandler(e: MouseEvent) {
    const node = e.target as HTMLElement
    // tip：点击客服不能关闭弹窗
    if (node.id && node.id === 'openZhichiCustom') return
    const id = node.offsetParent?.id
    if (id && (id.startsWith('header-link-id') || id.startsWith('header-notice-id'))) return
    this.resetClickHandler()
  }
  showHandler(type: 'link' | 'notice') {
    this.resetClickHandler()
    type === 'link' ? (this.showLink = true) : (this.showNotice = true)
    document.addEventListener('click', this.clickHandler)
  }
  resetClickHandler() {
    this.showLink = false
    this.showNotice = false
    document.removeEventListener('click', this.clickHandler)
  }
  recoverClickHandler() {
    document.addEventListener('click', this.clickHandler)
  }
  removeClickHandler() {
    document.removeEventListener('click', this.clickHandler)
  }
  async created() {
    if (this.loginIn && this.PageStore.UserInfo.status === 1) {
      let res = await this.ClasshourPageStore.onCurrentExistLive()
      this.$store.$storeUser.updateLiveStatus(!!res)

      await this.noticeStore.onUnreadCount()
    }
  }
  mounted() {
    setInterval(() => {
      if (this.animation) {
        if (document.documentElement.scrollTop > 0) {
          if (this.isTop) this.isTop = false
        } else {
          if (!this.isTop) this.isTop = true
        }
      } else {
        this.isTop = false
      }
    }, 10)
  }
  updated() {}
  destroyed() {}

  isSignin(title) {
    return lodash.includes([this.locale.links_signin, '', undefined], title)
  }
  isPhoneLogin(title) {
    return lodash.eq(this.locale.links_phone_login, title)
  }
  isEmailLogin(title) {
    return lodash.eq(this.locale.links_email_login, title)
  }
  isBindDevice(title) {
    return lodash.eq(this.locale.links_bind_device, title)
  }
  isRetrieve(title) {
    return lodash.eq(this.locale.links_retrieve, title)
  }
  isComplete(title) {
    return lodash.eq(this.locale.links_complete, title)
  }
  filterClass(title) {
    return lodash.includes([this.locale.links_signin, this.locale.links_complete, '', undefined], title)
  }
  getModalClass(isComplete) {
    return isComplete ? 'xt-login-modal' : 'xt-complete-modal'
  }
  get hideAll() {
    return this.$route.name === 'groupbuy-groupNo'
  }
}
