import moment from 'moment'
import { EnumCouponType, EnumCouponValidTimeType, EnumPreferentialType, IS_COMPOSE_COPPER, IS_COMPOSE_COUPON } from '@xt/client/enums'
import { BaseCouponItem, CouponInfoListItem } from '../../types/coupon'
import { toJS } from 'mobx'
import { seconds2Hours } from '@xt/client/utils/parser'

export type FormatCouponInfo = {
  target: number | string
  condition: string
  patchLimit: string
  minorLimit: string
  majorLimit: string
  useTime: string
}

/**
 *
 * @param couponItem 优惠券详情
 * @param timeType activity：需要看绝对时间还是相对时间  coupon直接取validStart & validEnd
 */
export function formatCouponInfo(couponItem: BaseCouponItem, timeType: 'activity' | 'coupon' = 'activity') {
  let target: number | string = ''
  let condition: string = ''
  let patchLimit: string = ''
  let minorLimit: string = ''
  let majorLimit: string = ''
  let useTime: string = ''

  switch (couponItem.preferentialType) {
    case EnumPreferentialType.FullReduction:
    case EnumPreferentialType.Discount:
      target = couponItem.preferentialType === EnumPreferentialType.Discount ? couponItem.discount / 10 : couponItem.denominations / 100
      condition = couponItem.condition === 0 ? '无限制' : `满${couponItem.condition / 100}元可用`
      break
    case EnumPreferentialType.Exchange:
      target = '课程兑换'
      condition = '指定课程可用'
      break
  }

  switch (couponItem.couponType) {
    case EnumCouponType.FullReduction:
    case EnumCouponType.Discount:
      const minorLimitList = []
      const majorLimitList = []

      if (couponItem.allowGiveUse) {
        patchLimit = '限购买指定课程使用'
        minorLimitList.push('限购买指定课程使用')
        majorLimitList.push('限购买指定课程使用')
      } else {
        patchLimit = '限自买指定课程使用'
        minorLimitList.push('限自买指定课程使用')
        majorLimitList.push('限自买指定课程使用')
      }

      if (!!(couponItem.composeFlag & IS_COMPOSE_COUPON)) {
        majorLimitList.push('可与1张非叠加券叠加使用')
      }

      if (!(couponItem.composeFlag & IS_COMPOSE_COPPER)) {
        minorLimitList.push('桐板儿不可同时使用')
        majorLimitList.push('桐板儿不可同时使用')
      }

      if (couponItem.couponType === EnumCouponType.Discount) {
        majorLimitList.push(`最高可抵扣${couponItem.limitAmount / 100}元`)
      }

      if (couponItem.allowGive) {
        majorLimitList.push('券可转赠Ta人')
      }

      minorLimit = minorLimitList.join('，')
      majorLimit = majorLimitList.join('，')
      break
    case EnumCouponType.Exchange:
      patchLimit = minorLimit = majorLimit = '限兑换指定课程使用'
      break
    case EnumCouponType.PencilStore:
      patchLimit = minorLimit = majorLimit = '限暄桐文房微信店使用'
      break
    case EnumCouponType.Companion:
      patchLimit = minorLimit = majorLimit = '限学习指定课程使用'
      target = '学习回顾'
      condition = `${seconds2Hours(couponItem.denominations)} 小时`
      break
  }

  useTime =
    timeType === 'activity' && couponItem.validType === EnumCouponValidTimeType.Relative
      ? `获得后${couponItem.validTimeDuration / 60 / 60 / 24}天内可用`
      : `${getDate(couponItem.validStartTime)}-${getDate(couponItem.validEndTime)}`

  function getDate(time: number) {
    return moment(time).utcOffset(+8).format('YYYY.MM.DD HH:mm')
  }

  return {
    target,
    condition,
    patchLimit,
    minorLimit,
    majorLimit,
    useTime
  }
}

export function getCouponUseInfo(coupon: BaseCouponItem, isRelativeTimeCoupon: boolean): Array<CouponInfoListItem> {
  const canUseWithAnotherCoupon = !!(coupon.composeFlag & IS_COMPOSE_COUPON)
  const canUseWithCopper = !!(coupon.composeFlag & IS_COMPOSE_COPPER)
  const result: Array<CouponInfoListItem> = []

  // 券时间
  result.push({
    icon: 'iconcoupon_time',
    title: '使用时间',
    value: isRelativeTimeCoupon
      ? `获得后${coupon.validTimeDuration / 60 / 60 / 24}天内可用`
      : `${moment(coupon.validStartTime).utcOffset(+8).format('YYYY.MM.DD HH:mm')}-${moment(coupon.validEndTime)
          .utcOffset(+8)
          .format('YYYY.MM.DD HH:mm')}`
  })

  // 适用课程
  if (Array.isArray(toJS(coupon.exchangeProducts)) && coupon.exchangeProducts.length > 0) {
    result.push({
      icon: 'iconcoupon_course',
      title: '适用课程',
      value: coupon.exchangeProducts.map(i => i.productName).join('、')
    })
  }

  // 适用范围 [回放延长卡不展示]
  if (coupon.couponType !== EnumCouponType.Companion && typeof coupon.allowGiveUse === 'boolean') {
    result.push({
      icon: 'iconcoupon_scope',
      title: '适用范围',
      value: coupon.couponType === EnumCouponType.PencilStore ? '暄桐文房微信店' : coupon.allowGive ? '自买' : '自买、赠课'
    })
  }

  // 使用限制
  const limit: string[] = []
  if (coupon.couponType === EnumCouponType.FullReduction || coupon.couponType === EnumCouponType.Discount) {
    // 满减、折扣券
    if (coupon.allowGiveUse) {
      limit.push('限购买指定课程使用')
    } else {
      limit.push('限自买指定课程使用')
    }

    if (canUseWithAnotherCoupon) {
      limit.push('可与1张非叠加券叠加使用')
    }

    if (!canUseWithCopper) {
      limit.push('桐板儿不可同时使用')
    }

    if (coupon.couponType === EnumCouponType.Discount) {
      limit.push(`最高可抵扣${coupon.limitAmount / 100}元`)
    }

    if (coupon.allowGive) {
      limit.push('券可转赠Ta人')
    }
  } else if (coupon.couponType === EnumCouponType.PencilStore) {
    // 文房券
    limit.push(`限暄桐文房微信店使用`)
  } else if (coupon.couponType === EnumCouponType.Exchange) {
    // 兑换券
    limit.push('限兑换指定课程使用')
  } else if (coupon.couponType === EnumCouponType.Companion) {
    // 伴学卡
    limit.push('限学习指定课程使用')
  }

  // [回放延长卡不展示]
  if (coupon.couponType !== EnumCouponType.Companion && limit.length > 0) {
    result.push({
      icon: 'iconcoupon_limit',
      title: '使用限制',
      value: limit.join('，')
    })
  }

  return result
}
