












































































































import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import moment from 'moment'
import { ExchangeProductItem } from '@xt/client/entities/ticket/product'
import { EnumCouponType, EnumExchangeType, EnumPreferentialType } from '@xt/client/enums'
import LazyImg from '@xt/www/components/lazyImg/index.vue'
import CouponUseInfo from '@xt/www/components/coupon/CouponUseInfo.vue'
import ExchangePopup from '@xt/www/components/coupon/ExchangePopup.vue'
import ExchangeBindPopup from '@xt/www/components/coupon/ExchangeBindPopup.vue'
import ExchangeConfirmPopup from '@xt/www/components/coupon/ExchangeConfirmPopup.vue'
import ExchangeWelfwareConfirmPopup from '@xt/www/components/coupon/ExchangeWelfwareConfirmPopup.vue'
import ExchangeSuccessPopup from '@xt/www/components/coupon/ExchangeSuccessPopup.vue'
import { CouponInfoListItem } from '@xt/client/types/coupon'
import { formatCouponInfo, FormatCouponInfo } from '@xt/client/utils/coupon'

enum EnumExchangeStatus {
  /** 笔山  自己不够 */
  SelfBiShanShort = 1,
  /** 桐板儿  自己不够 */
  SelfCoinShort = 2,
  /** 笔山｜桐板儿  正常 */
  Normal = 3,
  /** 笔山｜桐板儿  库存不足 */
  StockShort = 4,
  /** 兑换次数上限 */
  ExchangeLimit = 5
}

/** 兑换 ｜ 绑定用户 ｜ 确认兑换 ｜ 成功兑换 */
type VisibleType = 'exchange' | 'bind_account' | 'confirm_exchange' | 'confirm_welfware_exchange' | 'success_exchange'

@Observer
@Component({
  components: {
    LazyImg,
    CouponUseInfo,
    ExchangePopup,
    ExchangeBindPopup,
    ExchangeConfirmPopup,
    ExchangeWelfwareConfirmPopup,
    ExchangeSuccessPopup
  }
})
export default class PageView extends Vue {
  dataSource: ExchangeProductItem | null = null
  visibleCouponDesc = false
  // 优惠券使用说明数据
  couponUseDesc = null
  exchangeStatus: EnumExchangeStatus = EnumExchangeStatus.Normal
  visibleType: VisibleType | null = null
  // 有赞详情
  youzanDetail: any = null
  couponInfo: FormatCouponInfo | null = null

  bgCover = ''
  existPicture = false

  get PageStore() {
    return this.$store.$storeTicket
  }

  get UserStore() {
    return this.$store.$storeUser
  }

  get productId() {
    return lodash.get(this.$route.query, 'productId')
  }

  get couponDetail() {
    return this.dataSource?.couponActivityDetails
  }

  get EnumPreferentialType() {
    return EnumPreferentialType
  }

  get EnumExchangeType() {
    return EnumExchangeType
  }

  get isFullReduction() {
    return this.couponDetail.preferentialType === EnumPreferentialType.FullReduction
  }

  get isDiscount() {
    return this.couponDetail.preferentialType === EnumPreferentialType.Discount
  }

  get pictureList() {
    return this.dataSource.pictureResultVO.filter(v => v.pictureType === 2)
  }

  get couponInfoList(): Array<CouponInfoListItem> {
    const result: Array<CouponInfoListItem> = []

    if (!this.couponInfo) return result

    // 券时间
    result.push({
      icon: 'iconcoupon_time',
      title: '使用时间',
      value: this.couponInfo.useTime
    })

    // 适用课程
    if (Array.isArray(this.toJS(this.couponDetail.exchangeProducts)) && this.couponDetail.exchangeProducts.length > 0) {
      result.push({
        icon: 'iconcoupon_course',
        title: '适用课程',
        value: this.couponDetail.exchangeProducts.map(i => i.productName).join('、')
      })
    }

    // 回放延长卡不展示 适用范围 使用限制
    if (this.couponDetail.couponType !== EnumCouponType.Companion) {
      // 适用范围
      if (typeof this.couponDetail.allowGiveUse === 'boolean') {
        result.push({
          icon: 'iconcoupon_scope',
          title: '适用范围',
          value:
            this.couponDetail.couponType === EnumCouponType.PencilStore
              ? '暄桐文房微信店'
              : this.couponDetail.allowGive
                ? '自买'
                : '自买、赠课'
        })
      }

      // 使用限制
      if (this.couponInfo.majorLimit) {
        result.push({
          icon: 'iconcoupon_limit',
          title: '使用限制',
          value: this.couponInfo.majorLimit
        })
      }
    }

    return result
  }

  get isWenfangCoupon() {
    return this.couponDetail?.couponType === EnumCouponType.PencilStore
  }

  get isUnlimited() {
    return this.couponDetail.userTakeNumLimit === 99
  }

  get leaveCount() {
    const userinfo = this.UserStore.UserInfo
    return (this.dataSource.priceType === 1 ? userinfo.bishans : userinfo.coin) - this.dataSource.productPrice
  }

  get btn() {
    if (this.exchangeStatus === EnumExchangeStatus.StockShort) {
      return {
        text: '券已兑完',
        disabled: true
      }
    }
    if (this.exchangeStatus === EnumExchangeStatus.SelfBiShanShort) {
      return {
        text: '笔山不足',
        disabled: true
      }
    }
    if (this.exchangeStatus === EnumExchangeStatus.SelfCoinShort) {
      return {
        text: '桐板儿不足',
        disabled: true
      }
    }
    if (this.exchangeStatus === EnumExchangeStatus.ExchangeLimit) {
      return {
        text: '兑换次数不足',
        disabled: true
      }
    }
    return {
      style: 'normal',
      text: '立即兑换',
      disabled: false
    }
  }

  getDate(time: number) {
    return moment(time).format('YYYY.MM.DD HH:mm')
  }

  clickUseDescHandler() {
    this.couponUseDesc = {
      coupon: this.couponDetail,
      list: this.couponInfoList
    }
    this.visibleCouponDesc = true
  }

  async onExchange() {
    if (this.exchangeStatus !== EnumExchangeStatus.Normal) return
    this.onVisible(this.isWenfangCoupon ? 'exchange' : 'confirm_welfware_exchange')
  }

  async onSendSms(device: string, callback: () => {}) {
    try {
      if (device) {
        let res = await this.PageStore.sendMsgByPhone<boolean | { errCode: number }>(device)
        if (typeof res !== 'boolean' && 'errCode' in res) {
          if (res.errCode !== 1191100006 /** 验证码已发送，请查收 在Request层直接弹出来了，业务不弹 */) {
            this.$msg('获取验证码失败')
          }
          return
        }
        if (res) {
          callback()
          this.$msg('获取验证码成功', 'success')
        }
      }
    } catch (error) {
      if (lodash.eq(error, false)) {
        this.$msg(this.$EnumMessage.check_code_error)
      }
    }
  }

  async submitExchangeForm(device: string, verifyCode: string, callback: () => {}) {
    try {
      await this.PageStore.checkPhoneCode({ device, verifyCode })
      await this.getYouzanDetail(device)
    } catch (error) {
      if (error === false) {
        this.$msg('验证码错误')
      }
    } finally {
      callback()
    }
  }

  async getYouzanDetail(device: string) {
    try {
      //检查是否在有赞有账号
      const resp = await this.PageStore.checkYouzan(device, this.productId)
      if (resp && !resp['yzOpenId']) {
        setTimeout(() => {
          this.onVisible('bind_account')
        }, 800)
        return
      }
      this.youzanDetail = resp
      this.onVisible('confirm_exchange')
    } catch (error) {
      if (error === false) {
        this.$msg('验证码错误')
      }
    }
  }

  async onConfirmExchange(callback: () => {}) {
    try {
      await this.PageStore.exchangeTicket(
        this.youzanDetail?.userPhone,
        +this.productId,
        this.youzanDetail?.yzOpenId,
        this.youzanDetail?.avatar
      )
      setTimeout(() => {
        this.onVisible('success_exchange')
        callback()
        this.$store.$storeUser.onGetUserInfo()
      }, 800)
    } catch (error) {
      this.$msg(error.errMsg)
      callback()
    }
  }

  onVisible(visibleType: VisibleType | null) {
    this.visibleType = visibleType
  }

  init() {
    this.couponInfo = formatCouponInfo(this.couponDetail, 'activity')
    if (this.dataSource.stockQty <= 0) {
      this.exchangeStatus = EnumExchangeStatus.StockShort
    } else if (this.dataSource.priceType === 1 && this.dataSource.productPrice > this.UserStore.UserInfo.bishans) {
      this.exchangeStatus = EnumExchangeStatus.SelfBiShanShort
    } else if (this.dataSource.priceType === 2 && this.dataSource.productPrice > this.UserStore.UserInfo.coin) {
      this.exchangeStatus = EnumExchangeStatus.SelfCoinShort
    } else if (this.couponDetail.availableTakeQty <= 0) {
      this.exchangeStatus = EnumExchangeStatus.ExchangeLimit
    } else {
      this.exchangeStatus = EnumExchangeStatus.Normal
    }

    const item = this.dataSource.pictureResultVO.find(item => item.pictureType === 1)
    if (item) {
      this.existPicture = true
      this.bgCover = item.originalUrl
    } else {
      switch (this.couponDetail.couponType) {
        case EnumCouponType.FullReduction:
          this.bgCover = this.$images.point_exchange_reduction
          break
        case EnumCouponType.Discount:
          this.bgCover = this.$images.point_exchange_discount
          break
        case EnumCouponType.Exchange:
          this.bgCover = this.$images.point_exchange_course
          break
        case EnumCouponType.PencilStore:
          this.bgCover = this.$images.point_exchange_study
          break
        case EnumCouponType.Companion:
          this.bgCover = this.$images.point_exchange_companion
          break
      }
    }
  }

  async created() {
    this.dataSource = await this.PageStore.getTicketDetail(this.productId)
    this.init()
  }
}
