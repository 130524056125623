<template>
  <div class="detail">
    <Detail v-for="(item, index) in dataSource.detail" :key="index" :dataSource="item" />
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator'
import Detail from '../views/detail.vue'
import LazyImg from '@/components/lazyImg/index.vue'
// import JSONDATA from '@xt/client/static/mocks/travel_note.json'

@Component({
  components: { Detail, LazyImg },
  async asyncData(ctx) {
    // let res = JSONDATA

    const res = await ctx.store.$ajax.get(
      `/mocks/travel_note.json`,
      {},
      { 'Cache-Control': 'no-store', 'If-Modified-Since': '0' },
      {
        target: ctx.store.$global.production ? 'https://static.xuantong.cn' : 'https://testing-static.xuantong.cn'
      }
    )

    return {
      dataSource: res
    }
  }
})
export default class TravelNote extends Vue {
  dataSource = {}
}
</script>

<style lang="less" scoped></style>
