

















import { Component, Prop, Vue } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'

@Component({
  components: {
    LazyImg
  }
})
export default class ExchangeCarouselComp extends Vue {
  get swiper() {
    return (this.$refs.swiper as any)[0].$swiper
  }

  get swiperOptions() {
    return {
      pagination: {
        el: '#carousel-container .swiper-pagination',
        type: 'bullets'
      },
      loop: true,
      autoplay: {
        disableOnInteraction: false
      },
      speed: 1000,
      delay: 3000,
      grabCursor: true, //贴心抓手
      roundLengths: true,
      autoHeight: true
    }
  }

  get carouselData() {
    return [this.$images.point_exchange_banner1]
  }
}
